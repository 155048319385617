import { ButtonStyled } from "../../../../helperStyled/styledHelper";
import { NavigateOptionEdit } from "../../../../types/NavigateOption";
import { ButtonsBlockStyled } from "./styled";

type Props = {
  setNavEdit: React.Dispatch<React.SetStateAction<NavigateOptionEdit>>;
  toPage: NavigateOptionEdit;
  isActive: boolean;
  submitFunction?: () => void;
};

const ButtonsEdit: React.FC<Props> = ({ setNavEdit, toPage, isActive, submitFunction = () => {} }) => {
  return (
    <ButtonsBlockStyled>
      <ButtonStyled
        variant="white"
        type="reset"
      >
        Сброс
      </ButtonStyled>

      <ButtonStyled
        variant="blue"
        disabled={!isActive}
        onClick={() => isActive && setNavEdit(toPage)}
      >
        Назад
      </ButtonStyled>

      <ButtonStyled
        variant="blue"
        type="submit"
        onClick={submitFunction}
      >
        Сохранить и далее
      </ButtonStyled>
    </ButtonsBlockStyled>
  );
};

export default ButtonsEdit;
