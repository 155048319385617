import styled from "styled-components";

const CreateDeveloperStyled = styled.form`
  position: absolute;
  width: 100vw;
  height: calc(100vh - 64px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlockStyled = styled.div`
  display: flex;
  gap: 9px;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #ECECEC;
  padding: 29px 77px 22px 33px;
`;

const TitlePopUpStyled = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
`;

const BlockInputsStyled = styled.div`
  display: flex;
  gap: 99px;
`;

const ButtonsBlockStyled = styled.div`
  display: flex;
  gap: 8px;
`;

export {
  CreateDeveloperStyled,
  BlockStyled,
  BlockInputsStyled,
  ButtonsBlockStyled,
  TitlePopUpStyled,
};