import { InputBlockStyled, TextAreaStyled, LabelStyled, ImgPlaceHolderStyled } from "./styled";
import phPng from '../../../images/placeholder.png';
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { useState } from "react";
import { ErrorTextStyled } from "../TextInput/styled";
import HelperText from "../../../pages/_shared/HelperText/HelperText";

type Props<T extends FieldValues> = {
  control: Control<T>,
  name: Path<T>;
  label: string;
  plasehold?: string;
  helperText?: JSX.Element | undefined;
  variantWidth: 'full' | 'small' | 'verySmall';
  isImgPlaceHolder?: boolean;
};

const TextAreaInput = <T extends FieldValues>({
  control,
  name,
  label,
  plasehold = "Введите значение",
  helperText = undefined,
  variantWidth,
  isImgPlaceHolder = true,
}: Props<T>) => {
  const [hasNoFocus, setHasNoFocus] = useState(true);

  return (
    <InputBlockStyled>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            {!!label && <LabelStyled isHelperText={!!helperText}>
                {!!helperText && <HelperText text={helperText}/>}
                {label}
              </LabelStyled>
            }

            <TextAreaStyled
              onFocus={() => setHasNoFocus(false)}
              onBlur={() => setHasNoFocus(true)}
              value={value}
              onChange={onChange}
              placeholder={plasehold}
              variantWidth={variantWidth}
            />
            
            {hasNoFocus && !value && isImgPlaceHolder && <ImgPlaceHolderStyled src={phPng}/>}
            
            <ErrorTextStyled>{!!error ? error.message : ' '}</ErrorTextStyled>
          </>
        )}
      />
    </InputBlockStyled>
  )
};

export default TextAreaInput;