import { AfterStyled, EditNavStyled, NavItemStyled } from "./styled";
import { NavigateOptionAdmin } from "../../../../types/NavigateOption";

type Props = {
  selectedTab: NavigateOptionAdmin;
  setNavEdit: React.Dispatch<React.SetStateAction<NavigateOptionAdmin>>;
};

const AdminNav: React.FC<Props> = ({ selectedTab, setNavEdit }) => {
  type EditNavItem = {
    name: string;
    nameActive: NavigateOptionAdmin;
  };

  const editNavItems: EditNavItem[] = [
    {
      name: "Интеграции",
      nameActive: "integrations",
    },
    {
      name: "Домены",
      nameActive: "domens",
    },
    {
      name: "Пользователи",
      nameActive: "users",
    },
  ];

  return (
    <EditNavStyled>
      {editNavItems.map((item) => (
        <NavItemStyled
          isActive={selectedTab === item.nameActive}
          key={item.name}
          onClick={() => setNavEdit(item.nameActive)}
        >
          {item.name}
        </NavItemStyled>
      ))}

      <AfterStyled selectedTab={selectedTab} />
    </EditNavStyled>
  );
};

export default AdminNav;
