import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {AppsType, FilterAppListType} from '../../types/TypePWA';
import { CreateNamechapType } from '../../components/PopUp/PopCreateNamechap/PopCreateNamechap';
import { CreateCloudflareType } from '../../components/PopUp/PopCreateCloudflare/PopCreateCloudflare';
import { CreateDomainType } from '../../components/PopUp/PopCreateBuyDomen/PopCreateBuyDomen';
import { AdminUserType } from '../../pages/AdminPage/components/Users/Users';

type InitialStateType = {
  appsList: AppsType[];
  appsListArchive: AppsType[];
  appFilterList: FilterAppListType[];
  appFilterListArchive: FilterAppListType[];
  namecheapList: CreateNamechapType[];
  cloudflareList: CreateCloudflareType[];
  adminUsers: AdminUserType[],
};

const initialState: InitialStateType = {
  appsList: [],
  appsListArchive: [],
  appFilterList: [],
  appFilterListArchive: [],
  namecheapList: [],
  cloudflareList: [],
  adminUsers: [],
};

const pwaListSlice = createSlice({
  name: 'PWAs',
  initialState,
  reducers: {
    setPWAList: ((state: InitialStateType, action: PayloadAction<AppsType[]>) => {
      state.appsList = action.payload;
    }),
    setPWAArciveList: ((state: InitialStateType, action: PayloadAction<AppsType[]>) => {
      state.appsListArchive = action.payload;
    }),
    setPWAFilterList: ((state: InitialStateType, action: PayloadAction<FilterAppListType[]>) => {
      state.appFilterList = action.payload;
    }),
    setPWAArciveFilterList: ((state: InitialStateType, action: PayloadAction<FilterAppListType[]>) => {
      state.appFilterListArchive = action.payload;
    }),
    setNamechapList: ((state: InitialStateType, action: PayloadAction<CreateNamechapType[]>) => {
      state.namecheapList = action.payload;
    }),
    setCloudflareList: ((state: InitialStateType, action: PayloadAction<CreateCloudflareType[]>) => {
      state.cloudflareList = action.payload;
    }),
    setAdminUser: ((state: InitialStateType, action: PayloadAction<AdminUserType[]>) => {
      state.adminUsers = action.payload;
    }),
  }
});

export const {
  setPWAList,
  setPWAArciveList,
  setPWAFilterList,
  setPWAArciveFilterList,
  setNamechapList,
  setCloudflareList,
  setAdminUser,
} = pwaListSlice.actions;
export default pwaListSlice.reducer;