import { useEffect } from "react";
import {
  deletePush,
  getPushItem,
  getPushSets,
} from "../../../api/requestService";
import Accordion from "../../../components/Accordion/Accordion";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooksStore";
import { setEditPush } from "../../../store/pushEdit/pushEdit";
import { CreatePushType, PushType } from "../../../types/PushTupe";
import { itemsEventPush, pushFilterCountries } from "../../../utils/consts";
import { EDIT_SVG, RED_DELETE_SVG } from "../../../utils/SVG";
import {
  ButtonIconStyled,
  ListItemsWidthStyled,
  ListNameItemStyled,
  ListNamesStyled,
  ListRowItemStyled,
  ListRowStyled,
  ListStyled,
  PushSetListStyled,
} from "./styled";
import { setPushSetsAll } from "../../../store/itemsSelect/itemsSelectSlice";
import { toast } from "react-toastify";
import { ButtonStyled } from "../../../helperStyled/styledHelper";

type itemsType = {
  id: number;
  name: string;
  is_active: boolean;
  category_id: string;
  time_after_event: number;
  event_type: string;
};

type Nam = {
  id: number;
  name: keyof itemsType | "delete" | "editPush";
  title: string;
  style: { [key: string]: any };
  styleList: { [key: string]: any };
};

const listNames: Nam[] = [
  {
    id: 1,
    name: "id",
    title: "ID",
    style: {
      width: "85px",
    },
    styleList: {
      width: "90px",
    },
  },
  {
    id: 2,
    name: "name",
    title: "Название",
    style: {
      width: "250px",
    },
    styleList: {
      width: "255px",
    },
  },
  {
    id: 3,
    name: "is_active",
    title: "Статус",
    style: {
      width: "170px",
    },
    styleList: {
      width: "175px",
    },
  },
  {
    id: 4,
    name: "category_id",
    title: "Категория",
    style: {
      width: "250px",
    },
    styleList: {
      width: "255px",
    },
  },
  {
    id: 5,
    name: "time_after_event",
    title: "Отправить через",
    style: {
      width: "250px",
    },
    styleList: {
      width: "255px",
    },
  },
  {
    id: 6,
    name: "event_type",
    title: "Событие",
    style: {
      width: "250px",
    },
    styleList: {
      width: "255px",
    },
  },
  {
    id: 7,
    name: "delete",
    title: "",
    style: {
      width: "85px",
    },
    styleList: {
      width: "90px",
      height: "63.6px",
    },
  },
  {
    id: 7,
    name: "editPush",
    title: "",
    style: {
      width: "92px",
    },
    styleList: {
      width: "92px",
      height: "63.6px",
    },
  },
];

type Props = {
  setIsCreate: React.Dispatch<React.SetStateAction<boolean>>;
};

const PushPageList: React.FC<Props> = ({ setIsCreate }) => {
  const { pushSets } = useAppSelector((state) => state.itemsSelect);
  const { category_idAll } = useAppSelector((state) => state.itemsSelect);
  const dispatch = useAppDispatch();

  const findCategoryFromId = (category_id: number) => {
    return category_idAll.find((item) => item.id === category_id)?.name;
  };

  const formatTimeAfter = (seconds: number) => {
    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const parts = [];

    if (days > 0) {
      parts.push(`Дней: ${days}`);
    }
    if (hours > 0) {
      parts.push(`Часов: ${hours}`);
    }
    if (minutes > 0) {
      parts.push(`Минут: ${minutes}`);
    }

    return parts.join(", ");
  };

  const pushItemsInSet = (pushItems: PushType[]) => {
    return pushItems.map((item) => {
      return {
        id: item.id,
        name: item.name,
        is_active: item.is_active,
        category_id: findCategoryFromId(item.category_id),
        time_after_event: formatTimeAfter(item.time_after_event),
        event_type: item.event_type,
      };
    });
  };

  const handleDeletePush = async (pushId: number) => {
    await deletePush(pushId);
  };

  const refactAfterTime = (timer: number, variant: "time" | "name") => {
    const result = {
      time_after_event: 0,
      time_after_event_name: "",
    };

    const days = Math.floor(timer / (24 * 3600));
    const hours = Math.floor((timer % (24 * 3600)) / 3600);
    const minutes = Math.floor((timer % 3600) / 60);

    if (days > 0) {
      result.time_after_event = days;
      result.time_after_event_name = "Дни";
    } else if (hours > 0) {
      result.time_after_event = hours;
      result.time_after_event_name = "Часы";
    } else {
      result.time_after_event = minutes;
      result.time_after_event_name = "Минуты";
    }

    return variant === "time"
      ? result.time_after_event
      : result.time_after_event_name;
  };

  const handleEditPush = async (pushId: number) => {
    try {
      const pushItem = await getPushItem(pushId);
      const pushItemToEdit = {
        id: pushItem.id,
        push_set_id: {
          id: pushItem.push_set_id,
          name: pushSets.find((item) => item.id === pushItem.push_set_id)?.name,
        },
        category_id: {
          id: pushItem.category_id,
          name: category_idAll.find((item) => item.id === pushItem.category_id)
            ?.name,
        },
        is_active: pushItem.is_active,
        name: pushItem.name,
        time_after_event: refactAfterTime(pushItem.time_after_event, "time"),
        time_after_event_name: refactAfterTime(
          pushItem.time_after_event,
          "name"
        ),
        event_type: {
          id: itemsEventPush.find((item) => item.name === pushItem.event_type)
            ?.id,
          name: pushItem.event_type,
        },
        push_languages: pushItem.push_languages.map((item: any) => {
          return {
            ...item,
            image: [item.image],
          };
        }),
        push_weekdays: pushItem.push_weekdays,
        push_countries: pushItem.push_countries.map((item: string) => {
          return {
            id: pushFilterCountries.findIndex(
              (itInd) => itInd.slice(0, 2) === item
            ),
            name: item,
          };
        }),
        push_filters: pushItem.push_filters,
      };

      dispatch(setEditPush(pushItemToEdit as CreatePushType));
      setIsCreate(true);
    } catch {
      console.error("error edit push");
      toast.error("Ошибка получения Push!");
    }
  };

  useEffect(() => {
    const fetchEffect = async () => {
      try {
        const pushSestTo = await getPushSets();

        dispatch(setPushSetsAll(pushSestTo));
      } catch (error) {
        console.error("Failed to fetch PushSets", error);
        toast.error("Ошибка получения PushSets!");
      }
    };

    fetchEffect();
  }, []);

  return (
    <PushSetListStyled>
      {pushSets.map((itemPushSet) => (
        <Accordion title={itemPushSet.name}>
          <ListStyled>
            <ListNamesStyled>
              {listNames.map((nam) => (
                <ListNameItemStyled css={nam.styleList} key={nam.id}>
                  {nam.title}
                </ListNameItemStyled>
              ))}
            </ListNamesStyled>

            <ListItemsWidthStyled>
              {!!itemPushSet.pushes.length && pushItemsInSet(itemPushSet.pushes).map((item) => (
                <ListRowStyled key={item.id}>
                  {listNames.map((nam) => (
                    <ListRowItemStyled css={nam.style} key={nam.id}>
                      {nam.name === 'delete' && (
                        <ButtonIconStyled onClick={() => handleDeletePush(item.id)} userPermision={true}>
                          <RED_DELETE_SVG />
                        </ButtonIconStyled>
                      )}
                      {nam.name === 'editPush' && (
                        <ButtonIconStyled onClick={() => handleEditPush(item.id)} userPermision={true}>
                          <EDIT_SVG />
                        </ButtonIconStyled>
                      )}
                      {nam.name !== 'delete' && nam.name !== 'editPush' && (
                        <div>
                          {item[nam.name]}
                        </div>
                      )}
                      {nam.name === 'is_active' && (item[nam.name] ? (
                        <div onClick={() => handleEditPush(item.id)}>
                          Активный
                        </div>
                      ) : (
                        <div onClick={() => handleEditPush(item.id)}>
                          Неактивный
                        </div>
                      ))}
                    </ListRowItemStyled>
                  ))}
                </ListRowStyled>
              ))}
            </ListItemsWidthStyled>
          </ListStyled>
        </Accordion>
      ))}
    </PushSetListStyled>
  );
};

export default PushPageList;
