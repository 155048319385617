import { useForm } from "react-hook-form";
import { BlockInputsStyled, BlockStyled, ButtonsBlockStyled, CreateDeveloperStyled } from "./styled";
import { ButtonStyled } from "../../../helperStyled/styledHelper";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooksStore";
import { setPopUp } from "../../../store/core/core";
import { getTikTokPixels, sendTikTokPixels } from "../../../api/requestService";
import { setTikTopPixelsAll } from "../../../store/itemsSelect/itemsSelectSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import TextInput from "../../Inputs/TextInput/TextInput";
import { MetricPixelType } from "../../../types/EditsTypes";
import NumberInput from "../../Inputs/NumberInput/NumberInput";
import { PopUpKeys } from "../../../types/TypeCore";
import { TitlePopUpStyled } from "../PopCreateBuyDomen/styled";
import { toast } from "react-toastify";
import ButtonItem from "../../ButtonItem/ButtonItem";

const PopCreateTikTokPixel = () => {
  const dispatch = useAppDispatch();
  const { handleSubmit, control } = useForm<MetricPixelType>({
    resolver: yupResolver(schema),
    defaultValues: {
      pixel_id: '',
      access_token: '',
    },
  });

  const { onChange, value } = useAppSelector(state => state.core.popUp);

  const onSubmit = async (data: MetricPixelType) => {
    try {
      const tikTokPixel = await sendTikTokPixels(data);

      !!onChange && onChange([...value, {id: tikTokPixel?.id, name: tikTokPixel?.pixel_id}]);
      
      const tikTokPixels = await getTikTokPixels();

      dispatch(setTikTopPixelsAll(tikTokPixels));

      toast.success('TikTok Pixel создан!');

      dispatch(setPopUp({popUpName: PopUpKeys.NONE,}));
    } catch {
      console.log('error create');
      toast.error('Ошибка создания TikTok Pixel!');
    }
  };

  return (
    <CreateDeveloperStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <TitlePopUpStyled>Создание TikTok Pixel</TitlePopUpStyled>

        <BlockInputsStyled>
          <NumberInput
            control = {control}
            name = 'pixel_id'
            label = 'Tik Tok Pixel'
            plasehold="Pixel ID or Track ID"
          />

          <TextInput
            control = {control}
            name = 'access_token'
            label = ' '
            isExit = {true}
            plasehold="Access toker or API key"
          />
        </BlockInputsStyled>

        <ButtonsBlockStyled>
          <ButtonStyled
            variant="white"
            type="reset"
            onClick={() => dispatch(setPopUp({popUpName: PopUpKeys.NONE,}))}
          >
            Отмена
          </ButtonStyled>

          <ButtonItem variant='blue' buttonText='Создать' funcClick={handleSubmit(onSubmit)}/>
        </ButtonsBlockStyled>
      </BlockStyled>
    </CreateDeveloperStyled>
  );
};

export default PopCreateTikTokPixel;
