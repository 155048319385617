import { memo } from "react";

import { Wrap } from "./styled";

type Props = {
  children: any;
};

const DatePickerContainer: React.FC<Props> = ({ children }) => {
  return <Wrap>{children}</Wrap>;
};

export const MemoizedDatePickerContainer = memo(DatePickerContainer);
