export type Core = {
  isCreateAvailable: boolean;
  popUp: SetPopUpType;
  isArchive: boolean;
};

export enum PopUpKeys {
  NONE = '',
  DEVELOPER = 'developer',
  RATING = 'rating',
  FACEBOOK_PIXEL = 'facebookPixel',
  TIKTOK_PIXEL = 'tikTokPixel',
  NAMECHAP = 'namechap',
  CLOUDFLARE = 'cloudflare',
  BUYDOMEN = 'buydomen',
  PUSHSET = 'pushset',
  TAG = 'tag',
}

export type PopUpNamesType = typeof PopUpKeys[keyof typeof PopUpKeys];

export type SetPopUpType = {
  popUpName: PopUpNamesType;
  onChange?: (...event: any[]) => void;
  value?: any;
};