import { combineReducers, configureStore } from '@reduxjs/toolkit';
import PWAListSlice from './PWAList/PWAListSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import coreSlice from './core/core';
import authSlice from './auth/authSlice';
import aplicationEditSlice from './aplicationsEdit/aplicationEdit';
import itemsSelectSlice from './itemsSelect/itemsSelectSlice';
import pushEditSlice from './pushEdit/pushEdit';

const rootReducer = combineReducers({
  pwalist: PWAListSlice,
  core: coreSlice,
  auth: authSlice,
  aplicationEdit: aplicationEditSlice,
  itemsSelect: itemsSelectSlice,
  pushEdit: pushEditSlice,
});

const persistConfig = {
  key: 'root-config',
  storage,
  blacklist: [],
  whitelist: [
    'auth',
    'aplicationEdit'
  ],
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
export type RootState = ReturnType<typeof store.getState>; 
export type AppDispatch = typeof store.dispatch;
