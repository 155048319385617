import styled from "styled-components";

const AccordionStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleStyled = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
`;



export {
  AccordionStyled,
  TitleStyled,
};