import { OptionStyled, OptionsSelectStyled } from "./styled";

type Props = {
  items: (string | { id: number | string, name: string, })[];
  isActive: boolean;
  css: { [key: string]: string };
  isClick: (...event: any[]) => void;
  activeItem: string | {id: number, name: string};
};

const OptionsSelect: React.FC<Props> = ({ items, isActive, css, isClick, activeItem }) => {
  return (
    <OptionsSelectStyled css={css} isActive={isActive}>
      {items.map((item) => (
        <OptionStyled onClick={() => isClick(item)} isActive={activeItem === item} key={typeof item === 'string' ? item : item.id}>
          {typeof item === 'string' ? item : item.name}
        </OptionStyled>
      ))}
    </OptionsSelectStyled>
  )
};

export default OptionsSelect;