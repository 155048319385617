import React, { useState } from "react";
import { HelperIconStyled, HelperContainerStyled, HelperTextStyled } from "./styled";

type Props = {
  text: JSX.Element;
  width?: number;
};

const HelperText: React.FC<Props> = ({text}) => {
  const [hoverItem, setHoverItem] = useState(false);

  return (
    <HelperContainerStyled
      onMouseEnter={() => setHoverItem(true)}
      onMouseLeave={() => setHoverItem(false)}
    >
      <HelperIconStyled>?</HelperIconStyled>
      {hoverItem && <HelperTextStyled>{text}</HelperTextStyled>}
    </HelperContainerStyled>
  )
};

export default HelperText;