import {
  ErrorTextStyled,
  ImgAddsStyled,
  ImgClearStyled,
  InputBlockStyled,
  InputMaskStyled,
  InputStyled,
  LabelStyled,
} from "./styled";
import subtrac from "../../../images/select.png";
import add from "../../../images/selectBack.png";

import { Control, Controller, FieldValues, Path } from "react-hook-form";

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  plasehold?: string;
  wid?: number;
  externalValue?: string;
  mask?: string | (string | RegExp)[];
  maxValue?: number | false;
};

const NumberInput = <T extends FieldValues>({
  control,
  name,
  label,
  plasehold = "Введите значение",
  wid = 253,
  externalValue = "",
  mask = "",
  maxValue = false,
}: Props<T>) => {

  const setChangeValue = (e: React.ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => {
    if (Number(e.target.value.slice(0, 2)) > 23) {
      if (Number(e.target.value.slice(3)) > 59) {
        onChange(`23:59`);
      } else {
        onChange(`23:${e.target.value.slice(3)}`);
      }
    } else {
      if (Number(e.target.value.slice(3)) > 59) {
        onChange(`${e.target.value.slice(0, 2)}:59`);
      } else {
        onChange(e.target.value);
      }
    }
  };

  return (
    <InputBlockStyled>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value = externalValue },
          fieldState: { error },
        }) => {

          if (maxValue && parseInt(value) > maxValue) {
            onChange(maxValue);
          }

          return (
            <>
              {!!label && <LabelStyled width={wid}>{label}</LabelStyled>}
              {!!mask ? (
                <InputMaskStyled
                  mask='99:99'
                  value={value}
                  onChange={(e) => setChangeValue(e, onChange)}
                  placeholder={plasehold}
                  width={wid}
                />
              ) : (
                <InputStyled
                  type="number"
                  value={value}
                  onChange={onChange}
                  placeholder={plasehold}
                  width={wid}
                />
              )}

              {!mask && <ImgAddsStyled isLabel={!!label}>
                <ImgClearStyled src={add} onClick={() => onChange(`${value ? parseInt(value) + 1 : 1}`)} />
                <ImgClearStyled src={subtrac} onClick={() => onChange(`${value && parseInt(value) > 0 ? parseInt(value) - 1 : 0}`)} />
              </ImgAddsStyled>}
              
              <ErrorTextStyled>{!!error ? error.message : ' '}</ErrorTextStyled>
            </>
          )
        }}
      />
    </InputBlockStyled>
  );
};

export default NumberInput;
