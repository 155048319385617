import { ButtonStyled } from "../../../../helperStyled/styledHelper";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooksStore";
import { setPopUp } from "../../../../store/core/core";
import { PopUpKeys } from "../../../../types/TypeCore";
import ListAdmin from "../ListAdmin/ListAdmin";
import { BlockStyled, IntegrationStyled } from "./styled";

const listNamesNC = [
  {
    id: 1,
    name: 'email',
    title: 'Почта',
    style: {
      width: '303.5px',
      paddingLeft: '36px',
    },
  },
  {
    id: 2,
    name: 'balance',
    title: 'Баланс',
    style: {
      width: '303.5px',
      paddingLeft: '36px',
    },
  },
];

const listNamesCF = [
  {
    id: 1,
    name: 'email',
    title: 'Почта',
    style: {
      width: '301px',
      paddingLeft: '36px',
    },
  },
];

const Integration = () => {
  const dispatch = useAppDispatch();
  const { namecheapList, cloudflareList } = useAppSelector(state => state.pwalist);

  const namecheapItems = namecheapList.map(item => {return {email: item.email, balance: '12'}});
  const cloudflareItems = cloudflareList.map(item => {return {email: item.email}});

  return (
    <IntegrationStyled>
      <BlockStyled>
        <ListAdmin label='Namecheap' listNames={listNamesNC} items={namecheapItems}/>

        <div>
          <ButtonStyled variant='blue' onClick={() => dispatch(setPopUp({ popUpName: PopUpKeys.NAMECHAP }))}>Добавить Namecheap</ButtonStyled>
        </div>
      </BlockStyled>

      <BlockStyled>
        <ListAdmin label='Cloudflare' listNames={listNamesCF} items={cloudflareItems}/>

        <div>
          <ButtonStyled variant='blue' onClick={() => dispatch(setPopUp({ popUpName: PopUpKeys.CLOUDFLARE }))}>Добавить Cloudflare</ButtonStyled>
        </div>
      </BlockStyled>
    </IntegrationStyled>
  );
};

export default Integration;