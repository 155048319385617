import { Control, Controller, FieldValues, Path } from "react-hook-form";
import {
  ErrorTextStyled,
  InputBlockStyled,
  ItemCheckBoxStyled,
} from "./styled";
import { ACCEPT_SVG, NOTACCEPT_SVG } from "../../../utils/SVG";

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  itemCheck: string;
  width?: string;
};

const CheckBoxInput = <T extends FieldValues>({
  control,
  name,
  itemCheck,
  width = '253px',
}: Props<T>) => {
  return (
    <InputBlockStyled>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value = false },
          fieldState: { error },
        }) => (
          <>
              <ItemCheckBoxStyled onClick={() => onChange(!value)}>
                {itemCheck}

                {value ? <ACCEPT_SVG /> : <NOTACCEPT_SVG />}
              </ItemCheckBoxStyled>
          </>
        )}
      />
    </InputBlockStyled>
  );
};

export default CheckBoxInput;
