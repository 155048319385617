import { toast } from "react-toastify";
import { updateAdminUser } from "../../../../api/requestService";
import { SelectInput } from "../../../../components/Inputs/SelectInput/SelectInput";
import { ButtonStyled } from "../../../../helperStyled/styledHelper";
import { useAppSelector } from "../../../../hooks/hooksStore";
import ListAdmin from "../ListAdmin/ListAdmin";
import { BlockStyled, IntegrationStyled } from "./styled";
import UserStatus from "./UserStatus/UserStatus";

export type AdminUserType = {
  "id": number;
  "email": string;
  "name": string;
  "telegram": string;
  "user_status": "admin" | "buyer" | "qa" | "teamlead";
  "user_rights": "own_pwa" | "team_pwa" | "all_pwa",
  "team_id": number;
  "password": string;
};

const listNames = [
  {
    id: 1,
    name: 'name',
    title: 'Username',
    style: {
      width: '250px',
      paddingLeft: '36px',
    },
  },
  {
    id: 2,
    name: 'date',
    title: 'Дата',
    style: {
      width: '250px',
      paddingLeft: '36px',
    },
    render: (item: any) => {
      return "none"
    }
  },
  {
    id: 3,
    name: 'user_rights',
    title: 'Права',
    style: {
      width: '250px',
      paddingLeft: '36px',
    },
  },
  {
    id: 4,
    name: 'user_status',
    title: 'Уровень доступа',
    style: {
      width: '235px',
      padding: '16px 0 0 15px',
    }, 
    render: (item: any) => {
      return <UserStatus item={item}/>
    }
  },
];

const Users = () => {
  const {adminUsers} = useAppSelector(state => state.pwalist);

  return (
    <IntegrationStyled>
      <BlockStyled>
        <ListAdmin listNames={listNames} items={adminUsers}/>

        {/* <div>
          <ButtonStyled variant='blue'>Сохранить</ButtonStyled>
        </div> */}
      </BlockStyled>
    </IntegrationStyled>
  );
};

export default Users;