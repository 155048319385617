import { Control } from "react-hook-form";
import Accordion from "../../../../../../components/Accordion/Accordion";
import {SelectControlInput} from "../../../../../../components/Inputs/SelectInput/SelectInput";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import {
  AddImagesBlockStyled,
  BlockInputsStyled,
  BonusTextStyled,
  ContentInputsStyled,
  ItemInputStyled
} from "./styled";
import PhotoInput from "../../../../../../components/Inputs/PhotoInput/PhotoInput";
import TextAreaInput from "../../../../../../components/Inputs/TextAreaInput/TextAreaInput";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/hooksStore";
import { DescriptionsEditType } from "../../../../../../types/EditsTypes";
import SwitchInput from "../../../../../../components/Inputs/SwitchInput/SwitchInput";
import { setPopUp } from "../../../../../../store/core/core";
import NumberInput from "../../../../../../components/Inputs/NumberInput/NumberInput";
import { PopUpKeys, SetPopUpType } from "../../../../../../types/TypeCore";

type Props = {
  control: Control<DescriptionsEditType, any>;
};

const BasicAcordion: React.FC<Props> = ({ control }) => {
  const { developersAll = [], ratingsAll = [], storeCategoriesAll = [] } = useAppSelector(state => state.itemsSelect) || {};
  const developersAllName = developersAll.map(item => item.name);
  const ratingsAllName = ratingsAll.map(item => item.total_rating.toString());
  const dispatch = useAppDispatch();

  const itemsBasic = ['123', '1541325', '13513', `23524`, '231512345'];
  const itemsAge = ['3+', '7+', '12+', `16+`, '18+'];

  const setDeveloperCreate = (onChange: (...event: any[]) => void) => {
    const creater: SetPopUpType = {
      popUpName: PopUpKeys.DEVELOPER,
      onChange: onChange,
    };

    dispatch(setPopUp(creater));
  };

  const setRatingCreate = (onChange: (...event: any[]) => void) => {
    const creater: SetPopUpType = {
      popUpName: PopUpKeys.RATING,
      onChange: onChange,
    };

    dispatch(setPopUp(creater));
  };

  const isUpMenuHelpText = (
    <>
      Не&nbsp;работает&nbsp;на&nbsp;системных&nbsp;доменах
    </>
  );

  const videoYouTubeHelpText = (
    <>
      Ссылка&nbsp;не&nbsp;YouTube,&nbsp;Vimeo,&nbsp;Dailymotion&nbsp;или&nbsp;mp4
    </>
  );

  return (
    <Accordion title="Основное">
      <ContentInputsStyled>
        <ItemInputStyled>
          <SwitchInput control={control} name='isUpMenu' label="Показывать верхнее меню:" helperText={isUpMenuHelpText}/>
        </ItemInputStyled>

        <BlockInputsStyled>
          <ItemInputStyled>
            <SelectControlInput control={control} name="store_category_id" label="Категория в Store" items={storeCategoriesAll} isAutocomplete={true}/>
          </ItemInputStyled>
        </BlockInputsStyled>
        
        <BlockInputsStyled>
          <ItemInputStyled>
            <TextInput control={control} name='name' label="Название" isExit={true} />
          </ItemInputStyled>

          <ItemInputStyled>
            <SelectControlInput
              control={control}
              name='developer_contacts_id'
              label="Контакты разработчика"
              items={developersAllName}
              isAdding={true}
              setCreate={setDeveloperCreate}
            />
          </ItemInputStyled>

          <ItemInputStyled>
            <TextInput control={control} name='developer_name' label="Имя разработчика" isExit={true} />
          </ItemInputStyled>
        </BlockInputsStyled>

        <BlockInputsStyled>
          <ItemInputStyled>
            <TextInput control={control} name='videoYouTube' label="Видео YouTube" isExit={true} helperText={videoYouTubeHelpText}/>
          </ItemInputStyled>

          <AddImagesBlockStyled>
            <PhotoInput control={control} name='icon' label="Иконка" isMulti={false} isFixOneToOne={true}/>

            <PhotoInput control={control} name='images' label="Скриншоты" isMulti={true} />
          </AddImagesBlockStyled>
        </BlockInputsStyled>


        <BlockInputsStyled>
          <ItemInputStyled>
            <NumberInput control={control} name='total_downloads' label="Загрузок" />
          </ItemInputStyled>
        </BlockInputsStyled>

        <BlockInputsStyled>
          <ItemInputStyled>
            <SelectControlInput
              control={control}
              name='rating_id'
              label="Рейтинг"
              items={ratingsAllName}
              isAdding={true}
              setCreate={setRatingCreate}
            />
          </ItemInputStyled>
        </BlockInputsStyled>

        <BlockInputsStyled>
          <ItemInputStyled>
            <SwitchInput control={control} name='is_editors_choice' label="Выбор редакции:"/>
          </ItemInputStyled>
        </BlockInputsStyled>

        <BlockInputsStyled>
          <ItemInputStyled>
            <SelectControlInput control={control} name='age_rating' label="Возраст" items={itemsAge}/>
          </ItemInputStyled>
        </BlockInputsStyled>

        <BlockInputsStyled>
          <ItemInputStyled>
            <TextAreaInput control={control} name='description' label="Описание" variantWidth='small'/>
          </ItemInputStyled>
        </BlockInputsStyled>
      </ContentInputsStyled>
    </Accordion>
  )
};

export default BasicAcordion;