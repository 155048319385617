import { useState } from "react";
import { CircleActiveStyled, SwitchStyled } from "./styled"

type Props = {
  isActive: boolean
  setIsActive: (...event: any[]) => void | React.Dispatch<React.SetStateAction<boolean>>;
}

const SwitchCircle: React.FC<Props> = ({ isActive, setIsActive }) => {
  return (
    <SwitchStyled
      isActive={isActive}
      onClick={() => setIsActive(!isActive)}
    >
      <CircleActiveStyled isActive={isActive} />
    </SwitchStyled>
  )
};

export default SwitchCircle;