import styled from "styled-components";

const TextStyled = styled.div`
  position: relative;
`;

interface ItemWaitingProps {
  variant: `blue` | `white` | 'darkBlue' | 'darkBorder' | 'red';
};

const ItemWaitingStyled = styled.div<ItemWaitingProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({variant}) => {switch (variant) {
    case 'blue':
      return `
        background-color: #4091F7;
      `;

    case 'white':
      return `
        background-color: #FFF;
      `;

    case 'darkBlue':
      return `
        background-color: #2418E4;
      `;

    case 'darkBorder':
      return `
        background-color: #FFF;
      `;

    case 'red':
      return `
        background-color: #FF6B6C;
      `;
  }}}

  & > * {
    width: 20px;
    height: 20px;
  }
`;

type SpinnerType = {
  variant: 'white' | 'blue';
};

const SpinerStyled = styled.div<SpinnerType>`
  width: ${({variant}) => variant === 'white'
    ? `20px`
    : `170px`};
  aspect-ratio: 1;
  --_c: ${({variant}) => variant === 'white'
    ? `radial-gradient(farthest-side,white 92%,#0000)`
    : `radial-gradient(farthest-side,#4091F7 92%,#0000)`};
  background: 
    var(--_c) top,
    var(--_c) left,
    var(--_c) right,
    var(--_c) bottom;
  background-size: ${({variant}) => variant === 'white'
    ? `5px 5px`
    : `40px 40px`};
  background-repeat: no-repeat;
  animation: s7 1s infinite;

  @keyframes s7 {to{transform: rotate(.5turn)}}
`;

export {
  TextStyled,
  ItemWaitingStyled,
  SpinerStyled
}