import { useForm } from "react-hook-form";
import TextInput from "../../Inputs/TextInput/TextInput";
import { BlockInputsStyled, BlockStyled, ButtonsBlockStyled, CreateDeveloperStyled } from "./styled";
import { ButtonStyled } from "../../../helperStyled/styledHelper";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooksStore";
import { setPopUp } from "../../../store/core/core";
import { getDevelopers, sendAppDeveloper } from "../../../api/requestService";
import { setDevelopersAll } from "../../../store/itemsSelect/itemsSelectSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import { PopUpKeys } from "../../../types/TypeCore";
import { TitlePopUpStyled } from "../PopCreateBuyDomen/styled";
import { toast } from "react-toastify";
import ButtonItem from "../../ButtonItem/ButtonItem";

type CreateDeveloperType = {
  website: string;
  address: string;
  name: string;
  policy: string;
  email: string;
};

const PopCreateDeveloper = () => {
  const dispatch = useAppDispatch();
  const { handleSubmit, control } = useForm<CreateDeveloperType>({
    resolver: yupResolver(schema),
  });
  const { onChange } = useAppSelector(state => state.core.popUp);

  const onSubmit = async (data: CreateDeveloperType) => {
    try {
      const developer = await sendAppDeveloper(data);

      !!onChange && onChange(developer?.name);

      const developers = await getDevelopers();

      dispatch(setDevelopersAll(developers));

      toast.success('Разработчик создан!');

      dispatch(setPopUp({popUpName: PopUpKeys.NONE}));
    } catch {
      console.log('error create');
      toast.error('Ошибка создания разработчика!');
    }
  };

  return (
    <CreateDeveloperStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <TitlePopUpStyled>Создание Разработчика</TitlePopUpStyled>

        <BlockInputsStyled>
          <TextInput control={control} name='website' label="Сайт" isExit={true} />

          <TextInput control={control} name='address' label="Адресс" isExit={true} />
        </BlockInputsStyled>

        <BlockInputsStyled>
          <TextInput control={control} name='name' label="Название" isExit={true} />

          <TextInput control={control} name='policy' label="Политика " isExit={true} />
        </BlockInputsStyled>

        <BlockInputsStyled>
          <TextInput control={control} name='email' label="Email" isExit={true} />
        </BlockInputsStyled>

        <ButtonsBlockStyled>
          <ButtonStyled
            variant="white"
            type="reset"
            onClick={() => dispatch(setPopUp({popUpName: PopUpKeys.NONE}))}
          >
            Отмена
          </ButtonStyled>

          <ButtonItem variant='blue' buttonText='Создать' funcClick={handleSubmit(onSubmit)}/>
        </ButtonsBlockStyled>
      </BlockStyled>
    </CreateDeveloperStyled>
  );
};

export default PopCreateDeveloper;
