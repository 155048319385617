import styled from 'styled-components';

interface SwitchProps {
  isActive: boolean;
};

const SwitchStyled = styled.div<SwitchProps>`
  width: 52px;
  height: 32px;
  border: 2px solid ${({isActive}) => isActive ? '#4091F7' : '#323232'};
  background-color: ${({isActive}) => isActive ? '#4091F7' : 'none'};
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding-inline: 8px;
  transition: background-color 0.3s;
`;

interface CircleActiveProps {
  isActive: boolean;
}

const CircleActiveStyled = styled.div<CircleActiveProps>`
  width: ${({isActive}) => isActive ? 24 : 16}px;
  height: ${({isActive}) => isActive ? 24 : 16}px;
  border-radius: 100px;
  background-color: ${({isActive}) => isActive ? '#FFF' : '#323232'};
  transition: background-color 0.3s, transform 0.3s, width 0.3s, height 0.3s;

  transform: translateX(${({isActive}) => isActive ? 50 : 0}%);
`;

export {
  SwitchStyled,
  CircleActiveStyled,
}