import styled from 'styled-components';

const FormBasicStyled = styled.form`
  padding-block: 30px 38px;
`;

const FormInputsStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
`;

const ItemsBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const ConteinerInputStyled = styled.div`
  width: 253px;
`;

const InputBlockStyled = styled.div`
  display: flex;
`;

export {
  FormBasicStyled,
  FormInputsStyled,
  ItemsBlockStyled,
  ConteinerInputStyled,
  InputBlockStyled,
};