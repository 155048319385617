import styled from "styled-components";

const InputBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .input-range {
    &__slider-container {
      transition: none;
    }

    &__slider {
      background-color: white;
      border: 2px solid #4091F7;
    }

    &__track {
      transition: none;

      &--active {
        background-color: #4091F7;
      }
    }

    &__label {
      &--min {
        display: none;
      }
      &--max {
        display: none;
      }
    }
  }
`;

const LabelStyled = styled.label`
  white-space: nowrap;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  margin-bottom: 25px;
`;

const InputStyled = styled.input`
  width: 800px;
  height: 5px;
  margin-inline: 4px;
  background-color: #DAE3EC;
  appearance: none;
  overflow: hidden;
  outline: none;
`;

export {
  InputBlockStyled,
  LabelStyled,
  InputStyled,
};