type App = {
  language: string[],
  title: string,
  text: string,
  image: string,
  is_defaul: boolean,
  buttonsList: string,
};

type Nam = {
  id: number;
  name: keyof App;
  title: string;
  style: {
    width: string;
    paddingLeft?: string;
    paddingRight?: string;
  };
  styleList: {
    width: string;
  };
};

export const listNames: Nam[] = [
  {
    id: 1,
    name: 'language',
    title: 'Языки',
    style: {
      width: '200px',
    },
    styleList: {
      width: '205px',
    },
  },
  {
    id: 2,
    name: 'is_defaul',
    title: 'По умолчанию',
    style: {
      width: '150px',
    },
    styleList: {
      width: '155px',
    },
  },
  {
    id: 3,
    name: 'title',
    title: 'Заголовок',
    style: {
      width: '180px',
    },
    styleList: {
      width: '185px',
    },
  },
  {
    id: 4,
    name: 'text',
    title: 'Сообщение',
    style: {
      width: '180px',
    },
    styleList: {
      width: '185px',
    },
  },
  {
    id: 5,
    name: 'image',
    title: 'Изображение',
    style: {
      width: '140px',
    },
    styleList: {
      width: '145px',
    },
  },
  {
    id: 6,
    name: 'buttonsList',
    title: ' ',
    style: {
      width: '150px',
    },
    styleList: {
      width: '150px',
    },
  },
];