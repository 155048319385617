import { NavigateOptionEdit } from "../../../../types/NavigateOption";
import ContextDescription from "./ContextDescription/ContextDescription";
import LanguageDescription from "./LanguageDescription/LanguageDescription";

type Props = {
  setNavEdit: React.Dispatch<React.SetStateAction<NavigateOptionEdit>>;
}

const EditDescription: React.FC<Props> = ({ setNavEdit }) => {
 return (
  <>
    {/* <LanguageDescription /> */}
    <ContextDescription setNavEdit={setNavEdit} />
  </>
 );
};

export default EditDescription;