import { Control, FieldErrors, useFieldArray } from "react-hook-form";
import { ButtonAddStyled, FilterBlockStyled, LeftTableStyled, ListFilterStyled, ListItemsWidthStyled, ListNameItemStyled, ListNamesStyled, ListRowItemStyled, ListRowStyled, NameStyled, PushMassageStyled, RightTableStyled } from "./styled";
import { listNames } from "./PushListFilterApi";
import { ButtonStyled } from "../../../../helperStyled/styledHelper";
import {SelectControlInput} from "../../../../components/Inputs/SelectInput/SelectInput";
import { pushFilterColumn, pushFilterCountries, pushFilterLogicOperator, pushFilterStatement, pushMessageLanguage } from "../../../../utils/consts";
import TextInput from "../../../../components/Inputs/TextInput/TextInput";
import { MultiSelectControl } from "../../../../components/Inputs/MultiSelectInput/MultiSelectInput";
import { CreatePushType } from "../../../../types/PushTupe";
import { ErrorValidationStyled } from "../PushListMessage/styled";

type Props = {
  control: Control<CreatePushType, any>;
  errors: FieldErrors<Omit<CreatePushType, "id">>;
}

const PushListFilter: React.FC<Props> = ({control, errors}) => {
  const defaultValue = {
    logic_operator: 'AND',
    filtered_column: '',
    statement: '',
    value: '',
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'push_filters',
  });

 return (
  <PushMassageStyled>
    <NameStyled>Фильтр</NameStyled>

    <FilterBlockStyled>
      <LeftTableStyled>Фильтры</LeftTableStyled>
      <RightTableStyled>Значение</RightTableStyled>
    </FilterBlockStyled>

    <FilterBlockStyled>
      <LeftTableStyled>Страны</LeftTableStyled>

      <RightTableStyled>
        <MultiSelectControl
          control={control}
          name='push_countries'
          label=""
          items={pushFilterCountries.map((item, index) => {return {id: index, name: item}})}
          placehoder="Все"
          width="330px"
          maxWidth="calc(100vw - 810.1px)"
        />
      </RightTableStyled>
    </FilterBlockStyled>

    <FilterBlockStyled>
      <LeftTableStyled>Sub</LeftTableStyled>

      <RightTableStyled>
        <ListFilterStyled>
          <ListNamesStyled>
            {listNames.map((nam) => (
              <ListNameItemStyled css={nam.styleList} key={nam.id}>
                {nam.title}
              </ListNameItemStyled>
            ))}
          </ListNamesStyled>

          <ListItemsWidthStyled>
            {!!fields.length && fields.map((item, index) => (
              <ListRowStyled key={item.id}>
                <ListRowItemStyled css={listNames[0].style}>
                  <SelectControlInput width="90px" control={control} name={`push_filters.${index}.logic_operator`} label="" items={pushFilterLogicOperator}/>
                </ListRowItemStyled>

                <ListRowItemStyled css={listNames[1].style}>
                  <SelectControlInput width="130px" placeholder="" control={control} name={`push_filters.${index}.filtered_column`} label="" items={pushFilterColumn}/>
                </ListRowItemStyled>

                <ListRowItemStyled css={listNames[2].style}>
                  <SelectControlInput width="160px" placeholder="" control={control} name={`push_filters.${index}.statement`} label="" items={pushFilterStatement}/>
                </ListRowItemStyled>

                <ListRowItemStyled css={listNames[3].style}>
                  <TextInput width="130px" plasehold='' control={control} name={`push_filters.${index}.value`} label="" isExit={false}/>
                </ListRowItemStyled>

                <ListRowItemStyled css={listNames[4].style}>
                  <ButtonStyled variant="red" onClick={() => remove(index)}>Удалить</ButtonStyled>
                </ListRowItemStyled>
              </ListRowStyled>
            ))}
          </ListItemsWidthStyled>

          {errors.push_filters && (
            <ErrorValidationStyled>
              {errors.push_filters.message && <>{errors.push_filters.message}</>}
            </ErrorValidationStyled>
          )}

          <ButtonAddStyled type="button" variant="blue" onClick={() => append(defaultValue)}>Добавить</ButtonAddStyled>
        </ListFilterStyled>
      </RightTableStyled>
    </FilterBlockStyled>
  </PushMassageStyled>
 )
};

export default PushListFilter;