import Stand from "../_shared/Stand/Stand";
import FindPWA from "./components/FindPWA/FindPWA";
import TablesPWA from "./components/TablesPWA/TablesPWA";

const ListPage = () => {
  return (
    <div>
      <Stand />
      <FindPWA />
      <TablesPWA />
    </div>
  );
};

export default ListPage;
