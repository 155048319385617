import styled from "styled-components";

const ListStyled = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const LabelStyled = styled.div`
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
`;

const ListNamesStyled = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #BFBFBF;
  border-radius: 10px;
  background-color: #F9F9F9;

  & > :not(:first-child) {
    position: relative;
    margin-left: 5px;

    &::before {
      content: '';
      position: absolute;
      height: 18px;
      width: 1px;
      background-color: #4091F7;
      left: 0;
    }
  }
`;

type ListNameItemProps = {
  css: any;
}

const ListNameItemStyled = styled.p<ListNameItemProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-block: 0;
  white-space: nowrap;
  flex-shrink: 0;
  padding-block: 20px 22px;

  ${({ css }) => css}
`;

const ListItemsWidthStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 270.8px;
  min-height: 270.8px;
  overflow: auto;
`;

const ListRowStyled = styled.div`
  display: flex;

  & > :not(:first-child) {
    margin-left: 5px;
  }
`;

type ListRowItemProps = {
  css: any;
}

const ListRowItemStyled = styled.p<ListRowItemProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  flex-shrink: 0;
  padding-block: 21px;
  border: 1px solid #BFBFBF;
  border-radius: 10px;
  background-color: #F9F9F9;
  margin: 0;
  
  display: flex;
  align-items: center;

  ${({ css }) => css}
`;

export {
  ListStyled,
  LabelStyled,
  ListNamesStyled,
  ListNameItemStyled,
  ListItemsWidthStyled,
  ListRowStyled,
  ListRowItemStyled,
};