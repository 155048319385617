import styled from "styled-components";

const HelperContainerStyled = styled.div`
  position: relative;
`;

const HelperIconStyled = styled.div`
  border-radius: 50%;
  width: 17px;
  height: 17px;
  background-color: #4091F7;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const HelperTextStyled = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  position: absolute;
  border-radius: 5px;
  left: 20px;
  top: 0;
  padding: 5px 10px;
  z-index: 100;
  white-space: pre-wrap;
`;

export {
  HelperIconStyled,
  HelperContainerStyled,
  HelperTextStyled,
};