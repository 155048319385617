import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { defaultValuePushEdits } from "../../utils/consts";
import { CreatePushType, PushInitialEditType } from "../../types/PushTupe";

const initialState: PushInitialEditType = defaultValuePushEdits;

const pushEditSlice = createSlice({
  name: "pushEdit",
  initialState,
  reducers: {
    setClearPushCreator: () => {
      return defaultValuePushEdits;
    },
    setEditPush: (
      state: PushInitialEditType,
      action: PayloadAction<CreatePushType>
    ) => {
      state.itemsEdit = action.payload;
      state.variant = 'edit';
    },
    setCreatePush: (
      state: PushInitialEditType,
    ) => {
      state.itemsEdit = defaultValuePushEdits.itemsEdit;
      state.variant = "create";
    },
  },
});

export const {
  setClearPushCreator,
  setEditPush,
  setCreatePush,
} = pushEditSlice.actions;
export default pushEditSlice.reducer;
