import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup
    .string()
    .required('Поле обязательное!'),
  api_key: yup
    .string()
    .required('Поле обязательное!'),
  email: yup
    .string()
    .email('Некорректный email!')
    .required('Поле обязательное!'),
});