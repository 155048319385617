import { useState } from "react";
import Stand from "../_shared/Stand/Stand";
import { EditBoardStyled, EditDashStyled } from "./styled";
import { NavigateOptionAdmin } from "../../types/NavigateOption";
import AdminNav from "./components/AdminNav/AdminNav";
import Integration from "./components/Integration/Integration";
import Domens from "./components/Domens/Domens";
import Users from "./components/Users/Users";
import { getUserPermision } from "../../utils/func";
import { Navigate } from "react-router-dom";

type AdminTabs = Record<NavigateOptionAdmin, JSX.Element>;

const AdminPage = () => {
  const [selectedTab, setNavEdit] = useState<NavigateOptionAdmin>("integrations");

  const editTabs: AdminTabs = {
    integrations: <Integration />,
    domens: <Domens />,
    users: <Users />,
  };

  const adminPermision = getUserPermision('admipPage');

  return (
    <>
      {adminPermision ? (
        <>
          <Stand />
          <EditDashStyled>
            <AdminNav selectedTab={selectedTab} setNavEdit={setNavEdit} />
            <EditBoardStyled>{editTabs[selectedTab]}</EditBoardStyled>
          </EditDashStyled>
        </>
      ) : (
        <Navigate to="main/list" replace />
      )}
    </>
  );
};

export default AdminPage;
