import styled from 'styled-components';

const StandStyled = styled.div`
  padding: 18px 0 18px 4px;
  margin-bottom: 15px;
`;

const BlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AdressStyled = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
`;

const NameStyled = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
`;

export {
  StandStyled,
  BlockStyled,
  AdressStyled,
  NameStyled,
};