import styled from 'styled-components';
import { ButtonStyled } from '../../../../../helperStyled/styledHelper';

const FormBasicStyled = styled.div`
  padding-block: 30px 38px;
`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContainerCommentStyled = styled.form`
  display: flex;
  gap: 30px;
`;

const BlockStyled = styled.div`
  padding: 36px 23px 36px 25px;
  border: 1px solid #ECECEC;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const RowStyled = styled.div`
  display: flex;
  gap: 20px;
`;

const ButtonAddStyled = styled(ButtonStyled)`
  width: 66px;
`;

const ButtonDeleteStyled = styled.div`
  cursor: pointer;
`;

const ButtonsSubmitStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export {
  FormBasicStyled,
  ContentStyled,
  ContainerCommentStyled,
  BlockStyled,
  RowStyled,
  ButtonAddStyled,
  ButtonDeleteStyled,
  ButtonsSubmitStyled,
};