import { useRef, useState } from "react";
import { AccordionStyled, TitleStyled } from "./styled";
import { useSpring, animated } from '@react-spring/web';
import { ImgStyled } from "../../helperStyled/styledHelper";
import selectPng from '../../images/select.png';


type Props = {
  title: string;
  children: React.ReactNode;
};

const Accordion: React.FC<Props> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const height = contentRef.current ? contentRef.current.scrollHeight : 0;

  const styling = useSpring({
    visible: isOpen ? '1' : '0',
    height: isOpen ? height : 0,
    opacity: isOpen ? 1 : 0,
    config: { tension: 200, friction: 20 },
    overflow: isOpen ? 'visible' : 'hidden',
  })

  const stylingImg = useSpring({
    height: '7.41px',
    transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
  })
  
  return (
    <AccordionStyled>
      <TitleStyled onClick={handleClick}>
        <animated.div style={stylingImg}>
          <ImgStyled wid={12} hei={7.41} css="display: block;" src={selectPng} alt='accordion'/>
        </animated.div>

        {title}
      </TitleStyled>

      <animated.div style={styling}>
        <div ref={contentRef}>
          {children}
        </div>
      </animated.div>
    </AccordionStyled>
  );
};

export default Accordion;