import { BasicsEditType } from './../../../../types/EditsTypes';
import styled from 'styled-components';

const FormBasicStyled = styled.form`
  display: flex;
  flex-direction: column;
  padding-block: 30px 38px;
  gap: 14px;
`;

const BlockStyled = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InfoTextStyled = styled.div`
  &::before {
    content: '•';
    margin-right: 8px;
    color: #323232;
    font-size: 20px;
    vertical-align: middle;
  }

  font-weight: 400;
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  margin-block: 20px 10px;
`;

export {
  FormBasicStyled,
  BlockStyled,
  InfoTextStyled,
};