import { toast } from "react-toastify";
import { getAdminUsers, updateAdminUser } from "../../../../../api/requestService";
import { itemsUserStatus, statusToRights } from "../../../../../utils/consts";
import { SelectInput } from "../../../../../components/Inputs/SelectInput/SelectInput";
import { useAppDispatch } from "../../../../../hooks/hooksStore";
import { setAdminUser } from "../../../../../store/PWAList/PWAListSlice";

type Props = {
  item: any;
}

const UserStatus: React.FC<Props> = ({ item }) => {
  const dispatch = useAppDispatch();

  const changer = async (user_status: "admin" | "buyer" | "qa" | "teamlead") => {
    try {
      const newValue = {
        "id": item.id,
        "email": item.email,
        "name": item.name,
        "telegram": item.telegram,
        "user_status": user_status,
        "user_rights": statusToRights[user_status],
        "team_id": item.team_id,
      };

      await updateAdminUser(newValue, item.id);
      const adminUsers = await getAdminUsers();

      dispatch(setAdminUser(adminUsers));
      toast.success(`Статус пользователя ${item.name} обновлен!`)
    } catch (error) {
      console.error('Error update User status:', error);
      toast.error('Ошибка изменения статуса пользователя!');
    }
  };

  return (
    <SelectInput
      label=""
      items={itemsUserStatus}
      onChange={changer}
      value={item.user_status}
      width='200px'
    />
  )
};

export default UserStatus;