import * as yup from 'yup';

export const schema = yup.object().shape({
  // name: yup
  //   .string()
  //   .min(3, 'Не меньше 3-х символов!')
  //   .max(40, 'Не больше 40 символов!')
  //   .required('Поле обязательное!'),
  // category_id: yup
  //   .string()
  //   .required('Поле обязательное!'),
  // tracker_id: yup
  // .string()
  // .required('Поле обязательное!'),
  // domain_id: yup
  // .string()
  // .required('Поле обязательное!'),
  // teg: yup.string().nullable(),
  // typeWhite: yup.string().nullable(),
  android_only: yup
    .boolean()
    .required('Поле обязательное!'),
  block_vpn_tor_hosting: yup
    .boolean()
    .required('Поле обязательное!'),
  block_proxy: yup
    .boolean()
    .required('Поле обязательное!'),
  trafficback_for_all: yup
    .boolean()
    .required('Поле обязательное!'),
  trafficback_for_not_android: yup
    .boolean()
    .required('Поле обязательное!'),
  pixel_as_img: yup
    .boolean()
    .required('Поле обязательное!'),
  pixel_in_whitepage: yup
    .boolean()
    .required('Поле обязательное!'),
  redirect_type: yup
    .string()
    .required('Поле обязательное!'),
});