import styled, { css } from "styled-components";
import borderImg from '../../../images/BorderIcon.png';

const ContainerStyled = styled.div`
  position: relative;
`;

const UploadContainerStyled = styled.div`
  border-radius: 10px;
  width: 80px;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 7px;
  align-items: center;
  cursor: pointer;
  text-align: center;
  gap: 5.1px;
  position: relative;

  background-image: url(${borderImg});
  background-size: cover;
  background-position: center;
`;

const UploadInputStyled = styled.input`
  display: none;
`;

const IconStyled = styled.img`
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
`;

type PreviewContainerProps ={
  variant: 'bottom' | 'right';
};

const PreviewContainerStyled = styled.div<PreviewContainerProps>`
  position: absolute;

  ${({variant}) => {
    switch (variant) {
      case 'bottom': return css`
          left: -5px;
          top: 80px;
        `

      case 'right': return css`
        top: 0;
        left: 80px;
      `

      default: return css`
        left: -5px;
        top: 80px;
      `
    }
  }}
  
  display: flex;
  gap: 12px;
  max-width: 369px;
  overflow-x: auto;
`;

const PreviewBlockStyled = styled.div`
  position: relative;
`;

type PreviewImageType = {
  isMultiple: boolean;
  isHover: boolean;
};

const PreviewImageStyled = styled.img<PreviewImageType>`
  ${({isMultiple}) => isMultiple
  ? css`
      width: 115px;
      height: 204.44px;
    `
  : css`
      width: 80px;
      height: 80px;
    `
  }

  transition: filter 0.3s ease;

  ${({isHover}) => isHover && css`
    filter: brightness(70%);
    `
  }

  margin: 5px;
  flex-shrink: 0;

  box-shadow: 0 -1px 5px #00000017, 0 3px 5px #0000000f, 0 1px 2px #0000004d, 0 1px 3px #00000026;
  border-radius: 10px;
  object-fit: cover;
  background-color: black;
`;

const PreviewDeleteItemStyled = styled.img<PreviewImageType>`
  ${({isMultiple}) => isMultiple
  ? css`
      right: 10px;
      top: 10px;
    `
  : css`
      left: 35px;
      top: 35px;
    `
  }

  opacity: 0;
  transition: opacity 0.2s ease;

  ${({isHover}) => isHover && css`
    opacity: 1;
    `
  }

  cursor: pointer;

  filter: brightness(0) invert(1);
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 1000;
`;

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
`;

const BlockItemsStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const AfterNameStyled = styled.div`
  font-size: 12px;
  font-weight: 400px;
  cursor: pointer;
  display: flex;
`;

const DeleteItemStyled = styled.div`
  color: red;
`;



export {
  ContainerStyled,
  UploadContainerStyled,
  UploadInputStyled,
  IconStyled,
  PreviewContainerStyled,
  PreviewBlockStyled,
  PreviewImageStyled,
  PreviewDeleteItemStyled,
  LabelStyled,
  BlockItemsStyled,
  AfterNameStyled,
  DeleteItemStyled,
}