import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { useState } from "react";
import { ContainerStyled, IconStyled, LabelStyled, PreviewBlockStyled, PreviewContainerStyled, PreviewDeleteItemStyled, PreviewImageStyled, UploadContainerStyled, UploadInputStyled } from "./styled";
import addIcon from '../../../images/delete.png';
import exit from '../../../images/exit.png';
import { ErrorTextStyled } from "../TextInput/styled";
import { toast } from "react-toastify";

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  isMulti: boolean;
  variant?: 'bottom' | 'right';
  isFixOneToOne?: boolean;
};

const PhotoInput = <T extends FieldValues>({ control, name, label, isMulti, variant = 'bottom', isFixOneToOne = false}: Props<T>) => {
  const [hoverItem, setHoverItem] = useState<null | number>(null);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void,
    value: string[],
  ) => {
    const files = Array.from(event.target.files || []);
  
    const readFile = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    };

    const isImageSquare = (imageSrc: string): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          resolve(img.width === img.height);
        };
        img.onerror = reject;
        img.src = imageSrc;
      });
    };
  
    try {
      const imageFiles = await Promise.all(
        files.filter(file => file.type.startsWith('image/')).map(readFile)
      );

      const validImages: string[] = [];

      if (isFixOneToOne) {
        for (const image of imageFiles) {
          const isSquare = await isImageSquare(image);
          if (isSquare) {
            validImages.push(image);
          } else {
            toast.error('Размер иконки должен быть в соотношении 1:1')
            console.error('Image is not square:', image);
          }
        }
      } else {
        validImages.push(...imageFiles);
      }
  
      const itemsToChange = isMulti ? [...value, ...validImages] : validImages;
  
      onChange(itemsToChange);
      event.target.value = '';
    } catch (error) {
      console.error('Error reading files:', error);
    }
  };

  const handleDeleteItem = (index: number, onChange: (...event: any[]) => void, value: string[]) => {
    onChange(value.filter((item, ind) => ind !== index));
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
        <ContainerStyled>
          <UploadContainerStyled onClick={() => document.getElementById(name)?.click()}>
            <UploadInputStyled
              type="file"
              accept="image/*"
              multiple={isMulti}
              id={name}
              onChange={(event) => handleImageChange(event, onChange, value)}
            />

            <IconStyled src={addIcon} />

            <LabelStyled>{label}</LabelStyled>
          </UploadContainerStyled>


          {!!value.length && (
            <PreviewContainerStyled variant={variant}>
              {value.map((item, index) => (
                <PreviewBlockStyled
                  onMouseEnter={() => setHoverItem(index)}
                  onMouseLeave={() => setHoverItem(null)}
                >
                  <PreviewImageStyled
                    isMultiple={isMulti}
                    src={item}
                    alt="Uploaded Image"
                    isHover={hoverItem === index}
                  />

                {hoverItem === index && (
                  <PreviewDeleteItemStyled
                    src={exit}
                    isMultiple={isMulti}
                    isHover={hoverItem === index}
                    onClick={() => handleDeleteItem(index, onChange, value)}
                  />
                )}
                </PreviewBlockStyled>
              ))}
            </PreviewContainerStyled>
          )}

          <ErrorTextStyled>{!!error ? error.message : ' '}</ErrorTextStyled>
        </ContainerStyled>
      )}
    />
  );
};

export default PhotoInput;