import * as yup from 'yup';

export const schema = yup.object().shape({
  namecheap_id: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .required('Поле обязательное!'),
  cloudflare_id: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .required('Поле обязательное!'),
  name: yup
    .string()
    .required('Поле обязательное!'),
});