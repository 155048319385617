import { useEffect, useState } from "react";
import {
  BlockButtonsStyled,
  BlockIntutsStyled,
  ButtonBotStyled,
  ButtonContainerStyled,
  ButtonSecondContainerStyled,
  ContainerInputStyled,
  FindStyled,
  FormStyled,
  InputStuled,
  LabelStyled,
} from "./styled";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooksStore";
import { appListModel } from "../../../../models/appList";
import { setPWAArciveFilterList, setPWAFilterList } from "../../../../store/PWAList/PWAListSlice";
import { DatePickerInput } from "../../../../components/Inputs/DatePickerInput/DatePikerInput";
import { MultiSelectInput } from "../../../../components/Inputs/MultiSelectInput/MultiSelectInput";

const FindPWA = () => {
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [productTegs, setProductTegs] = useState<
    { id: number; name: string }[]
  >([]);
  const [productCreate, setProductCreate] = useState("");

  const dispatch = useAppDispatch();

  const { appsList, appsListArchive } = useAppSelector((state) => state.pwalist);
  const { isArchive } = useAppSelector(state => state.core);
  const { category_idAll = [], domainsAll = [], tagsAll = [], pushSets = [] } =
    useAppSelector((state) => state.itemsSelect) || {};

  useEffect(() => {
    if (isArchive) {
      if (appsListArchive.length > 0) {
        const pwaItems = appsListArchive.map((item) =>
          appListModel(item, category_idAll, domainsAll, tagsAll, pushSets));

        const pwaFilterItems = pwaItems
          .filter((item) => item.id.toString().includes(productId.toLowerCase()))
          .filter((item) =>
            !!item.create ? item.create.includes(productCreate || "") : true
          )
          .filter((item) => {
            if (productTegs.length === 0) {
              return true;
            }
  
            let res = false;
  
            for (const teg of productTegs) {
              if (item.teg.split(", ").includes(teg.name)) {
                res = true;
              }
            }
  
            return res;
          })
          .filter((item) =>
            (item.name ? item.name : '').toLowerCase().includes(productName.toLowerCase())
          );
        dispatch(setPWAArciveFilterList(pwaFilterItems));
      }
    } else {
      if (appsList.length > 0) {
        const pwaItems = appsList.map((item) =>
          appListModel(item, category_idAll, domainsAll, tagsAll, pushSets));

        const pwaFilterItems = pwaItems
          .filter((item) => item.id.toString().includes(productId.toLowerCase()))
          .filter((item) =>
            !!item.create ? item.create.includes(productCreate || "") : true
          )
          .filter((item) => {
            if (productTegs.length === 0) {
              return true;
            }
  
            let res = false;
  
            for (const teg of productTegs) {
              if (item.teg.split(", ").includes(teg.name)) {
                res = true;
              }
            }
  
            return res;
          })
          .filter((item) =>
            (item.name ? item.name : '').toLowerCase().includes(productName.toLowerCase())
          );
        dispatch(setPWAFilterList(pwaFilterItems));
      }
    }
  }, [productId, productName, productCreate, productTegs, isArchive]);

  // const handleReset = () => {
  //   setProductId("");
  //   setProductName("");
  //   dispatch(setPWAFilterList(pwaItems));
  // };

  return (
    <FindStyled>
      <FormStyled>
        <BlockIntutsStyled>
          <ContainerInputStyled>
            <LabelStyled htmlFor="inputId">ID</LabelStyled>
            <InputStuled
              type="text"
              id="inputId"
              value={productId}
              onChange={(e) => setProductId(e.target.value)}
              placeholder="Product ID"
            />
          </ContainerInputStyled>

          <ContainerInputStyled>
            <LabelStyled htmlFor="inputName">Название</LabelStyled>
            <InputStuled
              type="text"
              id="inputName"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              placeholder="Product Name"
            />
          </ContainerInputStyled>

          <ContainerInputStyled>
            <DatePickerInput
              name="CREATE"
              label="Создано"
              value={productCreate}
              onChange={setProductCreate}
              placeholder="Product Create"
              variant="second"
            />
          </ContainerInputStyled>

          <ContainerInputStyled>
            <MultiSelectInput
              label="Теги"
              items={tagsAll}
              placehoder="Product Tags"
              value={productTegs}
              onChange={setProductTegs}
              variant="second"
              maxWidth="calc(100vw - 1340.1px)"
            />
          </ContainerInputStyled>
        </BlockIntutsStyled>
      </FormStyled>
    </FindStyled>
  );
};

export default FindPWA;
