import styled from 'styled-components';

const EditDashStyled = styled.div`
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #ECECEC;
  padding: 0 0 0 23px;
`;

const EditBoardStyled = styled.div`
  max-height: 649px;
  overflow-y: auto;
`;

export { 
  EditDashStyled,
  EditBoardStyled,
};