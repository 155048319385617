import styled from "styled-components";

const ButtonsBlockStyled = styled.div`
  position: relative;
  margin-top: 40px;
  display: flex;
  gap: 8px;
  z-index: 1;
`;

export {
  ButtonsBlockStyled,
};
