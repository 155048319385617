import { Control, Controller, FieldError, FieldValues, Path } from "react-hook-form";
import {
  ContainerStyled,
  ErrorTextStyled,
  ImageAddStyled,
  ImgRightStyled,
  InputBlockStyled,
  LabelStyled,
  SelectInputStyled,
  SelectStyled,
} from "./styled";
import { useEffect, useRef, useState } from "react";
import OptionsSelect from "../../OptionsSelect/OptionsSelect";
import select from "../../../images/select.png";
import addImg from "../../../images/miniIcon/add.png";
import HelperText from "../../../pages/_shared/HelperText/HelperText";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

type Props2<T extends FieldValues> = {
  label: string;
  items: string[] | { id: number | string; name: string }[];
  placeholder?: string;
  isAdding?: boolean;
  setCreate?: (onChange: (...event: any[]) => void) => void;
  helperText?: JSX.Element | undefined;
  width?: string;
  isAutocomplete?: boolean;
  onChange: (...event: any[]) => void;
  value: string | {
    id: number;
    name: string;
  };
  error?: FieldError | undefined
};

export const SelectInput = <T extends FieldValues>({
  label,
  items,
  isAdding = false,
  setCreate = () => {},
  helperText = undefined,
  width = "253px",
  placeholder = "Выберите значение",
  isAutocomplete = false,
  onChange,
  value,
  error = undefined,
}: Props2<T>) => {
  const [autoComplete, setAutoComplete] = useState('');
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const selectRef = useRef(null);

  const styledOptions = {
    left: "0",
    top: !!label ? "84px" : "57px",
    width: width,
  };

  const valueText = (value: string | { id: number; name: string }) => {
    if (typeof value === "string") {
      return value !== "" ? value : placeholder;
    }

    

    return value.name;
  };

  const ifDefauldValueUsed = (value: string | { id: number; name: string }) => {
    if (autoComplete) {
      return false;
    };

    if (typeof value === "string") {
      return value === placeholder || value === '';
    }

    

    return value.name === placeholder || value.name === '';
  };

  useOutsideClick(selectRef, () => {
    if (isOpenOptions) {
      setIsOpenOptions(false);
    }
  });

  const useValueAutoComplete = (value: string | { id: number; name: string }) => {
    if (autoComplete || isOpenOptions) {
      return autoComplete;
    } else if (typeof value === "string") {
      return value !== "" ? value : '';
    } else {
      
      return value.name;
    }
  };

  const filterItems = Array.isArray(items) && typeof items[0] === 'string'
  ? (items as string[]).filter((item) => item.toLocaleLowerCase().includes(autoComplete.toLocaleLowerCase()))
  : (items as { id: number | string; name: string }[]).filter((item) => item.name.toLocaleLowerCase().includes(autoComplete.toLocaleLowerCase()));

  useEffect(() => {
    if (!isOpenOptions) {
      setAutoComplete('');
    };
  }, [isOpenOptions]);

  const handleClickChange = (
    item: string | {
      id: number;
      name: string;
  }) => {
    onChange(item);
    setIsOpenOptions(false);
  }

  return (
    <InputBlockStyled>
      <ContainerStyled
        ref={selectRef}
      >
        {!!label && <LabelStyled isHelperText={!!helperText} onClick={() => setIsOpenOptions((prewValue) => !prewValue)}>
          {!!helperText && <HelperText text={helperText}/>}

          {label}
        </LabelStyled>}

        {isAutocomplete ? (
          <SelectInputStyled
            isDefault={ifDefauldValueUsed(value)}
            isOpened={isOpenOptions}
            width={width}
            onClick={() => setIsOpenOptions((prewValue) => !prewValue)}
            value={useValueAutoComplete(value)}
            onChange={e => setAutoComplete(e.target.value)}
            placeholder={placeholder}
          />
        ) : (
          <SelectStyled
            isDefault={ifDefauldValueUsed(value)}
            isOpened={isOpenOptions}
            width={width}
            onClick={() => setIsOpenOptions((prewValue) => !prewValue)}
          >
            {valueText(value)}
          </SelectStyled>
        )}
        
        <ImgRightStyled src={select} alt="swipe" isLabel={!!label} onClick={() => setIsOpenOptions((prewValue) => !prewValue)}/>

        <ErrorTextStyled>{!!error ? error.message : ' '}</ErrorTextStyled>

        <OptionsSelect
          items={filterItems}
          isActive={isOpenOptions}
          css={styledOptions}
          isClick={handleClickChange}
          activeItem={value}
        />

        {isAdding && (
          <ImageAddStyled
            wid={15}
            hei={15}
            src={addImg}
            onClick={() => setCreate(onChange)}
          />
        )}
      </ContainerStyled>
    </InputBlockStyled>
  );
};

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  items: string[] | { id: number | string; name: string }[];
  placeholder?: string;
  isAdding?: boolean;
  setCreate?: (onChange: (...event: any[]) => void) => void;
  helperText?: JSX.Element | undefined;
  width?: string;
  isAutocomplete?: boolean;
};

export const SelectControlInput = <T extends FieldValues>({
  control,
  name,
  label,
  items,
  isAdding = false,
  setCreate = () => {},
  helperText = undefined,
  width = "253px",
  placeholder = "Выберите значение",
  isAutocomplete = false,
}: Props<T>) => {

  const useValue =
    typeof items[0] === "string"
      ? ''
      : { id: -1, name: '' };

  return (
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value = useValue },
          fieldState: { error },
        }) => (<SelectInput
          label={label}
          items={items}
          placeholder={placeholder}
          isAdding={isAdding}
          setCreate={setCreate}
          helperText={helperText}
          width={width}
          isAutocomplete={isAutocomplete}
          onChange={onChange}
          value={value}
          error={error}
          />)}
      />
  );
};
