import { Control } from "react-hook-form";
import Accordion from "../../../../../../components/Accordion/Accordion";
import {
  BlockInputsStyled,
  ContentInputsStyled,
  ItemInputStyled
} from "./styled";
import TextAreaInput from "../../../../../../components/Inputs/TextAreaInput/TextAreaInput";
import {DatePickerControlInput} from "../../../../../../components/Inputs/DatePickerInput/DatePikerInput";
import { DescriptionsEditType } from "../../../../../../types/EditsTypes";

type Props = {
  control: Control<DescriptionsEditType, any>;
}

const WhoNewAcordion: React.FC<Props> = ({ control }) => {
  return (
    <Accordion title="Что нового">
      <ContentInputsStyled>
        <BlockInputsStyled>
          <ItemInputStyled>
            <DatePickerControlInput
              control={control}
              placeholder="Введите значение"
              label="Последнее обновление"
              name='update_date'
            />
          </ItemInputStyled>
        </BlockInputsStyled>

        <BlockInputsStyled>
          <TextAreaInput control={control} name='update_description' label="Что нового" variantWidth='small'/>
        </BlockInputsStyled>
      </ContentInputsStyled>
    </Accordion>
  )
};

export default WhoNewAcordion;