import styled from "styled-components";

const IntegrationStyled = styled.div`
  display: flex;
  gap: 35px;
  margin-block: 30px;
`;

const BlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export {
  IntegrationStyled,
  BlockStyled,
};