import styled, { css } from 'styled-components';
import { ImgStyled } from '../../../helperStyled/styledHelper';

type InputBlockProps = {
  width: string;
};

const InputBlockStyled = styled.div<InputBlockProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 2px;
  width: ${({width}) => width};
`;

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

type LabelProps = {
  isHelperText: boolean;
  variant: 'first' | 'second';
};

const LabelStyled = styled.label<LabelProps>`
  font-weight: 400;
  line-height: 16px;

  ${({isHelperText}) => isHelperText ? css`
    padding: 4px 0 7px 0;
  ` : css`
    padding: 4px 0 7px 16px;
  `}

  ${({variant}) => {
    switch (variant) {
      case 'first': return css`
        display: flex;
        white-space: nowrap;
        font-size: 15px;
        width: 210px;
        padding: 4px 0 7px 16px;
        min-height: 27px;
        gap: 10px;
      `;

      case 'second': return css`
        position: absolute;
        background-color: #fff;
        padding-inline: 4px;
        font-size: 16px;
        left: 12px;
        top: -8px;
        z-index: 100;
      `;
    }
  }}
`;

type SelectType = {
  isDefault: boolean;
  isOpened: boolean;
  variant: 'first' | 'second';
};

const SelectStyled = styled.input<SelectType>`
  cursor: pointer;
  font-weight: 400;
  line-height: 24px;
  border: ${({ isOpened }) => isOpened ? '2px solid #4091F7' : '1px solid #868687'};
  padding: ${({ isOpened }) => isOpened ? '11px 0 11px 12px' : '12px 0 12px 13px'};
  border-radius: 10px;
  color: ${({ isDefault }) => isDefault ? '#868687' : '#323232'};
  position: relative;
  background-color: white;
  outline: none;

  ${({variant}) => {
    switch (variant) {
      case 'first': return css`
        width: 253px;
        height: 52px;
        font-size: 16px;
        padding: 0 0 0 12px;
        transition: border-color 1s;

        &:focus {
          border: 2px solid #4091F7;
          padding: 0 0 0 11px;
        }
      `;

      case 'second': return css`
          width: 240px;
          height: 56px;
          font-size: 20px;
          padding-left: 16px;
          transition: 0.3s;

          &:focus {
            padding-left: 15px;
            border: 3px solid #4091f7;
          }
      `;
    }
  }}
`;

type ItemValueStyled = {
  isLabel: boolean;
  maxWidth: string;
  variant: 'first' | 'second';
};


const ItemsValueStyled = styled.div<ItemValueStyled>`
  display: flex;
  position: absolute;
  gap: 10px;
  max-width: ${({maxWidth}) => maxWidth};
  overflow-x: auto;
  z-index: 10;
  ${({variant, isLabel}) => {
    switch (variant) {
      case 'first': return css`
        top: ${isLabel ? '35.29px' : '7.29px'};
        padding-bottom: 10px;
        left: 300px;
      `;

      case 'second': return css`
        top: 11.29px;
        left: 250px;
        padding-bottom: 5px;
      `;
    }
  }}
`;

const ValueStyled = styled.div`
  background-color: #4091F7;
  border-radius: 10px;
  color: white;
  padding: 8px;
  white-space: nowrap;
  display: flex;
  gap: 5px;
  align-items: center;
`;

const DeleteItemStyled = styled(ImgStyled)`
  cursor: pointer;
  filter: brightness(0) invert(1);
`;

type ImgRightType = {
  isLabel: boolean;
  variant: 'first' | 'second';
};

const ImgRightStyled = styled.img<ImgRightType>`
  position: absolute;
  width: 12px;
  height: 7.41px;
  right: 18px;
  cursor: pointer;
  ${({variant, isLabel}) => {
    switch (variant) {
      case 'first': return css`
        top: ${isLabel ? '49.29px' : '22.29px'};
      `;

      case 'second': return css`
        top: 23.29px;
      `;
    }
  }}
`;

const ErrorTextStyled = styled.div`
  height: 16px;
  padding: 4px 0 0 10px;
  font-size: 10px;
  color: red;
`;

const ImageAddStyled = styled(ImgStyled)`
  padding: 10px;
  top: 35px;
  box-sizing: content-box;
  position: absolute;
  right: -40px;
  cursor: pointer;
`;

export {
  InputBlockStyled,
  ContainerStyled,
  LabelStyled,
  SelectStyled,
  ImgRightStyled,
  ErrorTextStyled,
  ImageAddStyled,
  ItemsValueStyled,
  ValueStyled,
  DeleteItemStyled,
};