import styled from 'styled-components';

const ContainerStyled = styled.div`
  padding: 0 25px 63px 26px;
`;

const ListStyled = styled.div`
  height: 434px;
  border-radius: 12px 0px 0px 12px;
  width: calc(100vw - 366.1px);
  overflow: auto;
  position: relative;
`;

const ListNamesStyled = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;

  & > :not(:first-child) {
    position: relative;
    padding-left: 10px;

    &::before {
      content: '';
      position: absolute;
      height: 18px;
      width: 1px;
      background-color: #4091F7;
      left: 0;
    }
  }
`;

const RuporImgStyled = styled.img`
  width: 10.47px;
  height: 9.5px;
  margin: 0 0 0 40px;
`;

type ListNameItemProps = {
  css: any;
}

const ListNameItemStyled = styled.p<ListNameItemProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-block: 0;
  white-space: nowrap;
  flex-shrink: 0;
  padding-block: 21px;
  background-color: #F9F9F9;
  height: 62px;

  ${({ css }) => css}
`;

const ListRowContainerStyled = styled.div`
  /* height: 372px;
  overflow: auto; */
`;

const ListRowStyled = styled.div`
  display: flex;

  & > :not(:first-child) {
    padding-left: 10px;
  }
`;

type ListRowItemProps = {
  css: any;
}

const ListRowItemStyled = styled.p<ListRowItemProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  flex-shrink: 0;
  padding-block: 21px;
  border-bottom: 2px solid #F9F9F9;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;

  ${({ css }) => css}
`;

const LoadingStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
`;

export {
  ContainerStyled,
  ListStyled,
  ListNamesStyled,
  ListNameItemStyled,
  RuporImgStyled,
  ListRowContainerStyled,
  ListRowStyled,
  ListRowItemStyled,
  LoadingStyled,
}