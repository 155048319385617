import styled from 'styled-components';
import { ImgStyled } from '../../../../../helperStyled/styledHelper';

const ContainerCommentStyled = styled.div`
  padding-right: 20px;
`;

const BlockStyled = styled.div`
  padding: 36px 23px 36px 25px;
  border: 1px solid #ECECEC;
  border-radius: 10px;
  display: flex;
  gap: 100px;
`;

const FirstBlockStyled = styled.div`
  display: flex;
  gap: 20px;
`;

const AvatarStyled = styled(ImgStyled)`
  border-radius: 100px;
`;

const NameAndDateStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CommentTextStyled = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 60px;
`;

export {
  ContainerCommentStyled,
  BlockStyled,
  FirstBlockStyled,
  AvatarStyled,
  NameAndDateStyled,
  CommentTextStyled,
};