import { ButtonStyled } from "../../../../helperStyled/styledHelper";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooksStore";
import { setPopUp } from "../../../../store/core/core";
import { PopUpKeys } from "../../../../types/TypeCore";
import ListAdmin from "../ListAdmin/ListAdmin";
import { BlockStyled, IntegrationStyled } from "./styled";

const listNames = [
  {
    id: 1,
    name: 'domen',
    title: 'Домен',
    style: {
      width: '250px',
      paddingLeft: '36px',
    },
  },
  {
    id: 2,
    name: 'username',
    title: 'Username',
    style: {
      width: '250px',
      paddingLeft: '36px',
    },
  },
  {
    id: 3,
    name: 'dateEndDomen',
    title: 'Дата Окончание домена',
    style: {
      width: '250px',
      paddingLeft: '36px',
    },
  },
  {
    id: 4,
    name: 'status',
    title: 'Статус',
    style: {
      width: '250px',
      paddingLeft: '36px',
    },
  },
];

const Domens = () => {
  const dispatch = useAppDispatch();
  const {domainsAll} = useAppSelector(state => state.itemsSelect);
  const domainItems = domainsAll.map(item => {
    return {
      domen: item.name,
      username: 'none',
      dateEndDomen: 'none',
      status: 'none',
    }
  });

  return (
    <IntegrationStyled>
      <BlockStyled>
        <ListAdmin listNames={listNames} items={domainItems}/>

        <div>
          <ButtonStyled variant='blue' onClick={() => dispatch(setPopUp({ popUpName: PopUpKeys.BUYDOMEN }))}>Купить Домен</ButtonStyled>
        </div>
      </BlockStyled>
    </IntegrationStyled>
  );
};

export default Domens;