import * as yup from 'yup';

type push_weekdaysType = {
  monday: boolean,
  tuesday: boolean,
  wednesday: boolean,
  thursday: boolean,
  friday: boolean,
  saturday: boolean,
  sunday: boolean,
  sending_time: string,
  repeat: boolean,
}

const validateAtLeastOneTrue = (value: push_weekdaysType) => {
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = value;
  return [monday, tuesday, wednesday, thursday, friday, saturday, sunday].some(val => val === true);
};

export const schema = yup.object().shape({
  name: yup.string().required('Введите название!'),
  is_active: yup.boolean().required(),
  category_id: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }).test('not-default-value', 'Выберите значение!', value => {
    return value.id !== -1;
  }),
  time_after_event: yup.string().required('Введите значение!'),
  event_type: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }).test('not-default-value', 'Выберите значение!', value => {
    return value.id !== -1;
  }),
  push_set_id: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }).test('not-default-value', 'Выберите значение!', value => {
    return value.id !== -1;
  }),
  time_after_event_name: yup.string().oneOf(['Минуты', 'Часы', 'Дни', '']).required('Поле обязательное!'),
  push_languages: yup.array().of(
    yup.object().shape({
      language: yup.string().required(),
      title: yup.string().required('Введите текст!'),
      text: yup.string().required('Введите текст!'),
      image: yup.array().of(yup.string().required()).min(1, 'Фото обазятельное!').required(),
      is_default: yup.boolean().required(),
    })
  ).min(1, 'Минимум одно значение!')
  .required('Поле обязательное!'),
  push_weekdays: yup.array().of(
    yup.object().shape({
      monday: yup.boolean().required(),
      tuesday: yup.boolean().required(),
      wednesday: yup.boolean().required(),
      thursday: yup.boolean().required(),
      friday: yup.boolean().required(),
      saturday: yup.boolean().required(),
      sunday: yup.boolean().required(),
      sending_time: yup.string().required("Введите время!").matches(/^[0-2][0-9]:[0-5][0-9]$/, "Введите время!"),
      repeat: yup.boolean().required(),
    }).test(
      'at-least-one-true',
      'Должен быть выбран хотя бы один день во всех полях!',
      (value) => {
        return validateAtLeastOneTrue(value as push_weekdaysType);
      }
    )
  ).min(1, 'Минимум одно значение!')
  .required('Поле обязательное!'),
  push_countries: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(),
    })
  ).min(1, 'Минимум одно значение!')
  .required('Поле обязательное!'),
  push_filters: yup.array().of(
    yup.object().shape({
      logic_operator: yup.string().required(),
      filtered_column: yup.string().required('Выберите значение!'),
      statement: yup.string().required('Выберите значение!'),
      value: yup.string().required('Выберите значение!'),
    })
  ).min(1, 'Минимум одно значение!')
  .required('Поле обязательное!'),
});