import styled from "styled-components";

const CreateDeveloperStyled = styled.form`
  position: absolute;
  width: 100vw;
  height: calc(100vh - 64px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlockStyled = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #ECECEC;
  padding: 29px 77px 22px 33px;
`;

const BlockInputsStyled = styled.div`
  display: flex;
  gap: 99px;
`;

const RatingBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 2px;
`;

const RatingLabelStyled = styled.label`
  white-space: nowrap;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  width: 210px;
  padding: 4px 0 7px 16px;
`;

const RatingCalcStyled = styled.div`
  width: 86px;
  height: 52px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #868687;
  border-radius: 10px;
  transition: border-color 1s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`;

const ButtonsBlockStyled = styled.div`
  display: flex;
  gap: 8px;
`;

const ErrorItemStyled = styled.div`
  color: red;
`;

export {
  CreateDeveloperStyled,
  BlockStyled,
  BlockInputsStyled,
  RatingBlockStyled,
  RatingLabelStyled,
  RatingCalcStyled,
  ButtonsBlockStyled,
  ErrorItemStyled,
};