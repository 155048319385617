import axios from "axios";
import store from "../store/store";
import { setIsToken } from "../store/auth/authSlice";

const api = axios.create({
  baseURL: `https://backend.test-paw.online`,
})

api.interceptors.request.use((config) => {
  const { auth } = store.getState();

  if (auth.authToken) config.headers.Authorization = `Bearer ${auth.authToken}`;
  return config;
});

api.interceptors.response.use(
  (res) => res,
  (err) => {
    const { status } = err.response;

    if (status === 401) {
      store.dispatch(setIsToken(''));
    }

    if (status === 423) {
      window.location.href = 'auth/login';
    }

    return Promise.reject(err);
  }
);

export default api;