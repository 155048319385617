import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type UserTypeSting = 'admin' | 'buyer' | 'qa' | 'teamlead' | ''

type Auth = {
  authToken: string;
  userType: UserTypeSting;
}

const initialState: Auth = { authToken: '', userType: '' };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsToken: ((state: Auth, action: PayloadAction<string>) => {
      state.authToken = action.payload;
    }),
    setUserType: ((state: Auth, action: PayloadAction<UserTypeSting>) => {
      state.userType = action.payload;
    }),
  },
});

export const { setIsToken, setUserType } = authSlice.actions;
export default authSlice.reducer;