import api from "./axios";
import { apiRoutes } from "../utils/apiRoutes";
import { CommentPWAType } from "../types/TypePWA";

const apiGet = async (url: string) => {
  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const apiPost = async (url: string, data: any) => {
  try {
    const response = await api.post(url, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const apiDelete = async (url: string) => {
  try {
    const response = await api.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createNewUser = (data: any) => apiPost(apiRoutes.adminUserCreate, data);
export const getToken = (data: any) => apiPost(apiRoutes.token, data);
export const getApps = () => apiGet(apiRoutes.pwaList);
export const getAppItem = (id: string) => apiGet(apiRoutes.pwaItem(id));
export const getCategories = () => apiGet(apiRoutes.category_id);
export const getTrackers = () => apiGet(apiRoutes.trackers);
export const getDevelopers = () => apiGet(apiRoutes.developers);
export const getDomains = () => apiGet(apiRoutes.domains);
export const getRatings = () => apiGet(apiRoutes.ratings);
export const getFacebookPixels = () => apiGet(apiRoutes.facebookPixels);
export const getTikTokPixels = () => apiGet(apiRoutes.tiktokPixels);
export const getPushSets = () => apiGet(apiRoutes.pushSets);
export const getPushs = () => apiGet(apiRoutes.pushs);
export const getNamecheaps = () => apiGet(apiRoutes.namecheap);
export const getCloudflares = () => apiGet(apiRoutes.cloudflare);
export const getAdminUsers = () => apiGet(apiRoutes.adminUser);
export const getTags = () => apiGet(apiRoutes.tags);
export const getStoreCategories = () => apiGet(apiRoutes.storeCategories);
export const getCloackSetting = (id: number) => apiGet(`${apiRoutes.cloackSetting}${id}`);
export const getReview = (id: number): Promise<CommentPWAType | undefined> => apiGet(apiRoutes.reviewItem(id));
export const getPushItem = (id: number) => apiGet(apiRoutes.pushItem(id));

export const sendCloackSetting = (data: any) => apiPost(apiRoutes.cloackSettingCreate, data);
export const sendAppPWA = (data: any) => apiPost(apiRoutes.pwaCreate, data);
export const sendAppDeveloper = (data: any) => apiPost(apiRoutes.developerCreate, data);
export const sendAppRating = (data: any) => apiPost(apiRoutes.ratingCreate, data);
export const sendAppReview = (data: any) => apiPost(apiRoutes.reviewsCreate, data);
export const sendFacebookPixels = (data: any) => apiPost(apiRoutes.facebookPixelCreate, data);
export const sendTikTokPixels = (data: any) => apiPost(apiRoutes.tiktokPixelCreate, data);
export const sendPush = (data: any) => apiPost(apiRoutes.pushCreate, data);
export const sendNamecheap = (data: any) => apiPost(apiRoutes.namecheapCreate, data);
export const sendCloudflare = (data: any) => apiPost(apiRoutes.cloudflareCreate, data);
export const sendDomain = (data: any) => apiPost(apiRoutes.domainCreate, data);
export const sendPushSet = (data: any) => apiPost(apiRoutes.pushSetCreate, data);
export const sendTag = (data: any) => apiPost(apiRoutes.tagCreate, data);

export const updateCloackSettings = (data: any, id: number) => apiPost(apiRoutes.cloackSettingUpdate(id), data);
export const updateAppPWA = (data: any, id: string) => apiPost(apiRoutes.pwaUpdate(id), data);
export const updateAppReview = (data: any, id: number) => apiPost(apiRoutes.reviewsUpdate(id), data);
export const updatePush = (data: any, id: number) => apiPost(apiRoutes.pushUpdate(id), data);
export const updateAdminUser = (data: any, id: number) => apiPost(apiRoutes.adminUserUpdate(id), data);

export const deleteAppReview = (id: number) => apiDelete(apiRoutes.reviewsDelete(id));
export const deletePush = (id: number) => apiDelete(apiRoutes.pushDelete(id));
export const deletePwa = (id: string) => apiDelete(apiRoutes.pwaDelete(id));
