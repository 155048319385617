import styled, { css } from "styled-components";

const EditNavStyled = styled.div`
  display: flex;
  gap: 30px;

  border-bottom: 1px solid #dae3ec;
  position: relative;
`;

type NavItemProps = {
  isActive: boolean;
};

const NavItemStyled = styled.p<NavItemProps>`
  padding-block: 32px 12px;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      color: #4091f7;
      position: relative;
      font-weight: 700;
    `}
`;

type AfterProps = {
  selectedTab: string;
};

type NavEditStyle = {
  width: string;
  translate: string;
};

type NavEditStyles = {
  [key: string]: NavEditStyle;
};

const navEditStyles: NavEditStyles = {
  integrations: {
    width: "88.78px",
    translate: "0",
  },
  domens: {
    width: "64.13px",
    translate: "118.78px",
  },
  users: {
    width: "107.51px",
    translate: "212.91px",
  },
};

const AfterStyled = styled.div<AfterProps>`
  transform-origin: left;
  transition: all 0.5s;
  border: 1px solid #4091f7;
  position: absolute;
  left: 0;
  bottom: 0;

  ${({ selectedTab }) => {
    const selectedStyle = navEditStyles[selectedTab] || {
      width: "75px",
      translate: "0",
    };

    return css`
      width: ${selectedStyle.width};
      transform: translateX(${selectedStyle.translate});
    `;
  }}
`;

export { EditNavStyled, NavItemStyled, AfterStyled };
