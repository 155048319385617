import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./hooksStore";
import { getApps } from "../api/requestService";
import { setPWAArciveFilterList, setPWAArciveList, setPWAFilterList, setPWAList } from "../store/PWAList/PWAListSlice";
import { appListModel } from "../models/appList";
import { toast } from "react-toastify";

export const usePWAList = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { category_idAll = [], domainsAll = [], tagsAll = [], pushSets = [] } = useAppSelector((state) => state.itemsSelect) || {};
  const [forceUpdateCounter, setForceUpdateCounter] = useState(0);

  useEffect(() => {
    const fetchPWA = async () => {
      setIsLoading(true);
      setIsError(false);

      try {
        await getApps().then((pwas) => {
          const activePwas = pwas.filter((pwa: any) => !pwa.is_archived);
          const archivePwas = pwas.filter((pwa: any) => pwa.is_archived);

          dispatch(setPWAArciveList(archivePwas))
          dispatch(setPWAList(activePwas));

          const activePWAsModel = activePwas
            ? activePwas.map((item: any) => appListModel(item, category_idAll, domainsAll, tagsAll, pushSets))
            : [];

          const archivePWAsModel = archivePwas
            ? archivePwas.map((item: any) => appListModel(item, category_idAll, domainsAll, tagsAll, pushSets))
            : [];

          dispatch(setPWAFilterList(activePWAsModel));
          dispatch(setPWAArciveFilterList(archivePWAsModel));
        });
      } catch (error) {
        console.error("Failed to fetch PWAs:", error);
        toast.error('Не удалось получить PWA!')
        setIsError(true);
      }

      setIsLoading(false);
    };
    fetchPWA();
  }, [category_idAll, forceUpdateCounter]);

  const forceUpdate = () => {
    dispatch(setPWAList([]));
    dispatch(setPWAArciveList([]));
    dispatch(setPWAFilterList([]));
    setForceUpdateCounter((prevCounter) => prevCounter + 1);
  };

  return {isLoading, isError, forceUpdate};
};