import styled from 'styled-components';

const TablesPWAStyled = styled.div`
  background-color: #FFFFFF;
  width: 100%;
  min-height: calc(100vh - 320px);
  max-height: auto;
  border-radius: 12px;
  border: 1px solid #ECECEC;

  font-size: 16px;
  line-height: 20px;
`;

export {
  TablesPWAStyled,
}