import styled from "styled-components";

const ContentInputsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;

  margin-top: 20px;
  padding-bottom: 20px;
`;

const BlockInputsStyled = styled.div`
  display: flex;
  gap: 52px;
`;

export {
  ContentInputsStyled,
  BlockInputsStyled,
};
