import { LabelStyled, ListItemsWidthStyled, ListNameItemStyled, ListNamesStyled, ListRowItemStyled, ListRowStyled, ListStyled } from "./styled";

type Nam = {
  id: number;
  name: any;
  title: string;
  style: {
    width: string;
    paddingLeft?: string;
    paddingRight?: string;
    padding?: string;
  };
  render?: (data: any) => {};
};

type Props = {
  label?: string;
  listNames: Nam[];
  items: any[];
};

const ListAdmin: React.FC<Props> = ({
  label = '',
  listNames,
  items,
}) => {
  return (
    <ListStyled>
      {!!label && <LabelStyled>{label}</LabelStyled>}

      <ListNamesStyled>
        {listNames.map((nam) => (
          <ListNameItemStyled css={nam.style} key={nam.id}>
            {nam.title}
          </ListNameItemStyled>
        ))}
      </ListNamesStyled>

      <ListItemsWidthStyled>
        {!!items.length && items.map((item) => (
          <ListRowStyled key={item.id}>
            {listNames.map((nam) => (
              <ListRowItemStyled css={nam.style} key={nam.id}>
                {nam.render ? nam.render(item) : item[nam.name]}
              </ListRowItemStyled>
            ))}
          </ListRowStyled>
        ))}
      </ListItemsWidthStyled>
    </ListStyled>
  )
};

export default ListAdmin;