import { useMemo } from "react";
import { Control, FieldError, FieldValues, Path, PathValue } from "react-hook-form";
import { useController } from "react-hook-form";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { ru } from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";

import { MemoizedDatePickerContainer } from "./DatePickerContainer/DatePickerContainer";
import { MemoizedDatePickerHeader } from "./DatePickerHeader/DatePickerHeader";
import { FieldInput, Wrap, FieldWrap, ErrorTextStyled, LabelStatic } from "./styled";

type Props<T extends FieldValues> = {
  name: Path<T>;
  label: string;
  placeholder: string;
  value: PathValue<T, Path<T>>;
  onChange: (...event: any[]) => void;
  error?: FieldError | undefined;
  invalid?: boolean;
  variant?: 'first' | 'second';
};

export const DatePickerInput = <T extends FieldValues>({
  placeholder,
  label,
  value,
  onChange,
  error = undefined,
  invalid = false,
  variant = 'first',
}: Props<T>) => {

  const isFilled = useMemo(() => {
    return !!value;
  }, [value]);

  const onChangeDate = (date: any) => {
    onChange(date ? format(date, "yyyy-MM-dd") : null);
  };

  const renderCustomHeader = (props: any) => (
    <MemoizedDatePickerHeader {...props} value={value} />
  );

  const calendarContainer = (props: any) => {
    return <MemoizedDatePickerContainer {...{ ...props }} value={value} />;
  };

  const formatWeekDay = (nameOfDay: any) =>
    nameOfDay.substring(0, 3).toUpperCase();

  return (
    <Wrap>
      {label && <LabelStatic error={invalid} variant={variant}>{label}</LabelStatic>}
      <FieldWrap>
        <FieldInput error={invalid} activated={isFilled} variant={variant}>
          <DatePicker
            placeholderText={placeholder}
            timeCaption="Select time"
            autoComplete="off"
            className="field-input"
            selected={value}
            onChange={onChangeDate}
            renderCustomHeader={renderCustomHeader}
            calendarContainer={calendarContainer}
            formatWeekDay={formatWeekDay}
            disabled={false}
            dateFormat={"yyyy-MM-dd"}
            locale={ru}
          />
        </FieldInput>
      </FieldWrap>
      <ErrorTextStyled>{error ? error.message : null}</ErrorTextStyled>
    </Wrap>
  );
};

type Props2<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  placeholder: string;
};


export const DatePickerControlInput = <T extends FieldValues>({
  control,
  placeholder,
  label,
  name,
}: Props2<T>) => {
  const {
    field: { value, onChange },
    fieldState: { error, invalid },
  } = useController({ name, control });

  return (
    <DatePickerInput
      value={value}
      onChange={onChange}
      error={error}
      invalid={invalid}
      placeholder={placeholder}
      label={label}
      name={name}/>
  );
};
