import logo from '../../images/Logo.png';
import dollar from '../../images/dollar.png'
import {
  BlockInfoStyled,
  BlockItemStyled,
  BlockStyled,
  HeaderStyled,
  CircleHeadStyled,
} from './styled';
import { ImgStyled } from '../../helperStyled/styledHelper';
import { setIsToken } from '../../store/auth/authSlice';
import { useAppDispatch } from '../../hooks/hooksStore';

const Header = () => {
  const dispatch = useAppDispatch();

  const handleRemoveToken = () => {
    dispatch(setIsToken(''))
  };

  return (
    <HeaderStyled>
      <ImgStyled wid={112.43} hei={30} alt='logo' src={logo}/>

      <BlockStyled>
        <BlockItemStyled>
          <CircleHeadStyled>
            <ImgStyled wid={10.18} hei={18} alt='money' src={dollar}/>
          </CircleHeadStyled>

          <BlockInfoStyled>80.41</BlockInfoStyled>
        </BlockItemStyled>

        <BlockItemStyled>
          <CircleHeadStyled />

          <BlockInfoStyled onClick={handleRemoveToken}>ID: 1345</BlockInfoStyled>
        </BlockItemStyled>
      </BlockStyled>
    </HeaderStyled>
  )
}

export default Header;