import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./App.scss";
import { useEffect } from "react";
import { useAppSelector } from "./hooks/hooksStore";
import { ToastContainer } from "react-toastify";

export function App() {
  const locate = useLocation();
  const { authToken } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      navigate("auth");
    } else if (locate.pathname === "/") {
      navigate("main/list");
    }
  }, [locate.pathname, authToken]);

  return (
    <div className="App">
      <Outlet />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        draggable
        pauseOnHover
      />
    </div>
  );
}
