import styled from 'styled-components';

const FormBasicStyled = styled.form`
  display: flex;
  flex-direction: column;
  padding-block: 30px 38px;
  gap: 14px;
`;

const InfoTextStyled = styled.div`
  width: 923px;
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  margin: 0;
`;

export {
  FormBasicStyled,
  InfoTextStyled,
};