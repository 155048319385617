import { SubmitHandler, useForm } from "react-hook-form";
import TextInput from "../../Inputs/TextInput/TextInput";
import { BlockInputsStyled, BlockStyled, ButtonsBlockStyled, CreateDeveloperStyled } from "./styled";
import { ButtonStyled } from "../../../helperStyled/styledHelper";
import { useAppDispatch } from "../../../hooks/hooksStore";
import { setPopUp } from "../../../store/core/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import { PopUpKeys } from "../../../types/TypeCore";
import { getNamecheaps, sendNamecheap } from "../../../api/requestService";
import { setNamechapList } from "../../../store/PWAList/PWAListSlice";
import { TitlePopUpStyled } from "../PopCreateBuyDomen/styled";
import { toast } from "react-toastify";
import ButtonItem from "../../ButtonItem/ButtonItem";

export type CreateNamechapType = {
  id: number;
  name: string;
  api_key: string;
  username: string;
  email: string;
  contact_phone: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_address1: string;
  contact_country: string;
  contact_organization: string,
  contact_city: string,
  contact_state_province: string,
  contact_postal_code: string,
};

const PopCreateNamechap = () => {
  const dispatch = useAppDispatch();
  const { handleSubmit, control } = useForm<Omit<CreateNamechapType, 'id'>>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Omit<CreateNamechapType, 'id'>> = async (data) => {
    try {
      await sendNamecheap(data);

      const namecheaps = await getNamecheaps();

      dispatch(setNamechapList(namecheaps));

      toast.success('Namecheap создан!');

      dispatch(setPopUp({popUpName: PopUpKeys.NONE}));
    } catch {
      console.log('error create');
      toast.error('Ошибка создания Namecheap!');
    }
  };

  return (
    <CreateDeveloperStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <TitlePopUpStyled>Создание Namecheap</TitlePopUpStyled>

        <BlockInputsStyled>
          <TextInput control={control} name='name' label="Name" isExit={true} />

          <TextInput control={control} name='contact_first_name' label="First Name" isExit={true} />

          <TextInput control={control} name='contact_organization' label="Organization" isExit={true} />
        </BlockInputsStyled>

        <BlockInputsStyled>
          <TextInput control={control} name='api_key' label="API key" isExit={true} />

          <TextInput control={control} name='contact_last_name' label="Last Name " isExit={true} />

          <TextInput control={control} name='contact_city' label="City " isExit={true} />
        </BlockInputsStyled>

        <BlockInputsStyled>
          <TextInput control={control} name='username' label="UserName" isExit={true} />

          <TextInput control={control} name='contact_address1' label="Contact Adress" isExit={true} />

          <TextInput control={control} name='contact_state_province' label="State Province" isExit={true} />
        </BlockInputsStyled>

        <BlockInputsStyled>
          <TextInput control={control} name='email' label="E-mail" isExit={true} />

          <TextInput control={control} name='contact_country' label="Contact Countries" isExit={true} />

          <TextInput control={control} name='contact_postal_code' label="Postal Code" isExit={true} />
        </BlockInputsStyled>

        <BlockInputsStyled>
          <TextInput control={control} name='contact_phone' label="Contact Phone" isExit={true} />
        </BlockInputsStyled>

        <ButtonsBlockStyled>
          <ButtonStyled
            variant="white"
            type="reset"
            onClick={() => dispatch(setPopUp({popUpName: PopUpKeys.NONE}))}
          >
            Отмена
          </ButtonStyled>

          <ButtonItem variant='blue' buttonText='Создать' funcClick={handleSubmit(onSubmit)}/>
        </ButtonsBlockStyled>
      </BlockStyled>
    </CreateDeveloperStyled>
  );
};

export default PopCreateNamechap;
