import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup
    .string()
    .required('Поле обязательное!'),
  api_key: yup
    .string()
    .required('Поле обязательное!'),
  username: yup
    .string()
    .required('Поле обязательное!'),
  email: yup
    .string()
    .email('Введите email!')
    .required('Поле обязательное!'),
  contact_phone: yup
    .string()
    .required('Поле обязательное!'),
  contact_first_name: yup
    .string()
    .required('Поле обязательное!'),
  contact_last_name: yup
    .string()
    .required('Поле обязательное!'),
  contact_address1: yup
    .string()
    .required('Поле обязательное!'),
  contact_country: yup
  .string()
  .required('Поле обязательное!'),
  contact_organization: yup
    .string()
    .required('Поле обязательное!'),
  contact_city: yup
    .string()
    .required('Поле обязательное!'),
  contact_state_province: yup
    .string()
    .required('Поле обязательное!'),
  contact_postal_code: yup
    .string()
    .required('Поле обязательное!'),
});
