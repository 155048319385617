import { PushSetType } from "../types/PushTupe";
import {
  AppsType,
  CategoriesType,
  DomainsType,
  TagsType,
} from "../types/TypePWA";

const FindCategoryFromId = (
  category_id: number,
  category_idAll: CategoriesType[]
) => {
  return category_idAll.find((item) => item.id === category_id)?.name;
};

const FindAllTagsFromId = (tagsId: number[], tagsAll: TagsType[]) => {
  return tagsAll
    .filter((item) => tagsId.includes(item.id))
    .map((item) => item.name);
};

export const appListModel = (
  item: AppsType,
  category_idAll: CategoriesType[],
  domainsAll: DomainsType[],
  tagsAll: TagsType[],
  pushSets: PushSetType[],
) => ({
  id: item.id,
  name: item.internal_name,
  category_id: FindCategoryFromId(item.category_id, category_idAll),
  domen: FindCategoryFromId(item.domain_id, domainsAll),
  push_set_id: FindCategoryFromId(item.push_set_id, pushSets),
  teg: FindAllTagsFromId(item.tags, tagsAll).join(", "),
  create: item.creation_date,
  tarif: "free",
  clicks: item.clicks,
  install: item.installs,
  uskl: Number(((item.installs / item.clicks) * 100).toFixed(1)) || 0,
  reg: item.registrations,
  dep: item.deposits,
  idToEdit: item.id,
  editPwa: '',
  deletePwa: '',
});
