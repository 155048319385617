import { useNavigate } from "react-router-dom";
import { ButtonStyled } from "../../helperStyled/styledHelper";
import { useAppDispatch, useAppSelector } from "../../hooks/hooksStore";
import { AuthFormStyled, AuthStyled, ButtonsStyled, InputsBlockStyled, LogoStyled } from "./styled";
import { useEffect, useState } from "react";
import { setIsToken, setUserType } from "../../store/auth/authSlice";
import { SubmitHandler, useForm } from "react-hook-form";
import logo from '../../images/LogoLogin.png';
import TextInput from "../../components/Inputs/TextInput/TextInput";
import { createNewUser, getToken } from "../../api/requestService";
import { AuthType } from "../../types/AuthType";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaLogin, schemaRegister } from "./shema";
import ButtonItem from "../../components/ButtonItem/ButtonItem";
import { toast } from "react-toastify";

const Auth = () => {
  const [isPageRegister, setIsPageRegister] = useState(false);
  const { authToken } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { handleSubmit, control } = useForm<AuthType>({
    resolver: yupResolver(isPageRegister ? schemaRegister : schemaLogin),
  });

  const onSubmit: SubmitHandler<AuthType> = async (data) => {
    if (isPageRegister) {
      try {
        const dataCreate = {
            "email": data.email,
            "name": data.name,
            "telegram": data.telegram,
            "password": data.password,
            "user_status": "buyer",
            "user_rights": "own_pwa",
            "team_id": 1,
        };

        const newUser = await createNewUser(dataCreate);

        dispatch(setUserType('admin'));
        dispatch(setIsToken(newUser.access_token));
      } catch {
        console.log('error register')
        toast.error('Ошибка Регистрации!')
      }
    } else {
      try {
        const formData = new FormData();
        formData.append('username', data.email);
        formData.append('password', data.password);
  
        const token = await getToken(formData);

        dispatch(setUserType('admin'));
        dispatch(setIsToken(token.access_token));
      } catch (error: any) {
        console.log(error.response.data.detail);
        toast.error(
          error.response.data.detail === 'Incorrect email or password'
          ? 'Неправильный логин или пароль!'
          : 'Ошибка авторизации!'
        );
      };
    }
  };

  useEffect(() => {
    if (authToken) {
      navigate('/main/list');
    }
  }, [authToken]);

  return (
    <AuthStyled>
      <AuthFormStyled onSubmit={handleSubmit(onSubmit)}>

        <LogoStyled wid={220} src={logo} hei={109.7}/>

        <InputsBlockStyled>
          {isPageRegister && (
            <>
              <TextInput
                control = {control}
                name = 'name'
                label = 'Name'
                isExit = {false}
                variant='second'
                width='270px'
              />
              <TextInput
                control = {control}
                name = 'telegram'
                label = 'Telegram'
                isExit = {false}
                variant='second'
                width='270px'
              />
            </>
          )}

          <TextInput
            control = {control}
            name = 'email'
            label = 'E-mail'
            isExit = {false}
            variant='second'
            width='270px'
          />
        </InputsBlockStyled>

        <TextInput
          control = {control}
          name = 'password'
          label = 'Password'
          isExit = {false}
          variant='second'
          type='password'
          width='270px'
        />

        <ButtonsStyled>
          {isPageRegister ? (
            <ButtonStyled
              variant='darkBorder'
              type="button"
              onClick={() => setIsPageRegister(false)}
            >
              Есть Аккаунт
            </ButtonStyled>
          ) : (
            <ButtonStyled
              variant='darkBorder'
              type="button"
              onClick={() => setIsPageRegister(true)}
            >
              Регистрация
            </ButtonStyled>
          )}
          
          <ButtonItem variant='darkBlue' buttonText='Войти' funcClick={handleSubmit(onSubmit)}/>
        </ButtonsStyled>
      </AuthFormStyled>
    </AuthStyled>
  );
};

export default Auth;