import {
  FormBasicStyled,
  ContentStyled,
  ButtonAddStyled,
} from "./styled";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooksStore";
import { NavigateOptionEdit } from "../../../../types/NavigateOption";
import ButtonsEdit from "../ButtonsEdit/ButtonsEdit";
import { ReviewEditType } from "../../../../types/EditsTypes";
import { setEditComments } from "../../../../store/aplicationsEdit/aplicationEdit";
import { useState } from "react";
import CommentFrom from "./CommentFrom/CommentFrom";
import { defaultValueEdits } from "../../../../utils/consts";
import CommentSee from "./CommentSee/CommentSee";

type Props = {
  setNavEdit: React.Dispatch<React.SetStateAction<NavigateOptionEdit>>;
};

const EditComment: React.FC<Props> = ({ setNavEdit }) => {
  const dispatch = useAppDispatch();
  const { commentsEdit } = useAppSelector(state => state.aplicationEdit);
  const [fieldsComments, setFieldsComments] = useState<ReviewEditType>(commentsEdit);
  const [editIndex, setEditIndex] = useState<number | null>(commentsEdit.length > 1 ? null : 0);
  const isAllHaveId = fieldsComments.every(item => item.id !== undefined && item.id !== null);

  const createNewComment = () => {
    setFieldsComments(prewValue => [...prewValue, ...defaultValueEdits.commentsEdit])

    setEditIndex(fieldsComments.length);
  };

  const saveComments = () => {
      if (fieldsComments.length !== 0) {
        dispatch(setEditComments(fieldsComments.filter(item => item.id !== undefined && item.id !== null)));
      } else {
        dispatch(setEditComments([]));
      }

      setNavEdit("settings");
  };
 
  return (
    <FormBasicStyled>
      <ContentStyled>

        {!!fieldsComments.length && fieldsComments.map((item, index) => (
          <>
            {editIndex === index ? (
              <CommentFrom
                itemEdit={item}
                indexItem={index}
                setFieldsComments={setFieldsComments}
                setEditIndex={setEditIndex}
              />
            ) : (
              <>
                {editIndex === null && <CommentSee itemComment={item} setEditIndex={setEditIndex} index={index}/>}
              </>
            )}
          </>
        ))}
        
        <ButtonAddStyled
          variant="blue"
          type="button"
          onClick={createNewComment}
          disabled={!isAllHaveId}
          isDisabled={!isAllHaveId}
        >
          +
        </ButtonAddStyled>
      </ContentStyled>


      <ButtonsEdit
        setNavEdit={setNavEdit}
        toPage='description'
        isActive={true}
        submitFunction={saveComments}
      />
    </FormBasicStyled>
  );
};

export default EditComment;
