import * as yup from 'yup';

// Визначаємо, що поля reply є обов'язковими, якщо хоча б одне з полів reply є непорожнім
const replyCondition = yup.string().test(
  'reply-required',
  'Поле обязательное при наличии ответа!',
  function (value) {
    const { reply_user_name, reply_date, reply_text } = this.parent;
    if (reply_user_name || reply_date || reply_text) {
      return !!value;
    }
    return true;
  }
);

export const schema = yup.object().shape({
  avatar: yup
    .array()
    .min(1, 'Должно быть одно фото')
    .required('Поле обязательное!'),
  user_name: yup
    .string()
    .required('Поле обязательное!'),
  publication_date: yup
    .string()
    .required('Выберите дату!'),
  rating: yup
    .string()
    .required('Поле обязательное!'),
  likes: yup
    .string()
    .required('Поле обязательное!'),
  text: yup
    .string()
    .required('Поле обязательное!'),
  reply_user_name: replyCondition,
  reply_date: replyCondition,
  reply_text: replyCondition,
});