import styled from "styled-components";

const ContentInputsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;

  margin-top: 20px;
  padding-bottom: 20px;
`;

const BlockInputsStyled = styled.div`
  display: flex;
  gap: 52px;
`;

const ItemInputStyled = styled.div`
  position: relative;
`;

const BonusTextStyled = styled.div`
  white-space: nowrap;
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  left: 0;
  bottom: -5px;
  color: #868687;
`;

const AddImagesBlockStyled = styled.div`
  display: flex;
  gap: 119px;
  margin-top: 15px;
`;

export {
  ContentInputsStyled,
  BlockInputsStyled,
  ItemInputStyled,
  BonusTextStyled,
  AddImagesBlockStyled,
};
