import styled from 'styled-components';
import { CircleStyled } from '../../helperStyled/styledHelper';

const HeaderStyled = styled.header`
  width: 100%;
  height: 64px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 30px 85.57px;
`;

const BlockStyled = styled.div`
  display: flex;
  gap: 24px;
`;

const BlockItemStyled = styled.div`
  display: flex;
  align-items: center;
`;

const BlockInfoStyled = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  margin-left: 12px;
`;

const CircleHeadStyled = styled(CircleStyled)`
  width: 30px;
  height: 30px;
  background-color: none;
  border: 1px solid #323232;
`;

export {
  HeaderStyled,
  BlockStyled,
  BlockItemStyled,
  BlockInfoStyled,
  CircleHeadStyled,
};