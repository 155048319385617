import { Control } from "react-hook-form";
import Accordion from "../../../../../../components/Accordion/Accordion";
import TextInput from "../../../../../../components/Inputs/TextInput/TextInput";
import {
  BlockInputsStyled,
  ContentInputsStyled,
} from "./styled";
import SwitchInput from "../../../../../../components/Inputs/SwitchInput/SwitchInput";
import { DescriptionsEditType } from "../../../../../../types/EditsTypes";
import HelperText from "../../../../../_shared/HelperText/HelperText";
import NumberInput from "../../../../../../components/Inputs/NumberInput/NumberInput";

type Props = {
  control: Control<DescriptionsEditType, any>;
}

const ButtonAcordion: React.FC<Props> = ({ control }) => {
  const isButtonSpinerHelpText = (
    <>
      Не&nbsp;работает&nbsp;на&nbsp;системных&nbsp;доменах
    </>
  );

  return (
    <Accordion title="Кнопка">
      <ContentInputsStyled>
        <BlockInputsStyled>
          <SwitchInput
            control={control}
            name='isButtonSpiner'
            label="Кнопка в виде Spinner:"
            helperText={isButtonSpinerHelpText}
          />
        </BlockInputsStyled>

        <BlockInputsStyled>
          <NumberInput
            control={control}
            name='file_size'
            label="Размеры файла, Мб"
          />

          <NumberInput
            control={control}
            name='min_load_time'
            label="Мин. время заргузки"
          />

          <NumberInput
            control={control}
            name='max_load_time'
            label="Макс. время заргузки"
          />
        </BlockInputsStyled>
      </ContentInputsStyled>
    </Accordion>
  )
};

export default ButtonAcordion;