import { useForm } from "react-hook-form";
import { BlockInputsStyled, BlockStyled, ButtonsBlockStyled, CreateDeveloperStyled } from "./styled";
import { ButtonStyled } from "../../../helperStyled/styledHelper";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooksStore";
import { setPopUp } from "../../../store/core/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import TextInput from "../../Inputs/TextInput/TextInput";
import { PopUpKeys } from "../../../types/TypeCore";
import { TitlePopUpStyled } from "../PopCreateBuyDomen/styled";
import { toast } from "react-toastify";
import { PushSetCreate, PushSetType } from "../../../types/PushTupe";
import { getPushSets, sendPushSet } from "../../../api/requestService";
import { setPushSetsAll } from "../../../store/itemsSelect/itemsSelectSlice";
import ButtonItem from "../../ButtonItem/ButtonItem";

const PopCreatePushSet = () => {
  const dispatch = useAppDispatch();
  const { handleSubmit, control } = useForm<PushSetCreate>({
    resolver: yupResolver(schema),
    defaultValues: {
      "name": '',
    },
  });

  const { onChange } = useAppSelector(state => state.core.popUp);

  const onSubmit = async (data: PushSetCreate) => {
    try {
      const dataToFatch = {
        "name": data.name,
        "is_active": true,
      }

      const pushSetNew = await sendPushSet(dataToFatch);

      !!onChange && onChange({id: pushSetNew.id, name: pushSetNew.name});
      
      const pushSetsAll = await getPushSets();

      dispatch(setPushSetsAll(pushSetsAll));

      toast.success('PushSet создан!');

      dispatch(setPopUp({popUpName: PopUpKeys.NONE,}));
    } catch {
      console.log('error create');
      toast.error('Ошибка создания PushSet!');
    }
  };

  return (
    <CreateDeveloperStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <TitlePopUpStyled>Создание PushSet</TitlePopUpStyled>

        <BlockInputsStyled>
          <TextInput
            control = {control}
            name = 'name'
            label = 'Название'
            isExit = {true}
          />
        </BlockInputsStyled>

        <ButtonsBlockStyled>
          <ButtonStyled
            variant="white"
            type="reset"
            onClick={() => dispatch(setPopUp({popUpName: PopUpKeys.NONE,}))}
          >
            Отмена
          </ButtonStyled>

          <ButtonItem variant='blue' buttonText='Создать' funcClick={handleSubmit(onSubmit)}/>
        </ButtonsBlockStyled>
      </BlockStyled>
    </CreateDeveloperStyled>
  );
};

export default PopCreatePushSet;
