import styled, { css } from 'styled-components';

const Wrap = styled.div`
  display: flex;
  font-size: 18px;
  background-color: white;
  border: 1px solid #f1f1f6;
  border-radius: 16px;
  box-shadow: 8px 8px 10px -4px rgba(22, 19, 49, 0.08);
  overflow: hidden;

  .react-datepicker {
    &__header {
      padding: 0;
      background-color: transparent;
      border: none;
    }

    &__triangle {
      display: none !important;
      top: 1200px;
      left: 20px;
    }

    &__month {
      margin: 0;

      &-container {
        padding: 16px;
      }
    }

    &__day {
      width: 44px;
      line-height: 48px;
      text-align: center;
      margin: 0;

      &-name {
        width: 44px;
        line-height: 16px;
        text-align: center;
        margin: 0;

        font-weight: 400;
        font-size: 13px;

        color: #D1D5DB;
      }

      &-names {
        margin: 0;
        margin-bottom: 12px;
      }

      &:hover {
        background-color: #E0F7FA;
      }

      &--today {
        background-color: #E0F7FA;
        font-weight: 500;
        border-radius: 10px;
      }

      &--weekend {
        color: red;
      }

      &--disabled,
      &--outside-month {
        color: #DCDCDC;
      }

      &--selected,
      &--keyboard-selected {
        background-color: #4091F7;
        color: white;
        border-radius: 10px;
      }

      &--in-range,
      &--in-selecting-range {
        border-radius: 0;
        color: #E0F7FA;
        background-color: rgba(70, 148, 220, 0.3);
      }

      &--range-start,
      &--selecting-range-start {
        border-radius: 8px 0 0 8px;
        color: white;
        background-color: #E0F7FA;
      }

      &--range-end,
      &--selecting-range-end {
        border-radius: 0 8px 8px 0;
        color: white;
        background-color: #E0F7FA;
      }
    }

    &__time {
      &-container {
        width: 141px;
        padding: 20px 0 20px 16px;
        border-color: #DCDCDC;

        .react-datepicker__time-box {
          width: auto;
          text-align: start;
        }
      }

      &-list {
        max-height: 200px;

        &-item {
          display: inline-block;
          padding: 6px 36px 6px 8px !important;
          color: #001C3D;
          border-radius: 6px;

          &:not(:last-child) {
            margin-bottom: 6px;
          }

          &--selected {
            font-weight: 400 !important;
            background-color: #E0F7FA !important;

            &:hover {
              background-color: #E0F7FA !important;
            }
          }

          &--disabled {
            color: #DCDCDC !important;
            pointer-events: none;
          }

          &:hover {
            background-color: #E0F7FA !important;
          }
        }
      }
    }

    &-time__header {
      margin-bottom: 10px;
      padding-left: 8px;
      line-height: 24px;
      font-weight: 500;
      color: #001C3D;
      font-size: 14px;
      text-align: start;
    }
  }
`;

export { Wrap };
