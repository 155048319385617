import { InputBlockStyled, TextAreaStyled, LabelStyled } from "./styled";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import SwitchCircle from "../../SwitchCircle/SwitchCircle";
import HelperText from "../../../pages/_shared/HelperText/HelperText";

type Props<T extends FieldValues> = {
  control: Control<T>,
  name: Path<T>;
  label: string
  helperText?: JSX.Element | undefined;
  isNotPaddingLeft?: boolean;
};

const SwitchInput= <T extends FieldValues>({ control, name, label, helperText = undefined, isNotPaddingLeft = false}: Props<T>) => {
  return (
    <InputBlockStyled isHelperText={!!helperText} isNotPaddingLeft={isNotPaddingLeft} isLabel={!!label}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value = false } }) => (
          <>
            <LabelStyled>
              {!!helperText && <HelperText text={helperText}/>}
              {label}
            </LabelStyled>
            <TextAreaStyled>
              <SwitchCircle isActive={value} setIsActive={onChange}/>
            </TextAreaStyled>
          </>
        )}
      />
    </InputBlockStyled>
  )
};

export default SwitchInput;