import styled from "styled-components";

const PushSetListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;
`;

const ListStyled = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  overflow-x: auto;
  width: calc(100vw - 366.1px);
  margin-block: 20px;
  position: relative;
`;

const ListNamesStyled = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;

  & > :not(:first-child) {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 18px;
      width: 1px;
      background-color: #4091F7;
      left: 0;
    }
  }

  & > :first-child {
    border-left: 1px solid #BFBFBF;
    border-radius: 10px 0 0 10px;
  }

  & > :last-child {
    border-right: 1px solid #BFBFBF;
    border-radius: 0 10px 10px 0;
  }
`;

type ListNameItemProps = {
  css: any;
}

const ListNameItemStyled = styled.p<ListNameItemProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-block: 0;
  white-space: nowrap;
  flex-shrink: 0;
  padding-block: 20px 22px;
  border-top: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  background-color: #F9F9F9;
  padding-left: 36px;

  ${({ css }) => css}
`;

const ListItemsWidthStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 205.8px;
  min-height: 205.8px;
`;

const ListRowStyled = styled.div`
  display: flex;

  & > :not(:first-child) {
    margin-left: 5px;
  }
`;

type ListRowItemProps = {
  css: any;
}

const ListRowItemStyled = styled.p<ListRowItemProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  flex-shrink: 0;
  padding-block: 21px;
  border: 1px solid #BFBFBF;
  border-radius: 10px;
  background-color: #F9F9F9;
  margin: 0;
  display: flex;
  align-items: center;
  padding-left: 36px;

  ${({ css }) => css}
`;

type ButtonIconType = {
  userPermision?: boolean;
}

const ButtonIconStyled = styled.div<ButtonIconType>`
  ${({ userPermision = true }) => userPermision && 'cursor: pointer;'}
`;

export {
  PushSetListStyled,
  ListStyled,
  ListNamesStyled,
  ListNameItemStyled,
  ListItemsWidthStyled,
  ListRowStyled,
  ListRowItemStyled,
  ButtonIconStyled,
}