import * as yup from 'yup';

export const schemaRegister = yup.object().shape({
  telegram: yup
    .string()
    .min(3, 'Не меньше 3-х символов!')
    .max(40, 'Не больше 40 символов!')
    .required('Поле обязательное!'),
  email: yup
    .string()
    .min(3, 'Не меньше 3-х символов!')
    .max(40, 'Не больше 40 символов!')
    .email('Некорректный email!')
    .required('Поле обязательное!'),
  password: yup
    .string()
    .min(3, 'Не меньше 3-х символов!')
    .max(40, 'Не больше 40 символов!')
    .required('Поле обязательное!'),
  name: yup
    .string()
    .min(3, 'Не меньше 3-х символов!')
    .max(40, 'Не больше 40 символов!')
    .required('Поле обязательное!'),
});

export const schemaLogin = yup.object().shape({
  email: yup
    .string()
    .min(3, 'Не меньше 3-х символов!')
    .max(40, 'Не больше 40 символов!')
    .email('Некорректный email!')
    .required('Поле обязательное!'),
  password: yup
    .string()
    .min(3, 'Не меньше 3-х символов!')
    .max(40, 'Не больше 40 символов!')
    .required('Поле обязательное!'),
  telegram: yup.string(),
  name: yup.string(),
});