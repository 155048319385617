import { Control, FieldErrors, useFieldArray } from "react-hook-form";
import { ButtonAddStyled, ListItemsWidthStyled, ListNameItemStyled, ListNamesStyled, ListRowItemCheckBoxStyled, ListRowItemStyled, ListRowStyled, NameStyled, PushMassageStyled } from "./styled";
import { listNames } from "./PushListWekdaysApi";
import { ButtonStyled } from "../../../../helperStyled/styledHelper";
import { pushWekdays } from "../../../../utils/consts";
import SwitchInput from "../../../../components/Inputs/SwitchInput/SwitchInput";
import NumberInput from "../../../../components/Inputs/NumberInput/NumberInput";
import CheckBoxInput from "../../../../components/Inputs/CheckBoxInput/CheckBoxInput";
import { CreatePushType } from "../../../../types/PushTupe";
import { ErrorValidationStyled } from "../PushListMessage/styled";

type Props = {
  control: Control<CreatePushType, any>;
  errors: FieldErrors<Omit<CreatePushType, "id">>;
}

const PushListWekdays: React.FC<Props> = ({control, errors}) => {
  const defaultValue = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    sending_time: '',
    repeat: false,
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'push_weekdays',
  });

 return (
  <PushMassageStyled>
    <NameStyled>Сообщение</NameStyled>

    <ListNamesStyled>
      {listNames.map((nam) => (
        <ListNameItemStyled css={nam.styleList} key={nam.id}>
          {nam.title}
        </ListNameItemStyled>
      ))}
    </ListNamesStyled>

    <ListItemsWidthStyled>
      {!!fields.length && fields.map((item, index) => (
        <ListRowStyled key={item.id}>
          <ListRowItemStyled css={listNames[0].style}>
            <NumberInput
              plasehold=''
              wid={90}
              control={control}
              name={`push_weekdays.${index}.sending_time`}
              label=""
              mask="99:99"
            />
          </ListRowItemStyled>

          <ListRowItemStyled css={listNames[1].style}>
            <ListRowItemCheckBoxStyled>
              {pushWekdays.map(item => (
                <CheckBoxInput
                  control={control}
                  name={`push_weekdays.${index}.${item.isValue}`}
                  itemCheck={item.name}
                />
              ))}
            </ListRowItemCheckBoxStyled>
          </ListRowItemStyled>

          <ListRowItemStyled css={listNames[2].style}>
            <SwitchInput
              control={control}
              name={`push_weekdays.${index}.repeat`}
              label=""
              isNotPaddingLeft={true}
            />
          </ListRowItemStyled>

          <ListRowItemStyled css={listNames[3].style}>
            <ButtonStyled variant="red" onClick={() => remove(index)}>Удалить</ButtonStyled>
          </ListRowItemStyled>
        </ListRowStyled>
      ))}
    </ListItemsWidthStyled>

    {errors.push_weekdays && (
      <ErrorValidationStyled>
        {errors.push_weekdays.message && <>{errors.push_weekdays.message}</>}
        {errors.push_weekdays[0] && <>{errors.push_weekdays[0].message}</>}
      </ErrorValidationStyled>
    )}

    <ButtonAddStyled
      type="button"
      variant="blue"
      onClick={() => append(defaultValue)}
    >
      Добавить
    </ButtonAddStyled>
  </PushMassageStyled>
 )
};

export default PushListWekdays;