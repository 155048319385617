import styled, { css } from "styled-components";

type FiledIputProps = {
  error: boolean;
  activated: boolean;
  variant: 'first' | 'second';
};

const FieldInput = styled.div<FiledIputProps>`
  .field-input {
    border: none;
    border: 1px solid #868687;
    border-radius: 10px;
    ${({variant}) => {
      switch (variant) {
        case 'first': return css`
          width: 253px;
          height: 52px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          padding: 0 0 0 12px;
          transition: border-color 1s;

          &:focus {
            border: 2px solid #4091F7;
            padding: 0 0 0 11px;
            outline: none;
          }
        `;

        case 'second': return css`
            width: 240px;
            height: 56px;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            padding-left: 16px;
            transition: 0.3s;

            &:focus {
              padding-left: 15px;
              border: 3px solid #4091f7;
              outline: none;
            }
        `;
      }
    }}
  }

  .react-datepicker-popper {
    left: 45px !important;
    bottom: 95px !important;
    z-index: 101;
  }

  .react-datepicker-wrapper {
    display: block;
  }
`;

const Wrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FieldWrap = styled.div`
  position: relative;
`;

type LabelStaticProps = {
  error: boolean;
  variant: 'first' | 'second';
};

const LabelStatic = styled.label<LabelStaticProps>`
  font-weight: 400;
  line-height: 16px;

  ${({variant}) => {
    switch (variant) {
      case 'first': return css`
        display: flex;
        white-space: nowrap;
        font-size: 15px;
        width: 210px;
        padding: 4px 0 7px 16px;
        min-height: 27px;
        gap: 10px;
      `;

      case 'second': return css`
        position: absolute;
        background-color: #fff;
        padding-inline: 4px;
        font-size: 16px;
        left: 12px;
        top: -8px;
        z-index: 100;
      `;
    }
  }}
`;

const ErrorTextStyled = styled.div`
  flex: 1 1 auto;
  font-size: 10px;
  color: red;
  padding: 4px 0 0px 10px;
  height: 16px;
`;

export { FieldInput, Wrap, FieldWrap, LabelStatic, ErrorTextStyled };
