import { useForm } from "react-hook-form";
import { BlockInputsStyled, BlockStyled, ButtonsBlockStyled, CreateDeveloperStyled, ErrorItemStyled, RatingBlockStyled, RatingCalcStyled, RatingLabelStyled } from "./styled";
import { ButtonStyled } from "../../../helperStyled/styledHelper";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooksStore";
import { setPopUp } from "../../../store/core/core";
import { getRatings, sendAppRating } from "../../../api/requestService";
import { setRatingsAll } from "../../../store/itemsSelect/itemsSelectSlice";
import RangeInput from "../../Inputs/RangeInput/RangeInput";
import NumberInput from "../../Inputs/NumberInput/NumberInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import { STAR_SVG } from "../../../utils/SVG";
import { useState } from "react";
import { PopUpKeys } from "../../../types/TypeCore";
import { TitlePopUpStyled } from "../PopCreateBuyDomen/styled";
import { toast } from "react-toastify";
import ButtonItem from "../../ButtonItem/ButtonItem";

type CreateRatingType = {
  "total_reviews": string,
  "five_star_percent": number,
  "four_star_percent": number,
  "three_star_percent": number,
  "two_star_percent": number,
  "one_star_percent": number,
};

const PopCreateRating = () => {
  const [isErrorRating, setIsErrorRating] = useState(false);
  const dispatch = useAppDispatch();
  const { handleSubmit, control, watch } = useForm<CreateRatingType>({
    resolver: yupResolver(schema),
    defaultValues: {
      "five_star_percent": 0,
      "four_star_percent": 0,
      "three_star_percent": 0,
      "two_star_percent": 0,
      "one_star_percent": 0,
    },
  });

  const { onChange } = useAppSelector(state => state.core.popUp);

  const allItemsData = watch();

  const ratingAdd = allItemsData.five_star_percent +
    allItemsData.four_star_percent +
    allItemsData.three_star_percent +
    allItemsData.two_star_percent +
    allItemsData.one_star_percent;

  const maxValueFive = 100 - (ratingAdd - allItemsData.five_star_percent);
  const maxValueFour = 100 - (ratingAdd - allItemsData.four_star_percent);
  const maxValueThree = 100 - (ratingAdd - allItemsData.three_star_percent);
  const maxValueTwo = 100 - (ratingAdd - allItemsData.two_star_percent);
  const maxValueOne = 100 - (ratingAdd - allItemsData.one_star_percent);
  
  const ratingCalc = ratingAdd === 100 
    ? ((5 * (allItemsData.five_star_percent / 100)) +
      (4 * (allItemsData.four_star_percent / 100)) +
      (3 * (allItemsData.three_star_percent / 100)) +
      (2 * (allItemsData.two_star_percent / 100)) +
      (1 * (allItemsData.one_star_percent / 100))).toFixed(1)
    : 0;

  const onSubmit = async (data: CreateRatingType) => {
    if (ratingCalc === 0 || ratingAdd !== 100) {
      setIsErrorRating(true);
      return;
    } else {
      setIsErrorRating(false);
    };

    const dataItems = {...data, total_rating: ratingCalc}

    try {
      const rating = await sendAppRating(dataItems);

      !!onChange && onChange(rating?.total_rating.toString());
      
      const ratings = await getRatings();

      dispatch(setRatingsAll(ratings));

      toast.success('Рейтинг создан!');

      dispatch(setPopUp({popUpName: PopUpKeys.NONE}));
    } catch {
      console.log('error create');
      toast.error('Ошибка создания рейтинга!');
    }
  };

  return (
    <CreateDeveloperStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <TitlePopUpStyled>Создание Рейтинга</TitlePopUpStyled>

        <BlockInputsStyled>
          <RatingBlockStyled>
            <RatingLabelStyled>Рейтинг</RatingLabelStyled>
            <RatingCalcStyled>{ratingCalc}<STAR_SVG /></RatingCalcStyled>
            {isErrorRating && <ErrorItemStyled>Процентов в общем должно быть 100%</ErrorItemStyled>}
          </RatingBlockStyled>

          <NumberInput control={control} name='total_reviews' label="Отзывов" />
        </BlockInputsStyled>

        <RangeInput control={control} name='five_star_percent' label="Отзывов 5, в %" maxValue={maxValueFive}/>

        <RangeInput control={control} name='four_star_percent' label="Отзывов 4, в %" maxValue={maxValueFour}/>

        <RangeInput control={control} name='three_star_percent' label="Отзывов 3, в %" maxValue={maxValueThree}/>

        <RangeInput control={control} name='two_star_percent' label="Отзывов 2, в %" maxValue={maxValueTwo}/>

        <RangeInput control={control} name='one_star_percent' label="Отзывов 1, в %" maxValue={maxValueOne}/>

        <ButtonsBlockStyled>
          <ButtonStyled
            variant="white"
            type="reset"
            onClick={() => dispatch(setPopUp({popUpName: PopUpKeys.NONE}))}
          >
            Отмена
          </ButtonStyled>

          <ButtonItem variant='blue' buttonText='Создать' funcClick={handleSubmit(onSubmit)}/>
        </ButtonsBlockStyled>
      </BlockStyled>
    </CreateDeveloperStyled>
  );
};

export default PopCreateRating;
