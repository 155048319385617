import React from "react";

import { columns } from "./config";
import { useAppSelector } from "../../../../../hooks/hooksStore";
import ListRowItem from "./ListRowFixWidth/ListRowFixWidth";
import { useEditPage } from "../../../../../hooks/useEditPage";
import { SpinerStyled } from "../../../../../components/ButtonItem/styled";
import {
  ContainerStyled,
  ListNameItemStyled,
  ListNamesStyled,
  ListRowContainerStyled,
  ListRowStyled,
  ListStyled,
  LoadingStyled,
} from "./styled";

type Props = {
  isLoading: boolean;
  isError: boolean;
  forceUpdate: () => void;
};

const ListPWA: React.FC<Props> = ({ isLoading, isError, forceUpdate }) => {
  const { appFilterList, appFilterListArchive } = useAppSelector(
    (state) => state.pwalist
  );
  const { isArchive } = useAppSelector((state) => state.core);
  const { handleEditItem } = useEditPage();

  const appUseList = isArchive ? appFilterListArchive : appFilterList;

  return (
    <ContainerStyled>
      <ListStyled>
        <ListNamesStyled>
          {columns.map((nam) => (
            <ListNameItemStyled css={nam.style} key={nam.id}>
              {nam.title}
            </ListNameItemStyled>
          ))}
        </ListNamesStyled>

        <ListRowContainerStyled>
          {isLoading && (
            <LoadingStyled>
              <SpinerStyled variant="blue" />
            </LoadingStyled>
          )}

          {isError && !isLoading && !appUseList.length && (
            <div>Error Download Items</div>
          )}

          {!!appUseList.length &&
            !isLoading &&
            appUseList.map((pwa) => (
              <ListRowStyled key={pwa.id}>
                {columns.map((col) => (
                  <>
                    {!col.render ? (
                      <ListRowItem
                        text={pwa[col.name]?.toString() || ""}
                        css={col.styleItem}
                        name={col.name}
                        pwaId={pwa.id}
                      />
                    ) : (
                      col.render({ pwa, handleEditItem, forceUpdate })
                    )}
                  </>
                ))}
              </ListRowStyled>
            ))}
        </ListRowContainerStyled>
      </ListStyled>
    </ContainerStyled>
  );
};

export default ListPWA;
