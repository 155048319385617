import styled from 'styled-components';

const MainStyled = styled.main`
  min-height: calc(100vh - 64px);
  height: auto;
  width: 100%;
  display: flex;
  position: relative;

  background-color: #F5F5F5;
`;

const PageStyled = styled.div`
  flex-grow: 1;
  height: calc(100vh - 64px);
  overflow: auto;
  padding: 0 12px 29px 35px;
`;

export {
  MainStyled,
  PageStyled,
}