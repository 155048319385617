import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CategoriesType, DevelopersType, DomainsType, RatingsType, StoreCategoriesType, TagsType, TrackerType } from '../../types/TypePWA';
import { MetricPixelType } from '../../types/EditsTypes';
import { PushSetType } from '../../types/PushTupe';

type InitialStateType = {
  category_idAll: CategoriesType[];
  trackersAll: TrackerType[];
  developersAll: DevelopersType[];
  domainsAll: DomainsType[];
  ratingsAll: RatingsType[];
  facebook_pixelsAll: MetricPixelType[];
  tiktok_pixelsAll: MetricPixelType[];
  pushSets: PushSetType[];
  storeCategoriesAll: StoreCategoriesType[];
  tagsAll: TagsType[];
};

const initialState: InitialStateType = {
  category_idAll: [],
  trackersAll: [],
  developersAll: [],
  domainsAll: [],
  ratingsAll: [],
  facebook_pixelsAll: [],
  tiktok_pixelsAll: [],
  pushSets: [],
  storeCategoriesAll: [],
  tagsAll: [],
};

const itemsSelectSlice = createSlice({
  name: 'itemsSelect',
  initialState,
  reducers: {
    setCategoriesAll: ((state: InitialStateType, action: PayloadAction<CategoriesType[]>) => {
      state.category_idAll = action.payload;
    }),
    setTrackersAll: ((state: InitialStateType, action: PayloadAction<TrackerType[]>) => {
      state.trackersAll = action.payload;
    }),
    setDevelopersAll: ((state: InitialStateType, action: PayloadAction<DevelopersType[]>) => {
      state.developersAll = action.payload;
    }),
    setDomainsAll: ((state: InitialStateType, action: PayloadAction<DomainsType[]>) => {
      state.domainsAll = action.payload;
    }),
    setRatingsAll: ((state: InitialStateType, action: PayloadAction<RatingsType[]>) => {
      state.ratingsAll = action.payload;
    }),
    setFacebookPixelsAll: ((state: InitialStateType, action: PayloadAction<MetricPixelType[]>) => {
      state.facebook_pixelsAll = action.payload;
    }),
    setTikTopPixelsAll: ((state: InitialStateType, action: PayloadAction<MetricPixelType[]>) => {
      state.tiktok_pixelsAll = action.payload;
    }),
    setPushSetsAll: ((state: InitialStateType, action: PayloadAction<PushSetType[]>) => {
      state.pushSets = action.payload;
    }),
    setTagsAll: ((state: InitialStateType, action: PayloadAction<TagsType[]>) => {
      state.tagsAll = action.payload;
    }),
    setStoreCategoriesAll: ((state: InitialStateType, action: PayloadAction<StoreCategoriesType[]>) => {
      state.storeCategoriesAll = action.payload;
    }),
  },
});

export const {
  setCategoriesAll,
  setTrackersAll,
  setDevelopersAll,
  setDomainsAll,
  setRatingsAll,
  setFacebookPixelsAll,
  setTikTopPixelsAll,
  setPushSetsAll,
  setTagsAll,
  setStoreCategoriesAll,
} = itemsSelectSlice.actions;
export default itemsSelectSlice.reducer;