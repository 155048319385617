import { useForm } from "react-hook-form";
import { BlockInputsStyled, BlockStyled, ButtonsBlockStyled, CreateDeveloperStyled } from "./styled";
import { ButtonStyled } from "../../../helperStyled/styledHelper";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooksStore";
import { setPopUp } from "../../../store/core/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import TextInput from "../../Inputs/TextInput/TextInput";
import { PopUpKeys } from "../../../types/TypeCore";
import { TitlePopUpStyled } from "../PopCreateBuyDomen/styled";
import { toast } from "react-toastify";
import { getPushSets, getTags, sendPushSet, sendTag } from "../../../api/requestService";
import { setPushSetsAll, setTagsAll } from "../../../store/itemsSelect/itemsSelectSlice";
import { TagsType } from "../../../types/TypePWA";
import ButtonItem from "../../ButtonItem/ButtonItem";

const PopCreateTag = () => {
  const dispatch = useAppDispatch();
  const { handleSubmit, control } = useForm<Omit<TagsType, 'id'>>({
    resolver: yupResolver(schema),
    defaultValues: {
      "name": '',
    },
  });

  const { onChange, value } = useAppSelector(state => state.core.popUp);

  const onSubmit = async (data: Omit<TagsType, 'id'>) => {
    try {
      const dataToFatch = {
        "name": data.name,
      }

      const tagNaw = await sendTag(dataToFatch);

      !!onChange && onChange([...value, {id: tagNaw.id, name: tagNaw.name}]);
      
      const tagsFatch = await getTags();

      dispatch(setTagsAll(tagsFatch));

      toast.success('Tag создан!');

      dispatch(setPopUp({popUpName: PopUpKeys.NONE,}));
    } catch {
      console.log('error create');
      toast.error('Ошибка создания Tag!');
    }
  };

  return (
    <CreateDeveloperStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <TitlePopUpStyled>Создание Tag</TitlePopUpStyled>

        <BlockInputsStyled>
          <TextInput
            control = {control}
            name = 'name'
            label = 'Название'
            isExit = {true}
          />
        </BlockInputsStyled>

        <ButtonsBlockStyled>
          <ButtonStyled
            variant="white"
            type="reset"
            onClick={() => dispatch(setPopUp({popUpName: PopUpKeys.NONE,}))}
          >
            Отмена
          </ButtonStyled>

          <ButtonItem variant='blue' buttonText='Создать' funcClick={handleSubmit(onSubmit)}/>
        </ButtonsBlockStyled>
      </BlockStyled>
    </CreateDeveloperStyled>
  );
};

export default PopCreateTag;
