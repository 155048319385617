type App = {
  logic_operator: string,
  filtered_column: string,
  statement: string,
  value: string,
  buttonsList: string,
};

type Nam = {
  id: number;
  name: keyof App;
  title: string;
  style: {
    width: string;
    paddingLeft?: string;
    paddingRight?: string;
  };
  styleList: {
    width: string;
  };
};

export const listNames: Nam[] = [
  {
    id: 1,
    name: 'logic_operator',
    title: '',
    style: {
      width: '130px',
    },
    styleList: {
      width: '135px',
    },
  },
  {
    id: 2,
    name: 'filtered_column',
    title: 'Имя параметра',
    style: {
      width: '200px',
    },
    styleList: {
      width: '205px',
    },
  },
  {
    id: 3,
    name: 'statement',
    title: '',
    style: {
      width: '200px',
    },
    styleList: {
      width: '205px',
    },
  },
  {
    id: 4,
    name: 'value',
    title: 'Значение',
    style: {
      width: '180px',
    },
    styleList: {
      width: '185px',
    },
  },
  {
    id: 5,
    name: 'buttonsList',
    title: '',
    style: {
      width: '150px',
    },
    styleList: {
      width: '150px',
    },
  },
];