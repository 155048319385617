import { useEffect, useState } from "react";
import { AfterStyled, EditNavStyled, NavItemStyled } from "./styled";
import ButtonCreate from "./ButtonCreate/ButtonCreate";
import { NavigateOptionEdit } from "../../../../types/NavigateOption";

type Props = {
  selectedTab: NavigateOptionEdit;
  setNavEdit: React.Dispatch<React.SetStateAction<NavigateOptionEdit>>;
};

const EditNav: React.FC<Props> = ({ selectedTab, setNavEdit }) => {
  type EditNavItem = {
    name: string;
    nameActive: NavigateOptionEdit;
  };

  const editNavItems: EditNavItem[] = [
    {
      name: "Основное",
      nameActive: "basic",
    },
    {
      name: "Описание",
      nameActive: "description",
    },
    {
      name: "Комментарии",
      nameActive: "comments",
    },
    {
      name: "Настройки",
      nameActive: "settings",
    },
    {
      name: "Метрики",
      nameActive: "metrics",
    },
  ];

  return (
    <EditNavStyled>
      {editNavItems.map((item) => (
        <NavItemStyled
          isActive={selectedTab === item.nameActive}
          key={item.name}
          onClick={() => setNavEdit(item.nameActive)}
        >
          {item.name}
        </NavItemStyled>
      ))}

      <AfterStyled selectedTab={selectedTab} />

      <ButtonCreate />
    </EditNavStyled>
  );
};

export default EditNav;
