import { SubmitHandler, useForm } from "react-hook-form";
import { BlockStyled, ButtonsStyled, CreatePushStyled, DateBlockStyled, DateContainerStyled, EventBlockStyled, EventContainerStyled, EventHelpTextStyled, InputsDateStyled, SwitchBlock } from "./styled"
import { useAppDispatch, useAppSelector } from "../../../hooks/hooksStore";
import TextInput from "../../../components/Inputs/TextInput/TextInput";
import {SelectControlInput} from "../../../components/Inputs/SelectInput/SelectInput";
import PushListMessage from "./PushListMessage/PushListMessage";
import NumberInput from "../../../components/Inputs/NumberInput/NumberInput";
import { ButtonStyled } from "../../../helperStyled/styledHelper";
import SwitchInput from "../../../components/Inputs/SwitchInput/SwitchInput";
import { itemsEventPush, pushDate } from "../../../utils/consts";
import PushListFilter from "./PushListFilter/PushListFilter";
import PushListWekdays from "./PushListWekdays/PushListWekdays";
import { sendPush, updatePush } from "../../../api/requestService";
import { CreatePushType } from "../../../types/PushTupe";
import { setClearPushCreator } from "../../../store/pushEdit/pushEdit";
import { schema } from "./shema";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { PopUpKeys, SetPopUpType } from "../../../types/TypeCore";
import { setPopUp } from "../../../store/core/core";
import ButtonItem from "../../../components/ButtonItem/ButtonItem";



type Props = {
  setIsCreate: React.Dispatch<React.SetStateAction<boolean>>
};

const CreatePushPage: React.FC<Props> = ({ setIsCreate }) => {
  const dispatch = useAppDispatch();
  const {pushSets} = useAppSelector(state => state.itemsSelect);
  const {category_idAll} = useAppSelector(state => state.itemsSelect);
  const { itemsEdit, variant } = useAppSelector(state => state.pushEdit)

  const { handleSubmit, formState: { errors }, control, watch } = useForm<Omit<CreatePushType, 'id'>>({
    defaultValues: itemsEdit,
    resolver: yupResolver(schema),
  });

  const timeAfterToSec = (time: string, timeName: 'Минуты' | 'Часы' | 'Дни' | '') => {
    switch (timeName) {
      case 'Минуты': {
        return Number(time) * 60;
      }
      case 'Часы': {
        return Number(time) * 60 * 60;
      }
      case 'Дни': {
        return Number(time) * 60 * 60 * 24;
      }
    }
  };

  const onSubmit: SubmitHandler<Omit<CreatePushType, 'id'>> = async(data) => {
    try {
      const pushData = {
        push_set_id: data.push_set_id ? data.push_set_id.id : '',
        category_id: data.category_id ? data.category_id.id : '',
        is_active: data.is_active,
        name: data.name,
        time_after_event: timeAfterToSec(data.time_after_event, data.time_after_event_name),
        event_type: data.event_type ? data.event_type.name : '',
        push_languages: data.push_languages.map(item => {return {...item, image: item.image[0], language: item.language.slice(0, 2)}}),
        push_weekdays: data.push_weekdays,
        push_countries: data.push_countries.map(item => item.name.slice(0, 2)),
        push_filters: data.push_filters,
      };

      switch (variant) {
        case 'create': {
          await sendPush(pushData);
          toast.success('Push создан!');
          break;
        }
        case 'edit': {
          await updatePush({...pushData, id: itemsEdit.id}, itemsEdit.id || 0);
          toast.success('Push отредактирован!');
          break;
        }
      }

      dispatch(setClearPushCreator());
      setIsCreate(false);
    } catch (error) {
      console.log(error);
      toast.error('Ошибка запроса!');
    }
  };

  const helperText = (
    <>
      Если&nbsp;не&nbsp;выбрано,&nbsp;то&nbsp;уведомления&nbsp;уйдут&nbsp;на&nbsp;все&nbsp;приложения
    </>
  );

  const setPusSetCreate = (onChange: (...event: any[]) => void) => {
    const creater: SetPopUpType = {
      popUpName: PopUpKeys.PUSHSET,
      onChange: onChange,
    };

    dispatch(setPopUp(creater));
  };

  console.log(errors);
  console.log(watch());

  return (
    <CreatePushStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <TextInput control={control} name='name' label="Название" isExit={true}/>

        <SelectControlInput
          control={control}
          name='category_id'
          label="Категория"
          items={category_idAll.map(item => {return {id: item.id, name: item.name}})}
        />

        <SelectControlInput
          control={control}
          name='push_set_id'
          label="Push Sets"
          items={pushSets.map(item => {return {id: item.id, name: item.name}})}
          isAdding={true}
          setCreate={setPusSetCreate}
        />

        <SwitchBlock><SwitchInput control={control} name='is_active' label="Статус"/></SwitchBlock>
      </BlockStyled>

      <PushListMessage control={control} errors={errors}/>

      <DateBlockStyled>
        Время

        <DateContainerStyled>
          Отправить через

          <InputsDateStyled>
            <NumberInput control={control} name="time_after_event" label="К-ство" wid={95} plasehold=''/>
          
            <SelectControlInput
              control={control}
              name='time_after_event_name'
              label="Supporting Text"
              items={pushDate}
              width="120px"
              placeholder=''
            />
          </InputsDateStyled>

          После События
        </DateContainerStyled>
      </DateBlockStyled>

      <EventContainerStyled>
        Событие

        <EventBlockStyled>
          <SelectControlInput control={control} name='event_type' label="" items={itemsEventPush}/>

          <EventHelpTextStyled>Должен быть настроен постбэк в нашу сторону</EventHelpTextStyled>
        </EventBlockStyled>
      </EventContainerStyled>

      <PushListFilter control={control} errors={errors}/>

      <PushListWekdays control={control} errors={errors}/>

      <ButtonsStyled>
        <ButtonStyled type="button" variant="white" onClick={() => setIsCreate(false)}>Отмена</ButtonStyled>
        
        <ButtonItem
          variant="blue"
          buttonText={variant === 'create' ? 'Сохранить' : 'Отредактировать'}
          funcClick={handleSubmit(onSubmit)}
        />
      </ButtonsStyled>
    </CreatePushStyled>
  );
};

export default CreatePushPage;