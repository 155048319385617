import { useState } from "react";
import Stand from "../_shared/Stand/Stand";
import EditBasic from "./components/EditBasic/EditBasic";
import EditNav from "./components/EditNav/EditNav";
import { EditBoardStyled, EditDashStyled } from "./styled";
import { NavigateOptionEdit } from "../../types/NavigateOption";
import EditDescription from "./components/EditDescription/EditDescription";
import EditComment from "./components/EditComment/EditComment";
import EditSetting from "./components/EditSetting/EditSetting";
import EditMetric from "./components/EditMetric/EditMetric";

type EditTabs = Record<NavigateOptionEdit, JSX.Element>;

const EditPage = () => {
  const [selectedTab, setNavEdit] = useState<NavigateOptionEdit>("basic");

  const editTabs: EditTabs = {
    basic: <EditBasic setNavEdit={setNavEdit} />,
    description: <EditDescription setNavEdit={setNavEdit} />,
    comments: <EditComment setNavEdit={setNavEdit} />,
    settings: <EditSetting setNavEdit={setNavEdit}/>,
    metrics: <EditMetric setNavEdit={setNavEdit}/>,
  };

  return (
    <>
      <Stand />
      <EditDashStyled>
        <EditNav selectedTab={selectedTab} setNavEdit={setNavEdit} />
        <EditBoardStyled>{editTabs[selectedTab]}</EditBoardStyled>
      </EditDashStyled>
    </>
  );
};

export default EditPage;
