import { useState } from "react";
import { ButtonStyled } from "../../helperStyled/styledHelper";
import Stand from "../_shared/Stand/Stand";
import { EditDashStyled, ListPushSetsStyled } from "./styled";
import CreatePushPage from "./CreatePushPage/CreatePushPage";
import { usePWAList } from "../../hooks/usePWAList";
import PushPageList from "./PushPageList/PushPageList";
import { useAppDispatch } from "../../hooks/hooksStore";
import { setCreatePush } from "../../store/pushEdit/pushEdit";
import { getUserPermision } from "../../utils/func";
import { Navigate } from "react-router-dom";

const PushPage = () => {
  const pushPermision = getUserPermision('pushPage');
  const [isCreate, setIsCreate] = useState(false);
  const dispatch = useAppDispatch();

  const handleClickCreate = () => {
    dispatch(setCreatePush());
    setIsCreate(true);
  };

  usePWAList();


  return (
    <>
      {pushPermision ? (
        <>
          <Stand />
          <EditDashStyled>
            {isCreate ? (
              <CreatePushPage setIsCreate={setIsCreate}/>
            ) : (
              <ListPushSetsStyled>
                <ButtonStyled variant="blue" onClick={handleClickCreate}>Создать Push</ButtonStyled>
                <PushPageList setIsCreate={setIsCreate}/>
              </ListPushSetsStyled>
            )}
          </EditDashStyled>
        </>
      ) : (
        <Navigate to="main/list" replace />
      )}
    </>
  )
};

export default PushPage;