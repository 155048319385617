import styled from 'styled-components';

//   width: ${({wid}) => wid}px;
//   height: ${({hei}) => hei}px;
//   background-color: ${({col}) => col};
//   border: ${({ bor }) => bor};

const CircleStyled = styled.div`
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ButtonProps {
  variant: `blue` | `white` | 'darkBlue' | 'darkBorder' | 'red';
  css?: string;
};

const ButtonStyled = styled.button<ButtonProps>`
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  ${({variant}) => {switch (variant) {
    case 'blue':
      return `
        border: none;
        background-color: #4091F7;
        color: #FFF;
      `;

    case 'white':
      return `
        border: 1px solid #4091F7;
        background-color: #FFF;
        color: #323232;
      `;

    case 'darkBlue':
      return `
        border: none;
        background-color: #2418E4;
        color: #FFF;
      `;

    case 'darkBorder':
      return `
        border: 1px solid #323232;
        background-color: #FFF;
        color: #323232;
      `;

    case 'red':
      return `
        border: none;
        background-color: #FF6B6C;
        color: white;
      `;
  }}}

  ${({css}) => css ? css : ''}
`;

interface ImgProps {
  wid: number;
  hei: number;
  css?: any;
}

const ImgStyled = styled.img<ImgProps>`
  width: ${({ wid }) => wid}px;
  height: ${({ hei }) => hei}px;

  ${({ css }) => css}
`;

export {
  CircleStyled,
  ButtonStyled,
  ImgStyled,
}