import React, { useRef, useEffect, useState } from 'react';
import { HrefDomenStyled, ListRowItemStyled } from './styled';
import { toast } from 'react-toastify';

type Props = {
  text: string | number,
  css: {[key: string]: any},
  name: "name" | "id" | "category_id" | "clicks" | "domen"| "push_set_id" | "teg" | "create" | "tarif" | "install" | "uskl" | "reg" | "dep" | "editPwa" | "deletePwa";
  pwaId: string;
};

const ListRowItem: React.FC<Props> = ({ text, css, name, pwaId }) => {
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsOverflowed(element.scrollWidth > element.clientWidth);
    }
  }, [text]);

  const handleCopy = async (text: string) => {
    if (name !== 'id') {
      return;
    }
    try {
      await navigator.clipboard.writeText(text);
      toast.success(`Текст скопирван!`)
    } catch (error) {
      toast.error(`Ошибка копирования!`)
    }
  };

  return (
    <ListRowItemStyled css={css} ref={textRef} title={isOverflowed ? text.toString() : ""} onClick={() => handleCopy(text.toString())}>
       {text}
    </ListRowItemStyled>
  );
};

export default ListRowItem;