import { InputBlockStyled, InputStyled, LabelStyled } from "./styled";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import InputRange, { Range } from 'react-input-range';
import 'react-input-range/lib/css/index.css';

type Props<T extends FieldValues> = {
  control: Control<T>,
  name: Path<T>;
  label: string;
  maxValue: number;
};

const RangeInput = <T extends FieldValues>({ control, name, label, maxValue}: Props<T>) => {
  return (
    <InputBlockStyled>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value = 0 as number | Range } }) => {
          const handleChange = (valueChange: number | Range) => {
            if (((typeof valueChange === 'number' ? valueChange : 0)) <= maxValue || value > valueChange) {
              onChange(valueChange)
            } else (onChange(maxValue))
          };

          return (
            <>
              <LabelStyled>{label}</LabelStyled>
              <InputRange
                maxValue={100}
                minValue={0}
                value={value}
                onChange={handleChange}
              />
            </>
        )}}
      />
    </InputBlockStyled>
  )
};

export default RangeInput;