import iconTop from '../../../images/iconTop.png';
import {
  BlockStyled,
  CircleNavStyled,
  GoTopStyled,
  ItemTitleStyled,
  ListItemStyled,
  ListStyled,
  NavStyled,
  TitleStyled
} from './styled';
import { ImgStyled } from '../../../helperStyled/styledHelper';
import { Link, useLocation } from 'react-router-dom';
import { getUserPermision } from '../../../utils/func';

const pathNames: { [key: string]: string } = {
  "/main/list": "ListPage",
  "/main/statistic": "StatisticPage",
  "/main/admin": "AdminPage",
  "/main/push": "PushPage",
};

const Nav = () => {
  const path = useLocation();
  const pathName = pathNames[path.pathname];

  const adminPermision = getUserPermision('admipPage');
  const pushPermision = getUserPermision('pushPage');

  return (
    <NavStyled>
      <BlockStyled>
        <TitleStyled>PWA</TitleStyled>

        <GoTopStyled>
          <ImgStyled wid={16} hei={16} src={iconTop} alt='ToTop'/>
        </GoTopStyled>
      </BlockStyled>

      <ListStyled>
        <Link to={'list'} style={{ textDecoration: 'none' }}>
          <ListItemStyled isActive={pathName === 'ListPage'}>
            <CircleNavStyled isActive={pathName === 'ListPage'} />
            <ItemTitleStyled isActive={pathName === 'ListPage'}>
              Список
            </ItemTitleStyled>
          </ListItemStyled>
        </Link>

        <Link to={'statistic'} style={{ textDecoration: 'none' }}>
          <ListItemStyled isActive={pathName === 'StatisticPage'}>
            <CircleNavStyled isActive={pathName === 'StatisticPage'} />

            <ItemTitleStyled isActive={pathName === 'StatisticPage'}>
              Статистика
            </ItemTitleStyled>
          </ListItemStyled>
        </Link>

        {adminPermision && <Link to={'admin'} style={{ textDecoration: 'none' }}>
          <ListItemStyled isActive={pathName === 'AdminPage'}>
            <CircleNavStyled isActive={pathName === 'AdminPage'} />

            <ItemTitleStyled isActive={pathName === 'AdminPage'}>
              Админка
            </ItemTitleStyled>
          </ListItemStyled>
        </Link>}

        {pushPermision && <Link to={'push'} style={{ textDecoration: 'none' }}>
          <ListItemStyled isActive={pathName === 'PushPage'}>
            <CircleNavStyled isActive={pathName === 'PushPage'} />

            <ItemTitleStyled isActive={pathName === 'PushPage'}>
              Push
            </ItemTitleStyled>
          </ListItemStyled>
        </Link>}
      </ListStyled>
    </NavStyled>
  )
}

export default Nav;