import styled from "styled-components";

const FormDescriptionStyled = styled.form`
  margin-block: 30px 38px;
`;

const FormAccardionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export {
  FormDescriptionStyled,
  FormAccardionsStyled,
};
