import { SubmitHandler, useForm } from "react-hook-form";
import TextInput from "../../Inputs/TextInput/TextInput";
import { BlockInputsStyled, BlockStyled, ButtonsBlockStyled, CreateDeveloperStyled } from "./styled";
import { ButtonStyled } from "../../../helperStyled/styledHelper";
import { useAppDispatch } from "../../../hooks/hooksStore";
import { setPopUp } from "../../../store/core/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import { PopUpKeys } from "../../../types/TypeCore";
import { getCloudflares, sendCloudflare } from "../../../api/requestService";
import { setCloudflareList } from "../../../store/PWAList/PWAListSlice";
import { TitlePopUpStyled } from "../PopCreateBuyDomen/styled";
import { toast } from "react-toastify";
import ButtonItem from "../../ButtonItem/ButtonItem";

export type CreateCloudflareType = {
  id: number;
  name: string;
  email: string;
  api_key: string;
};

const PopCreateCloudflare = () => {
  const dispatch = useAppDispatch();
  const { handleSubmit, control } = useForm<Omit<CreateCloudflareType, 'id'>>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Omit<CreateCloudflareType, 'id'>> = async (data) => {
    try {
      await sendCloudflare(data);

      const cloudflares = await getCloudflares();

      dispatch(setCloudflareList(cloudflares));

      toast.success('Cloudflare создан!');

      dispatch(setPopUp({popUpName: PopUpKeys.NONE}));
    } catch {
      console.log('error create');
      toast.error('Ошибка создания Cloudflare!');
    }
  };

  return (
    <CreateDeveloperStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <TitlePopUpStyled>Создание Cloudflare</TitlePopUpStyled>

        <BlockInputsStyled>
          <TextInput control={control} name='name' label="Name" isExit={true} />

          <TextInput control={control} name='email' label="E-mail" isExit={true} />
        </BlockInputsStyled>

        <BlockInputsStyled>
          <TextInput control={control} name='api_key' label="API key" isExit={true} />
        </BlockInputsStyled>


        <ButtonsBlockStyled>
          <ButtonStyled
            variant="white"
            type="reset"
            onClick={() => dispatch(setPopUp({popUpName: PopUpKeys.NONE}))}
          >
            Отмена
          </ButtonStyled>

          <ButtonItem variant='blue' buttonText='Создать' funcClick={handleSubmit(onSubmit)}/>
        </ButtonsBlockStyled>
      </BlockStyled>
    </CreateDeveloperStyled>
  );
};

export default PopCreateCloudflare;
