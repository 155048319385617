import styled from "styled-components";

type OptionsSelectType = {
  css: any;
  isActive: boolean;
};

const OptionsSelectStyled = styled.div<OptionsSelectType>`
  max-height: 218px;
  overflow: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  transform: ${({ isActive }) => isActive ? 'scaleY(100%)' : 'scaleY(0%)'};
  opacity: ${({ isActive }) => isActive ? '1' : '0'};
  transform-origin: top;
  transition: transform 0.5s, opacity 0.5s;
  z-index: 1000;

  ${({ css }) => css}
`;

type OptionType = {
  isActive: boolean;
};

const OptionStyled = styled.p<OptionType>`
  flex-shrink: 0;
  cursor: pointer;
  margin: 0;
  padding-left: 12px;
  width: 100%;
  height: 52px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-radius: 10px;
  background-color: ${({ isActive }) => isActive ? '#DAE3EC' : 'none'};
  display: flex;
  align-items: center;

  &:hover {
    background-color: #ECECEC;
  }
`;

export {
  OptionsSelectStyled,
  OptionStyled,
}