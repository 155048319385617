import styled from "styled-components";
import InputMask from 'react-input-mask';

const InputBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 2px;
`;

const LabelStyled = styled.label<InputProps>`
  white-space: nowrap;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  width: 210px;
  padding: 4px 0 7px 16px;
  max-width: ${({ width }) => width}px;;
`;

type InputProps = {
  width: number;
};

const InputMaskStyled = styled(InputMask)<InputProps>`
  width: ${({ width }) => width}px;
  height: 52px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #868687;
  border-radius: 10px;
  padding: 0 0 0 12px;
  transition: border-color 1s;

  &:focus {
    border: 2px solid #4091F7;
    padding: 0 0 0 11px;
    outline: none;
  }
`;

const InputStyled = styled.input<InputProps>`
  width: ${({ width }) => width}px;
  height: 52px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #868687;
  border-radius: 10px;
  padding: 0 0 0 12px;
  transition: border-color 1s;

  &:focus {
    border: 2px solid #4091F7;
    padding: 0 0 0 11px;
    outline: none;
  }
`;

type ImgAddProps = {
  isLabel: boolean;
};

const ImgAddsStyled = styled.div<ImgAddProps>`
  position: absolute;
  top: ${({isLabel}) => isLabel ? '41px' : '14px'};
  right: 14px;

  display: flex;
  flex-direction: column;
  gap: 9.59px;
`;

const ImgClearStyled = styled.img`
  width: 12px;
  height: 7.41px;
  cursor: pointer;
`;

const ErrorTextStyled = styled.div`
  height: 16px;
  padding: 4px 0 0 10px;
  font-size: 10px;
  color: red;
`;

export {
  InputBlockStyled,
  LabelStyled,
  InputMaskStyled,
  InputStyled,
  ImgAddsStyled,
  ImgClearStyled,
  ErrorTextStyled,
};