import { ImgStyled } from "../../../../helperStyled/styledHelper";
import { ACCEPT_SVG, NOTACCEPT_SVG } from "../../../../utils/SVG";
import { OptionStyled, OptionsSelectStyled, SVGItemNoFixSizeStyled } from "./styled";

type Props = {
  items: {id: number, name: string}[];
  isActive: boolean;
  css: { [key: string]: string };
  isClick: (...event: any[]) => void;
  activeItem: any[];
  setIsOpenOptions: React.Dispatch<React.SetStateAction<boolean>>;
};

const MultiOptionsSelect: React.FC<Props> = ({ items, isActive, css, isClick, activeItem, setIsOpenOptions }) => {
  const handleClick = (
    item: {
      id: number;
      name: string;
    }
  ) => {
    if (activeItem.some(obj => obj.id === item.id)) {
      isClick(activeItem.filter(obj => obj.id !== item.id));
    } else {
      isClick([...activeItem, item]);
    }
  };

  return (
    <OptionsSelectStyled
      css={css}
      isActive={isActive}
      tabIndex={0}
      onBlur={() => setIsOpenOptions(false)}
    >
      {items.map((item) => (
        <OptionStyled onClick={() => handleClick(item)} key={item.id}>
          {item.name}
          <SVGItemNoFixSizeStyled>
            {activeItem.some(obj => obj.id === item.id) ? <ACCEPT_SVG /> : <NOTACCEPT_SVG />}
          </SVGItemNoFixSizeStyled>
        </OptionStyled>
      ))}
    </OptionsSelectStyled>
  )
};

export default MultiOptionsSelect;