import { SubmitHandler, useForm } from "react-hook-form";
import TextInput from "../../Inputs/TextInput/TextInput";
import { BlockInputsStyled, BlockStyled, ButtonsBlockStyled, CreateDeveloperStyled, TitlePopUpStyled } from "./styled";
import { ButtonStyled } from "../../../helperStyled/styledHelper";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooksStore";
import { setPopUp } from "../../../store/core/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import { PopUpKeys } from "../../../types/TypeCore";
import {SelectControlInput} from "../../Inputs/SelectInput/SelectInput";
import { getDomains, sendDomain } from "../../../api/requestService";
import { setDomainsAll } from "../../../store/itemsSelect/itemsSelectSlice";
import { toast } from "react-toastify";
import ButtonItem from "../../ButtonItem/ButtonItem";

export type CreateDomainType = {
  id: number;
  namecheap_id: {id: number, name: string};
  cloudflare_id: {id: number, name: string};
  name: string;
};

const PopCreateBuyDomen = () => {
  const dispatch = useAppDispatch();
  const {cloudflareList, namecheapList} = useAppSelector(state => state.pwalist);
  const cloudflareItems = cloudflareList.map(item => {return {id: item.id, name: item.email}});
  const namecheapItems = namecheapList.map(item => {return {id: item.id, name: item.email}});
  const { handleSubmit, control } = useForm<Omit<CreateDomainType, 'id'>>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<Omit<CreateDomainType, 'id'>> = async (data) => {
    try {
      const domainData = {
        namecheap_id: data.namecheap_id.id,
        cloudflare_id: data.cloudflare_id.id,
        name: data.name,
      };

      await sendDomain(domainData);

      const domain = await getDomains();

      dispatch(setDomainsAll(domain));

      toast.success('Домен создан!');

      dispatch(setPopUp({popUpName: PopUpKeys.NONE}));
    } catch (error: any) {
      toast.error(error.response.data.detail ? error.response.data.detail : 'Ошибка создания домена!');
    }
  };

  return (
    <CreateDeveloperStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <TitlePopUpStyled>Создание Домена</TitlePopUpStyled>

        <BlockInputsStyled>
          <SelectControlInput control={control} name='namecheap_id' label="Namechap" items={namecheapItems}/>

          <SelectControlInput control={control} name='cloudflare_id' label="Cloudflare" items={cloudflareItems}/>
        </BlockInputsStyled>

        <BlockInputsStyled>
          <TextInput control={control} name='name' label="Domain Name" isExit={true} />
        </BlockInputsStyled>


        <ButtonsBlockStyled>
          <ButtonStyled
            variant="white"
            type="reset"
            onClick={() => dispatch(setPopUp({popUpName: PopUpKeys.NONE}))}
          >
            Отмена
          </ButtonStyled>

          <ButtonItem variant='blue' buttonText='Создать' funcClick={handleSubmit(onSubmit)}/>
        </ButtonsBlockStyled>
      </BlockStyled>
    </CreateDeveloperStyled>
  );
};

export default PopCreateBuyDomen;
