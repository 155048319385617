import styled, { css } from 'styled-components';
import { ImgStyled } from '../../../helperStyled/styledHelper';

const InputBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 2px;
  max-width: 253px;
`;

const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

type LabelProps = {
  isHelperText: boolean;
};

const LabelStyled = styled.label<LabelProps>`
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  width: 100%;

  ${({isHelperText}) => isHelperText ? css`
    padding: 4px 0 7px 0;
  ` : css`
    padding: 4px 0 7px 16px;
  `}

  display: flex;
  gap: 10px;
`;

type SelectType = {
  isDefault: boolean;
  isOpened: boolean;
  width: string;
};

const SelectStyled = styled.div<SelectType>`
  cursor: pointer;
  width: ${({ width }) => width};
  height: 52px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: ${({ isOpened }) => isOpened ? '2px solid #4091F7' : '1px solid #868687'};
  padding: ${({ isOpened }) => isOpened ? '11px 0 11px 12px' : '12px 0 12px 13px'};
  border-radius: 10px;
  transition: border-color 0.5s;
  color: ${({ isDefault }) => isDefault ? '#868687' : '#323232'};
  position: relative;
  background-color: white;
`;

type SelectInputType = {
  isDefault: boolean;
  isOpened: boolean;
  width: string;
};

const SelectInputStyled = styled.input<SelectInputType>`
  cursor: pointer;
  width: ${({ width }) => width};
  height: 52px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: ${({ isOpened }) => isOpened ? '2px solid #4091F7' : '1px solid #868687'};
  padding: ${({ isOpened }) => isOpened ? '11px 0 11px 12px' : '12px 0 12px 13px'};
  border-radius: 10px;
  transition: border-color 0.5s;
  color: ${({ isDefault }) => isDefault ? '#868687' : '#323232'};
  position: relative;
  background-color: white;
  outline: none;
`;

type ImgRightProps = {
  isLabel: boolean;
};

const ImgRightStyled = styled.img<ImgRightProps>`
  position: absolute;
  width: 12px;
  ${({isLabel}) => isLabel ? `top: 49.29px;` : 'top: 22.29px;'}
  height: 7.41px;
  right: 18px;
  cursor: pointer;
`;

const ErrorTextStyled = styled.div`
  height: 16px;
  padding: 4px 0 0 10px;
  font-size: 10px;
  color: red;
`;

const ImageAddStyled = styled(ImgStyled)`
  padding: 10px;
  top: 35px;
  box-sizing: content-box;
  position: absolute;
  right: -40px;
  cursor: pointer;
`;

export {
  InputBlockStyled,
  ContainerStyled,
  LabelStyled,
  SelectStyled,
  SelectInputStyled,
  ImgRightStyled,
  ErrorTextStyled,
  ImageAddStyled,
};