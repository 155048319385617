import * as yup from 'yup';

export const schema = yup.object().shape({
  facebookPixelId: yup.array()
    .test('at-least-one', 'Выберите одно значение!', function (value) {
      const { facebookPixelId, tikTokPixelId } = this.parent;
      return (value && value.length > 0) || (tikTokPixelId && tikTokPixelId.length > 0);
    }).required(),

  tikTokPixelId: yup.array()
    .test('at-least-one', 'Выберите одно значение!', function (value) {
      const { facebookPixelId, tikTokPixelId } = this.parent;
      return (value && value.length > 0) || (facebookPixelId && facebookPixelId.length > 0);
    }).required(),
});