import { SubmitHandler, useForm } from "react-hook-form";
import {
  BlockStyled,
  FormBasicStyled,
  InfoTextStyled,
} from "./styled";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooksStore";
import { NavigateOptionEdit } from "../../../../types/NavigateOption";
import ButtonsEdit from "../ButtonsEdit/ButtonsEdit";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import { SettingEditType } from "../../../../types/EditsTypes";
import SwitchInput from "../../../../components/Inputs/SwitchInput/SwitchInput";
import {SelectControlInput} from "../../../../components/Inputs/SelectInput/SelectInput";
import { setEditSettings } from "../../../../store/aplicationsEdit/aplicationEdit";

type Props = {
  setNavEdit: React.Dispatch<React.SetStateAction<NavigateOptionEdit>>;
};

const EditSetting: React.FC<Props> = ({ setNavEdit }) => {
  const dispatch = useAppDispatch();
  const itemsBasic = ['standard', 'version2'];
  const { settingsEdit } = useAppSelector(state => state.aplicationEdit)

  const { handleSubmit, control } = useForm<SettingEditType>({
    resolver: yupResolver(schema),
    defaultValues: settingsEdit,
  });

  const onSubmit: SubmitHandler<SettingEditType> = async (data) => {
    try {
      const dataContainer = {
        android_only: data.android_only,
        block_vpn_tor_hosting: data.block_vpn_tor_hosting,
        block_proxy: data.block_proxy,
        trafficback_for_all: data.trafficback_for_all,
        trafficback_for_not_android: data.trafficback_for_not_android,
        pixel_as_img: data.pixel_as_img,
        pixel_in_whitepage: data.pixel_in_whitepage,
        redirect_type: data.redirect_type,
      };

      dispatch(setEditSettings(settingsEdit.id ? {...dataContainer, id: settingsEdit.id} : dataContainer));

      setNavEdit("metrics");
    } catch (error) {
      console.error(error);
    }
  };

  const helperText = (
    <>
      ...
    </>
  )

  return (
    <FormBasicStyled onSubmit={handleSubmit(onSubmit)}>
      <BlockStyled>
        <SwitchInput control={control} name="android_only" label="Только  Android:" isNotPaddingLeft={true}/>
      </BlockStyled>

      <BlockStyled>
        <SwitchInput control={control} name="block_vpn_tor_hosting" label="VPN, TOR, Hosting Provider считать как бот: " isNotPaddingLeft={true}/>
      </BlockStyled>

      <BlockStyled>
        <SwitchInput control={control} name="block_proxy" label="Proxy считать как бот:" isNotPaddingLeft={true}/>
      </BlockStyled>

      <BlockStyled>
        <SwitchInput control={control} name="trafficback_for_all" label="Включить функцию BackUrl для всего трафика:" helperText={helperText}/>
      </BlockStyled>

      <BlockStyled>
        <SwitchInput control={control} name="trafficback_for_not_android" label="Включить функцию BackUrl только если не Android:" helperText={helperText}/>
      </BlockStyled>

      <BlockStyled>
        <SwitchInput control={control} name="pixel_as_img" label="Пиксель как img:" helperText={helperText}/>
      </BlockStyled>

      <BlockStyled>
        <SwitchInput control={control} name="pixel_in_whitepage" label="Пиксель на WhitePage:" helperText={helperText}/>
      </BlockStyled>

      <SelectControlInput
        control={control}
        name="redirect_type"
        label="Тип редиректа"
        items={itemsBasic}
        helperText={helperText}
      />

      <InfoTextStyled>Если тип “Тип white” выключен, то настройка с фильтрацией трафика не будут работать, т.к. весь трафик идет на PWA</InfoTextStyled>

      <ButtonsEdit setNavEdit={setNavEdit} toPage='comments' isActive={true} />
    </FormBasicStyled>
  );
};

export default EditSetting;
