import { memo, useRef, useState } from "react";
import { getMonth, getYear } from "date-fns";

import {
  Wrap,
  Month,
  BtnArrow,
  MonthYear,
  Select,
  SelectField,
  ArrowsWrapStyled,
} from "./styled";
import { CHEVRON_SVG } from "../../../../utils/SVG";
import OptionsSelect from "../../../OptionsSelect/OptionsSelect";

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

const generateArrayOfYears = (yearsCount = 70) => {
  const max = new Date().getFullYear();
  const min = max - yearsCount;
  const years = [];

  // eslint-disable-next-line no-plusplus
  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};

const years = generateArrayOfYears();

type Props = {
  monthDate: any;
  decreaseMonth: any;
  changeYear: any;
  increaseMonth: any;
  prevMonthButtonDisabled: any;
  nextMonthButtonDisabled: any;
  value: any;
};

const DatePickerHeader: React.FC<Props> = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
  changeYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  value,
}) => {
  const styledOptions = {
    left: "0",
    top: "40px",
    width: "75px",
  };
  const selectRef = useRef(null);
  const [openedDropdown, setOpenedDropdown] = useState(false);

  const onChangeYear = (option: string) => {
    changeYear(Number(option));
    setOpenedDropdown(false);
  };

  console.log(value);

  return (
    <Wrap>
      <MonthYear>
        <Month>{months[getMonth(monthDate)]}</Month>

        <Select
          ref={selectRef}
          onClick={() => setOpenedDropdown(!openedDropdown)}
          onBlur={() => setOpenedDropdown(false)}
        >
          <SelectField>{getYear(monthDate)}</SelectField>

          <OptionsSelect
            items={years.map((item) => String(item))}
            isActive={openedDropdown}
            isClick={onChangeYear}
            activeItem={String(getYear(monthDate))}
            css={styledOptions}
          />
        </Select>
      </MonthYear>

      <ArrowsWrapStyled>
        <BtnArrow
          type="button"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <CHEVRON_SVG isLeft />
        </BtnArrow>
        <BtnArrow
          type="button"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <CHEVRON_SVG />
        </BtnArrow>
      </ArrowsWrapStyled>
    </Wrap>
  );
};

export const MemoizedDatePickerHeader = memo(DatePickerHeader);
