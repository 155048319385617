import * as yup from 'yup';

export const schema = yup.object().shape({
  internal_name: yup
    .string()
    .required('Поле обязательное!'),
  tracker_id: yup
  .string()
  .required('Поле обязательное!'),
  category_id: yup
    .string()
    .required('Поле обязательное!'),
  domain_id: yup
    .string()
    .required('Поле обязательное!'),
  tags: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(),
    })
  ).min(1, 'Минимум одно значение!')
  .required('Поле обязательное!'),
  push_set_id: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }).test('not-default-value', 'Выберите значение!', value => {
    return value.id !== -1;
  }),

  typeWhite: yup.string().nullable(),
});