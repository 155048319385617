import store from "../store/store";

const permissionLevels = {
  admin: {
    deletePwa: true,
    admipPage: true,
    pushPage: true,
    archive: true,
  },
  buyer: {
    deletePwa: false,
    admipPage: false,
    pushPage: false,
    archive: false,
  },
  qa: {
    deletePwa: true,
    admipPage: false,
    pushPage: true,
    archive: true,
  },
  teamlead: {
    deletePwa: true,
    admipPage: false,
    pushPage: true,
    archive: false,
  },
};

export const getUserPermision = (itemPermision: 'deletePwa' | 'admipPage' | 'pushPage' | 'archive'): boolean => {
  const state = store.getState();
  const role = state.auth.userType;

  if (!role) {
    return false;
  } else {
    return permissionLevels[role][itemPermision];
  }
};
