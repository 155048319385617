import styled from "styled-components";

const CreatePushStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

const BlockStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SwitchBlock = styled.div`
  margin: 20px 0 0 20px;
`

const DateBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
  margin-bottom: 20px;
`;

const DateContainerStyled = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 90px;
  font-weight: 500;
  font-size: 32px;
  line-height: 16px;

  background-color: #F9F9F9;
  border-radius: 10px;
  border: 1px solid #BFBFBF;
  padding-block: 10px;
`;

const InputsDateStyled = styled.div`
  display: flex;
  gap: 40px;
`;

const EventContainerStyled = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 20px;
`;

const EventBlockStyled = styled.div`
  padding: 30px 30px 15px;
  display: flex;
  flex-direction: column;
  background-color: #F9F9F9;
  border: 1px solid #BFBFBF;
  border-radius: 10px;
  margin-block: 20px;
  position: relative;
`;

const EventHelpTextStyled = styled.div`
  position: absolute;
  top: 11px;
  left: 45px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #868687;
`;

const ButtonsStyled = styled.div`
  display: flex;
  gap: 20px;
`;

export {
  CreatePushStyled,
  BlockStyled,
  SwitchBlock,
  DateBlockStyled,
  DateContainerStyled,
  InputsDateStyled,
  EventBlockStyled,
  EventContainerStyled,
  EventHelpTextStyled,
  ButtonsStyled,
};