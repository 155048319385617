import styled from "styled-components";

type ListRowItemProps = {
  css: any;
}

const ListRowItemStyled = styled.p<ListRowItemProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  flex-shrink: 0;
  padding-block: 21px;
  border-bottom: 2px solid #F9F9F9;
  margin: 0;

  ${({ css }) => css}
`;

const HrefDomenStyled = styled.a`
  color: black;
`;

export {
  ListRowItemStyled,
  HrefDomenStyled,
};