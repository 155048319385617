import styled from 'styled-components';
import { ButtonStyled } from '../../../../helperStyled/styledHelper';

const FormBasicStyled = styled.div`
  padding-block: 30px 38px;
`;

const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContainerCommentStyled = styled.form`
  display: flex;
  gap: 30px;
`;

const BlockStyled = styled.div`
  padding: 36px 23px 36px 25px;
  border: 1px solid #ECECEC;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const RowStyled = styled.div`
  display: flex;
  gap: 20px;
`;

type ButtonAddProps = {
  isDisabled: boolean;
}

const ButtonAddStyled = styled(ButtonStyled)<ButtonAddProps>`
  ${({isDisabled}) => isDisabled && `opacity: 0.3; cursor: default;`}
  width: 66px;
`;

const ButtonDeleteStyled = styled.div`
  cursor: pointer;
`;

export {
  FormBasicStyled,
  ContentStyled,
  ContainerCommentStyled,
  BlockStyled,
  RowStyled,
  ButtonAddStyled,
  ButtonDeleteStyled,
};