import { Control, Controller, FieldError, FieldValues, Path } from "react-hook-form";
import {
  ContainerStyled,
  DeleteItemStyled,
  ErrorTextStyled,
  ImageAddStyled,
  ImgRightStyled,
  InputBlockStyled,
  ItemsValueStyled,
  LabelStyled,
  SelectStyled,
  ValueStyled,
} from "./styled";
import { useRef, useState } from "react";
import select from "../../../images/select.png";
import addImg from "../../../images/miniIcon/add.png";
import HelperText from "../../../pages/_shared/HelperText/HelperText";
import MultiOptionsSelect from "./MultiOptionsSelect/MultiOptionsSelect";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { ImgStyled } from "../../../helperStyled/styledHelper";
import exit from "../../../images/exit.png";

type Props<T extends FieldValues> = {
  label: string;
  items: {id: number, name: string}[];
  placehoder?: string;
  isAdding?: boolean;
  setCreate?: (onChange: (...event: any[]) => void, value: any) => void;
  helperText?: JSX.Element | undefined;
  width?: string;
  maxWidth?: string;
  value: {
    id: number;
    name: string;
  }[];
  onChange: (...event: any[]) => void;
  error?: FieldError | undefined;
  variant?: 'first' | 'second';
};

const defaultValue = "Выберите значение";

export const MultiSelectInput = <T extends FieldValues>({
  label,
  items,
  isAdding = false,
  setCreate = () => {},
  helperText = undefined,
  width = '253px',
  placehoder = '',
  maxWidth = 'calc(100vw - 660.1px)',
  value,
  onChange,
  error = undefined,
  variant = 'first',
}: Props<T>) => {
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  const multSelectRef = useRef(null);
  const [isFilterText, setIsFilterText] = useState('');

  const filterItems = items.filter(item => {
    return item.name.toString().toLowerCase().includes(isFilterText.toLowerCase());
  });

  const styledOptions = {
    left: "0",
    top: !!label ? "84px" : "62px",
    width: width,
  };

  useOutsideClick(multSelectRef, () => {
    if (isOpenOptions) {
      setIsOpenOptions(false);
    }
  })

  return (
    <ContainerStyled ref={multSelectRef}>
      {!!label && <LabelStyled htmlFor="selectOption" isHelperText={!!helperText} variant={variant}>
        {!!helperText && <HelperText text={helperText}/>}

        {label}
      </LabelStyled>}

      <SelectStyled
        isDefault={false}
        isOpened={isOpenOptions}
        id="selectOption"
        onClick={() => setIsOpenOptions((prewValue) => !prewValue)}
        placeholder={!!placehoder ? placehoder : defaultValue}
        value={isFilterText}
        onChange={e => setIsFilterText(e.target.value)}
        variant={variant}
      />

      {!!value.length && <ItemsValueStyled isLabel={!!label} maxWidth={maxWidth} variant={variant}>
        {value.map(item => (
          <ValueStyled>
            {item.name}
            <DeleteItemStyled wid={15} hei={15} onClick={() => onChange(value.filter(obj => obj.id !== item.id))} src={exit}/>
          </ValueStyled>
        ))}
      </ItemsValueStyled>}
      
      <ImgRightStyled
        variant={variant}
        src={select} alt="swipe"
        onClick={() => setIsOpenOptions((prewValue) => !prewValue)} isLabel={!!label}
      />

      <ErrorTextStyled>{!!error ? error.message : ' '}</ErrorTextStyled>

      <MultiOptionsSelect
        items={filterItems}
        isActive={isOpenOptions}
        css={styledOptions}
        isClick={onChange}
        activeItem={value}
        setIsOpenOptions={setIsOpenOptions}
      />

      {isAdding && (
        <ImageAddStyled
          wid={15}
          hei={15}
          src={addImg}
          onClick={() => setCreate(onChange, value)}
        />
      )}
    </ContainerStyled>
  )
}

type Props2<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  items: {id: number, name: string}[];
  placehoder?: string;
  isAdding?: boolean;
  setCreate?: (onChange: (...event: any[]) => void, value: any) => void;
  helperText?: JSX.Element | undefined;
  width?: string;
  maxWidth?: string;
};

export const MultiSelectControl = <T extends FieldValues>({
  control,
  name,
  label,
  items,
  isAdding = false,
  setCreate = () => {},
  helperText = undefined,
  width = '253px',
  placehoder = '',
  maxWidth = 'calc(100vw - 660.1px)',
}: Props2<T>) => {
  

  return (
    <InputBlockStyled width='253px'>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value = [] as {id: number, name: string}[] },
          fieldState: { error },
        }) => (
          <MultiSelectInput
            label = {label}
            items = {items}
            isAdding = {isAdding}
            setCreate = {setCreate}
            helperText = {helperText}
            width = {width}
            placehoder = {placehoder}
            maxWidth = {maxWidth}
            onChange = {onChange}
            value = {value}
            error = {error}
          />
        )}
      />
    </InputBlockStyled>
  );
};
