import styled from 'styled-components';
import { ImgStyled } from '../../helperStyled/styledHelper';

const AuthStyled = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 143px;
`;

const AuthFormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
`;

const LogoStyled = styled(ImgStyled)`
 margin-bottom: 19px;
`

const InputsBlockStyled = styled.div`
  display: flex;
  gap: 48px;
`;

const ButtonsStyled = styled.div`
  display: flex;
  gap: 10px;
`;

export {
  AuthStyled,
  AuthFormStyled,
  LogoStyled,
  InputsBlockStyled,
  ButtonsStyled,
};