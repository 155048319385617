import styled, { css } from "styled-components";

type InputBlockType = {
  isHelperText: boolean;
  isNotPaddingLeft: boolean;
  isLabel: boolean;
}

const InputBlockStyled = styled.div<InputBlockType>`
  display: flex;
  position: relative;
  ${({isLabel}) => isLabel ? 'gap: 10px;' : 'gap: 0;'}
  align-items: center;
  ${({isHelperText, isNotPaddingLeft}) => isHelperText || isNotPaddingLeft ? css`
    padding: 0 0 16px 0;
  ` : css`
    padding: 0 0 16px 16px;
  `}
`;

const LabelStyled = styled.label`
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  gap: 10px;
`;

const TextAreaStyled = styled.div`
  width: auto;
  height: auto;
`;

export {
  InputBlockStyled,
  LabelStyled,
  TextAreaStyled,
};