import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooksStore';
import Header from '../Header/Header';
import PopCreateDeveloper from '../PopUp/PopCreateDeveloper/PopCreateDeveloper';
import PopCreateFacebookPixel from '../PopUp/PopCreateFacebookPixel/PopCreateFacebookPixel';
import PopCreateRating from '../PopUp/PopCreateRating/PopCreateRating';
import PopCreateTikTokPixel from '../PopUp/PopCreateTikTokPixel/PopCreateTikTokPixel';
import Nav from './Nav/Nav';
import { MainStyled, PageStyled } from './styled';
import { Outlet } from 'react-router-dom';
import { getAdminUsers, getCategories, getCloudflares, getDevelopers, getDomains, getFacebookPixels, getNamecheaps, getPushSets, getRatings, getStoreCategories, getTags, getTikTokPixels, getTrackers } from '../../api/requestService';
import { setCategoriesAll, setDevelopersAll, setDomainsAll, setFacebookPixelsAll, setPushSetsAll, setRatingsAll, setStoreCategoriesAll, setTagsAll, setTikTopPixelsAll, setTrackersAll } from '../../store/itemsSelect/itemsSelectSlice';
import { PopUpKeys, PopUpNamesType } from '../../types/TypeCore';
import PopCreateNamechap from '../PopUp/PopCreateNamechap/PopCreateNamechap';
import PopCreateCloudflare from '../PopUp/PopCreateCloudflare/PopCreateCloudflare';
import PopCreateBuyDomen from '../PopUp/PopCreateBuyDomen/PopCreateBuyDomen';
import { setAdminUser, setCloudflareList, setNamechapList } from '../../store/PWAList/PWAListSlice';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PopCreatePushSet from '../PopUp/PopCreatePushSet/PopCreatePushSet';
import PopCreateTag from '../PopUp/PopCreateTag/PopCreateTag';

type PopUpItems = Record<PopUpNamesType, JSX.Element>;

const Main = () => {
  const { popUpName } = useAppSelector(state => state.core.popUp);
  const dispatch = useAppDispatch();

  const activePopUp: PopUpItems = {
    [PopUpKeys.NONE]: <></>,
    [PopUpKeys.DEVELOPER]: <PopCreateDeveloper />,
    [PopUpKeys.RATING]: <PopCreateRating />,
    [PopUpKeys.FACEBOOK_PIXEL]: <PopCreateFacebookPixel />,
    [PopUpKeys.TIKTOK_PIXEL]: <PopCreateTikTokPixel />,
    [PopUpKeys.NAMECHAP]: <PopCreateNamechap />,
    [PopUpKeys.CLOUDFLARE]: <PopCreateCloudflare />,
    [PopUpKeys.BUYDOMEN]: <PopCreateBuyDomen />,
    [PopUpKeys.PUSHSET]: <PopCreatePushSet />,
    [PopUpKeys.TAG]: <PopCreateTag />,
  }

  useEffect(() => {
    const fetchPWA = async () => {
      try {
        const [
          category_id,
          trackers,
          developers,
          domains,
          ratings,
          facebookPixels,
          tiktokPixels,
          pushSets,
          cloudflares,
          namecheaps,
          adminUsers,
          tags,
          storeCategories,
        ] =
          await Promise.all([
            getCategories(),
            getTrackers(),
            getDevelopers(),
            getDomains(),
            getRatings(),
            getFacebookPixels(),
            getTikTokPixels(),
            getPushSets(),
            getCloudflares(),
            getNamecheaps(),
            getAdminUsers(),
            getTags(),
            getStoreCategories(),
          ]);

        dispatch(setCategoriesAll(category_id));
        dispatch(setTrackersAll(trackers));
        dispatch(setDevelopersAll(developers));
        dispatch(setDomainsAll(domains));
        dispatch(setRatingsAll(ratings));
        dispatch(setFacebookPixelsAll(facebookPixels));
        dispatch(setTikTopPixelsAll(tiktokPixels));
        dispatch(setPushSetsAll(pushSets));
        dispatch(setCloudflareList(cloudflares));
        dispatch(setNamechapList(namecheaps));
        dispatch(setAdminUser(adminUsers));
        dispatch(setTagsAll(tags));
        dispatch(setStoreCategoriesAll(storeCategories));
      } catch (error) {
        console.error("Failed to fetch category_id:", error);
      }
    };

    fetchPWA();
  }, []);

  return (
    <>
      <Header />
      <MainStyled>
        <>{activePopUp[popUpName]}</>
        <Nav />
        <PageStyled>
          <Outlet />
        </PageStyled>
      </MainStyled>
    </>
  )
}

export default Main;