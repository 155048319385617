import styled, { css } from "styled-components";

const InputBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 2px;
`;

type LabelProps = {
  variant: 'first' | 'second';
  isHelperText: boolean;
};

const LabelStyled = styled.label<LabelProps>`
  ${({variant}) => {
    switch (variant) {
      case 'first': return css`
        display: flex;
        white-space: nowrap;
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        width: 210px;
        padding: 4px 0 7px 16px;
        min-height: 27px;
        gap: 10px;
      `;

      case 'second': return css`
        position: absolute;
        background-color: #fff;
        padding-inline: 4px;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        left: 12px;
        top: -8px;
      `;
    }
  }}

  ${({isHelperText, variant}) => isHelperText && variant === 'first' && css`
    padding: 4px 0 7px 0;
  `}
`;

type InputProps = {
  variant: 'first' | 'second';
  width: string;
};

const InputStyled = styled.input<InputProps>`
${({variant}) => {
    switch (variant) {
      case 'first': return css`
        width: 253px;
        height: 52px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        border: 1px solid #868687;
        border-radius: 10px;
        padding: 0 0 0 12px;
        transition: border-color 1s;

        &:focus {
          border: 2px solid #4091F7;
          padding: 0 0 0 11px;
          outline: none;
        }
      `;

      case 'second': return css`
          width: 240px;
          height: 56px;
          border: 1px solid #868687;
          border-radius: 10px;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          padding-left: 16px;
          transition: 0.3s;

          &:focus {
            padding-left: 15px;
            border: 3px solid #4091f7;
            outline: none;
          }
      `;
    }
  }}

  ${({width}) => width && css`width: ${width};`}
`;

type ImgClearStyled = {
  isLabel: boolean;
}

const ImgClearStyled = styled.img<ImgClearStyled>`
  width: 20px;
  height: 20px;
  position: absolute;
  top: ${({isLabel}) => isLabel ? '43px' : '16px'};
  right: 14px;
`;

const ErrorTextStyled = styled.div`
  height: 16px;
  padding: 4px 0 0 10px;
  font-size: 10px;
  color: red;
`;

export {
  InputBlockStyled,
  LabelStyled,
  InputStyled,
  ImgClearStyled,
  ErrorTextStyled,
};