import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Core, PopUpKeys, SetPopUpType } from "../../types/TypeCore";

const initialState: Core = {
  isCreateAvailable: false,
  popUp: {
    popUpName: PopUpKeys.NONE,
  },
  isArchive: false,
};

const coreSlice = createSlice({
  name: "Core",
  initialState,
  reducers: {
    setIsCreateAvailable: (state: Core, action: PayloadAction<boolean>) => {
      state.isCreateAvailable = action.payload;
    },
    setPopUp: (state: Core, action: PayloadAction<SetPopUpType>) => {
      state.popUp = action.payload;
    },
    setIsArchive: (state: Core, action: PayloadAction<boolean>) => {
      state.isArchive = action.payload;
    },
  },
});

export const {
  setPopUp,
  setIsCreateAvailable,
  setIsArchive,
} = coreSlice.actions;
export default coreSlice.reducer;
