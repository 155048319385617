import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  BasicsEditType,
  CommentType,
  DescriptionsEditType,
  InitialEditsType,
  MetricEditType,
  SettingEditType,
} from "../../types/EditsTypes";
import { defaultValueEdits } from "../../utils/consts";

const initialState: InitialEditsType = defaultValueEdits;

const aplicationEditSlice = createSlice({
  name: "aplicetionEdit",
  initialState,
  reducers: {
    setId: (
      state: InitialEditsType,
      action: PayloadAction<InitialEditsType>
    ) => {
      return action.payload;
    },
    setEditBasics: (
      state: InitialEditsType,
      action: PayloadAction<BasicsEditType>
    ) => {
      state.basicsEdit = action.payload;
    },
    setEditDescriptions: (
      state: InitialEditsType,
      action: PayloadAction<DescriptionsEditType>
    ) => {
      state.descriptionsEdit = action.payload;
    },
    setEditComments: (
      state: InitialEditsType,
      action: PayloadAction<CommentType[]>
    ) => {
      state.commentsEdit = action.payload;
    },
    setEditSettings: (
      state: InitialEditsType,
      action: PayloadAction<SettingEditType>
    ) => {
      state.settingsEdit = action.payload;
    },
    setEditMetrics: (
      state: InitialEditsType,
      action: PayloadAction<MetricEditType>
    ) => {
      state.metricsEdit = action.payload;
    },
  },
});

export const {
  setId,
  setEditBasics,
  setEditDescriptions,
  setEditSettings,
  setEditComments,
  setEditMetrics,
} = aplicationEditSlice.actions;
export default aplicationEditSlice.reducer;
