import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Month = styled.div`
  width: 82px;
  font-weight: 600;
  font-size: 16px;
`;

const MonthYear = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BtnArrow = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
`;

const Select = styled.div`
  position: relative;
  display: flex;
  width: 68px;
  height: 26px;
  padding: 4px 0px 4px 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    background-color: #eaecf5;
  }
`;

const SelectField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  color: #111827;
`;

const ArrowsWrapStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export {
  Wrap,
  Month,
  BtnArrow,
  MonthYear,
  Select,
  SelectField,
  ArrowsWrapStyled,
};
