import styled, { css } from 'styled-components';

const InputBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-around;
`;

const ItemCheckBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const ErrorTextStyled = styled.div`
  height: 16px;
  padding: 4px 0 0 10px;
  font-size: 10px;
  color: red;
`;

export {
  InputBlockStyled,
  ItemCheckBoxStyled,
  ErrorTextStyled,
};