import * as yup from 'yup';

export const schema = yup.object().shape({
  website: yup
    .string()
    .url('Некорректная ссылка!')
    .required('Поле обязательное!'),
  address: yup
    .string()
    .required('Поле обязательное!'),
  name: yup
    .string()
    .required('Поле обязательное!'),
  policy: yup
    .string()
    .required('Поле обязательное!'),
  email: yup
    .string()
    .email('Некорректный email!')
    .required('Поле обязательное!'),
});