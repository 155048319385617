import styled from "styled-components";

const EditDashStyled = styled.div`
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #ECECEC;
  padding: 16px 23px 16px 23px;
`;

const ListPushSetsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: start;
`;

export {
  EditDashStyled,
  ListPushSetsStyled,
};