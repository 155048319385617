import {
  ErrorTextStyled,
  ImgClearStyled,
  InputBlockStyled,
  InputStyled,
  LabelStyled,
} from "./styled";
import exit from "../../../images/exit.png";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import HelperText from "../../../pages/_shared/HelperText/HelperText";

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  isExit: boolean;
  plasehold?: string;
  width?: string;
  externalValue?: string;
  variant?: 'first' | 'second';
  type?: 'text' | 'password';
  helperText?: JSX.Element | undefined;
};

const TextInput = <T extends FieldValues>({
  control,
  name,
  label,
  isExit,
  plasehold = "Введите значение",
  width = '',
  externalValue = "",
  variant = 'first',
  type = 'text',
  helperText = undefined,
}: Props<T>) => {
  return (
    <InputBlockStyled>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value = externalValue },
          fieldState: { error },
        }) => (
          <>
            {!!label && <LabelStyled variant={variant} isHelperText={!!helperText}>
              {!!helperText && <HelperText text={helperText}/>}

              {label}
            </LabelStyled>}
            
            <InputStyled
              value={value}
              onChange={onChange}
              placeholder={plasehold}
              variant={variant}
              type={type}
              width={width}
            />
            
            {isExit && !!value && (
              <ImgClearStyled src={exit} onClick={() => onChange("")} isLabel={!!label}/>
            )}

            <ErrorTextStyled>{!!error ? error.message : ' '}</ErrorTextStyled>
          </>
        )}
      />
    </InputBlockStyled>
  );
};

export default TextInput;
