import * as yup from 'yup';

export const schema = yup.object().shape({
  total_reviews: yup
    .string()
    .max(40, 'Не больше 40 символов!')
    .required('Поле обязательное!'),
  five_star_percent: yup
    .number()
    .required('Поле обязательное!'),
  four_star_percent: yup
    .number()
    .required('Поле обязательное!'),
  three_star_percent: yup
    .number()
    .required('Поле обязательное!'),
  two_star_percent: yup
    .number()
    .required('Поле обязательное!'),
  one_star_percent: yup
    .number()
    .required('Поле обязательное!'),
});