import { SubmitHandler, useForm } from "react-hook-form";
import {
  FormBasicStyled,
  InfoTextStyled,
} from "./styled";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooksStore";
import { NavigateOptionEdit } from "../../../../types/NavigateOption";
import ButtonsEdit from "../ButtonsEdit/ButtonsEdit";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./shema";
import {MultiSelectControl} from "../../../../components/Inputs/MultiSelectInput/MultiSelectInput";
import { setPopUp } from "../../../../store/core/core";
import { MetricEditType } from "../../../../types/EditsTypes";
import { setEditMetrics } from "../../../../store/aplicationsEdit/aplicationEdit";
import { PopUpKeys, SetPopUpType } from "../../../../types/TypeCore";

type Props = {
  setNavEdit: React.Dispatch<React.SetStateAction<NavigateOptionEdit>>;
};

const EditMetric: React.FC<Props> = ({ setNavEdit }) => {
  const dispatch = useAppDispatch();
  const { metricsEdit } = useAppSelector(state => state.aplicationEdit);
  const {facebook_pixelsAll, tiktok_pixelsAll} = useAppSelector(state => state.itemsSelect);
  const itemsFacebook = facebook_pixelsAll.map((item) => {
    return {id: item?.id || 0, name: item.pixel_id};
  });

  const itemsTikTok = tiktok_pixelsAll.map((item) => {
    return {id: item?.id || 0, name: item.pixel_id};
  });

  const { handleSubmit, control } = useForm<MetricEditType>({
    resolver: yupResolver(schema),
    defaultValues: metricsEdit,
  });

  const onSubmit: SubmitHandler<MetricEditType> = async (data) => {
    try {
      dispatch(setEditMetrics(structuredClone(data)));

      setNavEdit("metrics");
    } catch (error) {
      console.error(error);
    }
  };

  const setFacebookPixelCreate = (onChange: (...event: any[]) => void, value: any) => {
    const creater: SetPopUpType = {
      popUpName: PopUpKeys.FACEBOOK_PIXEL,
      onChange: onChange,
      value: value,
    };

    dispatch(setPopUp(creater));
  };

  const setTikTokCreate = (onChange: (...event: any[]) => void, value: any) => {
    const creater: SetPopUpType = {
      popUpName: PopUpKeys.TIKTOK_PIXEL,
      onChange: onChange,
      value: value,
    };

    dispatch(setPopUp(creater));
  };

  return (
    <FormBasicStyled onSubmit={handleSubmit(onSubmit)}>

      <InfoTextStyled>Отображаются Pixel, которые добавлены в систему и были найдены в заходах или добавлены вручную к данному PWA. Изменения применяются глобально, если вы редактируете токет, он изменится везде. При удалении удалится только в данном PWA.</InfoTextStyled>

      <MultiSelectControl
        control={control}
        name='facebookPixelId'
        label='Facebook Pixel'
        items={itemsFacebook}
        isAdding={true}
        setCreate={setFacebookPixelCreate}
        placehoder="Pixel ID or Track ID"
      />

      <MultiSelectControl
        control={control}
        name='tikTokPixelId'
        label='TikTok Pixel'
        items={itemsTikTok}
        isAdding={true}
        setCreate={setTikTokCreate}
        placehoder="Pixel ID or Track ID"
      />

      <ButtonsEdit setNavEdit={setNavEdit} toPage='settings' isActive={true} />
    </FormBasicStyled>
  );
};

export default EditMetric;
