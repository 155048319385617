import { useState } from 'react';
import reload from '../../../../../images/reload.png';
import { ContentStyled, NavPWAStyled, ParagBigStyled, ParagStyled } from './styled';
import { ButtonStyled, ImgStyled } from '../../../../../helperStyled/styledHelper';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooksStore';
import SwitchCircle from '../../../../../components/SwitchCircle/SwitchCircle';
import { useNavigate } from 'react-router-dom';
import { setId } from '../../../../../store/aplicationsEdit/aplicationEdit';
import { defaultValueEdits } from '../../../../../utils/consts';
import { getUserPermision } from '../../../../../utils/func';
import { setIsArchive } from '../../../../../store/core/core';

type Props = {
  forceUpdate: () => void;
};

const NavPWA: React.FC<Props> = ({ forceUpdate }) => {
  const archivePermision = getUserPermision('archive');
  const { isArchive } = useAppSelector(state => state.core);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('../create');
    dispatch(setId(defaultValueEdits));
  };

  const handleClickArchive = (item: any) => {
    dispatch(setIsArchive(item));
  };

  return (
    <NavPWAStyled>
      <ParagStyled>PWAs</ParagStyled>

      <ContentStyled>
        {archivePermision && (
          <>
            <ParagBigStyled>Архив:</ParagBigStyled>

            <SwitchCircle isActive={isArchive} setIsActive={handleClickArchive}/>
          </>
        )}

        <ButtonStyled
          variant='blue'
          onClick={handleClick}
        >
          Создать
        </ButtonStyled>

        <ImgStyled css='cursor: pointer;' wid={15.93} hei={18.93} alt='reload' src={reload} onClick={forceUpdate}/>
      </ContentStyled>
    </NavPWAStyled>
  )
}

export default NavPWA;