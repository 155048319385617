import styled from "styled-components";
import { ButtonStyled } from "../../../../helperStyled/styledHelper";

const PushMassageStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const NameStyled = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 12px;
`;

const ListNamesStyled = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #BFBFBF;
  border-radius: 10px;
  background-color: #F9F9F9;
  align-self: flex-start;
  width: 1025.6px;

  & > :not(:first-child) {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 18px;
      width: 1px;
      background-color: #4091F7;
      left: 0;
    }
  }
`;

type ListNameItemProps = {
  css: any;
}

const ListNameItemStyled = styled.p<ListNameItemProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-block: 0;
  white-space: nowrap;
  flex-shrink: 0;
  padding: 20px 0 22px 15px;
  height: 62px;

  ${({ css }) => css}
`;

const ListItemsWidthStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 310px;
  min-width: 1025.6px;
  max-width: 1037.6px;
  overflow-y: auto;
`;

const ListRowStyled = styled.div`
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    margin-left: 5px;
  }
`;

type ListRowItemProps = {
  css: any;
}

const ListRowItemStyled = styled.p<ListRowItemProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  flex-shrink: 0;
  padding-block: 15px;
  border: 1px solid #BFBFBF;
  border-radius: 10px;
  background-color: #F9F9F9;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 300px;

  ${({ css }) => css}
`;

const ButtonAddStyled = styled(ButtonStyled)`
  align-self: start;
  margin-block: 16px;
`;

const ErrorValidationStyled = styled.div`
  color: red;
  font-size: 12px;
`;

export {
  PushMassageStyled,
  NameStyled,
  ListNamesStyled,
  ListNameItemStyled,
  ListItemsWidthStyled,
  ListRowStyled,
  ListRowItemStyled,
  ButtonAddStyled,
  ErrorValidationStyled,
};