type App = {
  weekday: string[],
  sending_time: string,
  repeat: boolean,
  buttonsList: string,
};

type Nam = {
  id: number;
  name: keyof App;
  title: string;
  style: {
    width: string;
    paddingLeft?: string;
    paddingRight?: string;
  };
  styleList: {
    width: string;
  };
};

export const listNames: Nam[] = [
  {
    id: 1,
    name: 'sending_time',
    title: 'Время',
    style: {
      width: '100px',
    },
    styleList: {
      width: '105px',
    },
  },
  {
    id: 2,
    name: 'weekday',
    title: 'Дни недели',
    style: {
      width: '345px',
    },
    styleList: {
      width: '350px',
    },
  },
  {
    id: 3,
    name: 'repeat',
    title: 'Повторять',
    style: {
      width: '100px',
    },
    styleList: {
      width: '105px',
    },
  },
  {
    id: 4,
    name: 'buttonsList',
    title: ' ',
    style: {
      width: '150px',
    },
    styleList: {
      width: '150px',
    },
  },
];