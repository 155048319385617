import styled from 'styled-components';
import { CircleStyled } from '../../../helperStyled/styledHelper';

const NavStyled = styled.div`
  width: 250px;
  height: calc(100vh - 64px);
  padding: 32px 12px 0;
  flex-shrink: 0;
`;

const BlockStyled = styled.div`
  width: 100%;
  height: 76px;
  padding-inline: 16px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TitleStyled = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
`;

const GoTopStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: white;
`;

const ListStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

interface ListItemProps {
  isActive: boolean;
};

const ListItemStyled = styled.li<ListItemProps>`
  width: 100%;
  height: 56px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 22px;
  position: relative;
  background-color: ${({ isActive }) => isActive ? '#4091F7' : 'none'};

  &:after {
    content: '';
    width: ${({ isActive }) => isActive ? 0 : 226}px;
    height: ${({ isActive }) => isActive ? 0 : 1}px;
    background-color: #BFBFBF;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

interface ItemTileProps {
  isActive: boolean;
};

const ItemTitleStyled = styled.p<ItemTileProps>`
  font-weight: ${({ isActive }) => isActive ? 600 : 500};
  font-size: 14px;
  line-height: 20px;
  margin-left: 12px;
  color: ${({ isActive }) => isActive ? '#FFF' : '#323232'};
`;

interface CirvleNavProps {
  isActive: boolean;
};

const CircleNavStyled = styled(CircleStyled)<CirvleNavProps>`
  width: 12px;
  height: 12px;
  background-color: ${({isActive}) => isActive ? '#FFFFFF' : '#323232'};
`;

export {
  NavStyled,
  BlockStyled,
  TitleStyled,
  GoTopStyled,
  ListStyled,
  ListItemStyled,
  ItemTitleStyled,
  CircleNavStyled,
};
